<template>
  <div class="container" style="margin-bottom:0px">
    <div class="border">
      <div class="row">
        <div class="col-md-12">
          <h2>歌留多録</h2>
        </div>
      </div>
      <div id="firebaseui-auth-container"></div>
      <span class="description">認証が完了すると、</span
      ><span class="description">一度ログイン画面に戻ります。</span><br />
      <span class="description">記録画面が表示されるまで、</span
      ><span class="description">そのままお待ちください。</span>
      <hr />
      <h5>歌留多録（かるたろく）とは？</h5>
      <span class="description">小倉百人一首競技かるたの</span
      ><span class="description">対戦記録・統計アプリです。</span><br />
      <span
        >作者：おくさん（<a href="https://twitter.com/0kdynnkw" target="_blank"
          >@0kdynnkw</a
        >）<br />
        <a href="https://ko1ynnky.github.io/public/about.html" target="_blank"
          >詳しい自己紹介と開発経緯</a
        ></span
      >
      <hr />
      <h5>このアプリでできること</h5>
      <h6>
        <span class="description">「記録」を通じて、</span
        ><span class="description">競技かるたをさらに楽しめます。</span>
      </h6>
      <p><b>①</b><br />日々の練習結果を簡単に記録できます。</p>
      <p>
        <b>②</b><br /><span class="description"
          >記録を集計し、級ごとの勝率や、</span
        ><span class="description">平均枚数・お手つき回数を確認できます。</span>
      </p>
      <p>
        <b>③</b><br /><span class="description">結果面・行動面に分けて</span
        ><span class="description">目標を設定できます。</span>
      </p>
      <p>
        <b>その他</b><br /><router-link to="/read"
          >読み札を表示できます。</router-link
        ><br />（ログイン不要）
      </p>
      <hr />
      <h5>おすすめかるたアプリ</h5>
      <div class="row">
        <div class="col-sm-5 offset-sm-1">
          <a href="https://www.karutar.com/" target="_blank">競技かるた記録</a
          ><br />（練習会全体の記録）<br />
          ゆうすけ様（<a href="https://twitter.com/SVangurad" target="_blank"
            >@SVanguard</a
          >）<br />
          <br />
        </div>
        <div class="col-sm-5">
          <a href="https://matchrecord.herokuapp.com/#/" target="_blank"
            >Karuta Record</a
          ><br />（1枚ごとの経過の記録）<br />
          せーとく様（<a href="https://twitter.com/toku_kazeso" target="_blank"
            >@toku_kazeso</a
          >）<br />
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 offset-sm-1">
          <a href="https://carutalog.amebaownd.com/" target="_blank"
            >かるたーのためのカルタログ</a
          ><br />
          <a
            href="https://apps.apple.com/us/app/%E3%82%AB%E3%83%AB%E3%82%BF%E3%83%BC%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%82%AB%E3%83%AB%E3%82%BF%E3%83%AD%E3%82%B0/id1500253098"
            >iOS</a
          >
          /
          <a href="https://play.google.com/store/apps/details?id=com.log.caruta"
            >Android</a
          >
          <br />
          （試合ごとの記録）<br />
          川瀬将義様（<a
            href="https://twitter.com/MasayoshiKawase"
            target="_blank"
            >@MasayoshiKawase</a
          >）<br />
          <br />
        </div>
        <div class="col-sm-5">
          <a href="https://karuta-haichi-memo.web.app/" target="_blank"
            >競技かるた配置めも</a
          ><br />（札配置の記録）<br />
          宮様（<a href="https://twitter.com/mt_karuta" target="_blank"
            >@mt_karuta</a
          >）<br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.mt-2 {
  margin-top: 2px;
}
.border {
  border: 0.25em solid #669966 !important;
  padding: 1em;
}
.col-md-6 {
  padding: 0px;
}
span.description {
  display: inline-block;
}
</style>

<script>
import firebase from "firebase/app";
import "firebase/auth";
// import router from '../router'

// FirebaseUI読み込み
import firebaseui from "firebaseui-ja";
import "firebaseui-ja/dist/firebaseui.css";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      showError: false,
    };
  },
  mounted() {
    // 認証設定
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function() {
          // 認証種類判定
          return true;
        },
      },
      // 認証時に同一ウィンドウで表示
      signInFlow: "popup",
      // ログイン後リダイレクト先
      signInSuccessUrl: "/success",
      // 各認証
      signInOptions: [
        // Google認証
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // twitter認証
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // Facebook認証
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
      // 利用規約へリンク
      // tosUrl: '/termsofuse',
      // プライバシーポリシーリンク
      // privacyPolicyUrl: '/privacypolicy'
    };

    // 認証UI表示
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
