<template>
<div class="container">
  <div class="border">
    <Header />
    <div class="form-group border-success" id="record">
      <div class="pc">
        <h5>記録</h5>
        <b-button size="sm" variant="success" v-b-toggle.resultVisibilitySettings class="m-1">記録項目選択</b-button>
      </div>
      <div class="mb">
        <div class="row">
          <div class="col-4 my-auto"></div>
          <div class="col-4 my-auto"><span style="font-size:1.25rem;font-weight:500">記録</span></div>
          <div class="col-4 my-auto">
            <b-button size="sm" variant="outline-success" v-b-toggle.resultVisibilitySettings class="m-1 float-right">
              <font-awesome-icon icon="cog" />
            </b-button>
          </div>
        </div>
      </div>
      <b-collapse id="resultVisibilitySettings">
        <b-card>
          <div class="row" style="text-align:left">
            <div class="col-12 mb" style="text-align:center">
              <p>入力欄</p>
              <b-form-group>
                <b-form-radio-group size="lg" v-model="resultVisibility.inputForm" @input="changeResultVisibility()">
                  <b-form-radio :value="true">常に表示</b-form-radio>
                  <b-form-radio :value="false">折りたたむ</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-12 mb" style="text-align:center">
              <p>記録項目</p>
            </div>
            <div class="col-6">
              <b-form-checkbox size="lg" switch v-model="resultVisibility.Date" @input="changeResultVisibility()" disabled>日付</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.type" @input="changeResultVisibility()">形式</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.round" @input="changeResultVisibility()">回戦</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.player" @input="changeResultVisibility()">プレイヤー</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.place" @input="changeResultVisibility()">会場</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.reader" @input="changeResultVisibility()">読手</b-form-checkbox>
            </div>
            <div class="col-6">
              <b-form-checkbox size="lg" switch v-model="resultVisibility.opponent" @input="changeResultVisibility()">相手</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.club" @input="changeResultVisibility()">所属</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.rank" @input="changeResultVisibility()">級</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.dan" @input="changeResultVisibility()">段位</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.hand" @input="changeResultVisibility()">有効手</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.result" @input="changeResultVisibility()">結果</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.cardnumber" @input="changeResultVisibility()">枚数</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.otetsukiNumber" @input="changeResultVisibility()">お手</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="resultVisibility.comment" @input="changeResultVisibility()">コメント</b-form-checkbox>
            </div>
          </div>
        </b-card>
        <br>
      </b-collapse>
      <!-- PCでの表示 -->
      <div class="pc">
        <div class="row">
          <div v-show="resultVisibility.Date" class="col-md-6">
            <div class="row">
              <label for="datepicker" class="col-4 my-auto">日付</label>
              <div class="col-8" id="date_picker">
                <b-form-input v-model="result.defaultDate" :type="'date'"></b-form-input>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.type" class="col-md-6">
            <div class="row">
              <label for="type" id="tooltip-type" class="col-4 my-auto">形式
                <font-awesome-icon :icon="['fas','question-circle']" />
              </label>
              <div class="col-8" id="hand">
                <input class="form-control" type="text" v-model="result.type" autocomplete="on" list="typeList">
                <datalist id="typeList">
                  <option v-for="(item,key) in typeSuggestionList" :key="key">{{item}}</option>
                </datalist>
              </div>
              <b-button variant="link" @click="clearItem('type')" class="searchclear">x</b-button>
              <b-tooltip target="tooltip-type" triggers="hover">
                試合の形式。代表的な候補を選択肢に表示していますが、自由入力することも可能です。
              </b-tooltip>
            </div>
          </div>
          <div v-show="resultVisibility.round" class="col-md-6">
            <div class="row">
              <label for="round" class="col-4 my-auto">回戦</label>
              <div class="col-8" id="round">
                <input @input="validateRound" class="form-control" type="tel" v-model="result.round">
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.player" class="col-md-6">
            <div class="row">
              <label for="player" id="tooltip-player" class="col-4 my-auto">プレイヤー
                <font-awesome-icon :icon="['fas','question-circle']" />
              </label>
              <div class="col-8" id="player">
                <input class="form-control" type="text" v-model="result.player" autocomplete="on">
              </div>
              <b-tooltip target="tooltip-player" triggers="hover">
                記録対象の選手
              </b-tooltip>
            </div>
          </div>
          <div v-show="resultVisibility.place" class="col-md-6">
            <div class="row">
              <label for="place" class="col-4 my-auto">会場</label>
              <div class="col-8" id="place">
                <input class="form-control" type="text" v-model="result.place" autocomplete="on">
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.reader" class="col-md-6">
            <div class="row">
              <label for="reader" class="col-4 my-auto">読手</label>
              <div class="col-8" id="place">
                <input class="form-control" type="text" v-model="result.reader" autocomplete="on">
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.opponent" class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">相手</label>
              <div class="col-8" id="opponent">
                <input @change="suggestClubAndRank" class="form-control" type="text" v-model="result.opponent" autocomplete="on" list="opponentList">
                <datalist id="opponentList">
                  <option v-for="(item,key) in opponentSuggestionList" :key="key">{{item}}</option>
                </datalist>
                <b-button variant="link" @click="clearItem('opponent')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.club" class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">所属</label>
              <div class="col-8" id="club">
                <input class="form-control" type="text" v-model="result.club" autocomplete="on" list="clubList">
                <datalist id="clubList">
                  <option v-for="(item,key) in clubSuggestionList" :key="key">{{item}}</option>
                </datalist>
                <b-button variant="link" @click="clearItem('club')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.rank" class="col-md-6">
            <div class="row">
              <label for="rank" class="col-4 my-auto">級</label>
              <div class="col-8" id="rank">
                <b-form-select v-model="result.rank" :options="rankOptions"></b-form-select>
                <b-button variant="link" @click="clearItem('rank')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.dan" class="col-md-6">
            <div class="row">
              <label for="dan" class="col-4 my-auto">段位</label>
              <div class="col-8" id="dan">
                <b-form-select v-model="result.dan" :options="danOptions"></b-form-select>
                <b-button variant="link" @click="clearItem('dan')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.hand" class="col-md-6">
            <div class="row">
              <label for="hand" class="col-4 my-auto">有効手</label>
              <div class="col-8" id="hand">
                <b-form-select v-model="result.hand" :options="handOptions"></b-form-select>
                <b-button variant="link" @click="clearItem('hand')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.result" class="col-md-6">
            <div class="row">
              <label for="result" class="col-4 my-auto">勝敗</label>
              <div class="col-8" id="result">
                <b-form-select v-model="result.result" :options="resultOptions"></b-form-select>
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.cardnumber" class="col-md-6">
            <div class="row">
              <label for="cardnumber" class="col-4 my-auto">枚数</label>
              <div class="col-8" id="cardnumber">
                <input @input="validateCardNumber" class="form-control" type="tel" v-model="result.cardnumber">
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.otetsukiNumber" class="col-md-6">
            <div class="row">
              <label for="cardnumber" class="col-4 my-auto">お手</label>
              <div class="col-8" id="otetsukiNumber">
                <input @input="validateOtetsukiNumber" class="form-control" type="tel" v-model="result.otetsukiNumber">
              </div>
            </div>
          </div>
          <div v-show="resultVisibility.comment" class="col-md-6">
            <div class="row row-eq-height">
              <label for="comment" class="col-4 my-auto">コメント</label>
              <div class="col-8" id="comment">
                <b-form-textarea class="form-control" v-model="result.comment" rows="3" max-rows="8"></b-form-textarea>
              </div>
            </div>
          </div>

          <div class="col-md-12 align-bottom">
            <div class="row row-eq-height">
              <div class="col-sm-12 text-center">
                <b-button size="sm" variant="success" @click="onsubmit">記録</b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-left">
            <p v-if="errors.length">
              <b>入力エラーがあるようです:</b>
            <ul>
              <li v-for="(error,key) in errors" :key="key">{{ error }}</li>
            </ul>
            </p>
          </div>
        </div>
      </div>
      <div class="mb">
        <div v-if="!resultVisibility.inputForm">
          <b-button size="sm" variant="success" v-b-toggle.result class="m-1">入力する</b-button>
          <b-collapse id="result">
            <div class="row">
              <div v-show="resultVisibility.Date" class="col-md-6">
                <div class="row">
                  <label for="datepicker" class="col-4 my-auto">日付</label>
                  <div class="col-8" id="date_picker">
                    <b-form-input v-model="result.defaultDate" :type="'date'"></b-form-input>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.type" class="col-md-6">
                <div class="row">
                  <label for="type" id="tooltip-type" class="col-4 my-auto">形式
                    <font-awesome-icon :icon="['fas','question-circle']" />
                  </label>
                  <div class="col-8" id="hand">
                    <input class="form-control" type="text" v-model="result.type" autocomplete="on" list="typeList">
                    <datalist id="typeList">
                      <option v-for="(item,key) in typeSuggestionList" :key="key">{{item}}</option>
                    </datalist>
                  </div>
                  <b-button variant="link" @click="clearItem('type')" class="searchclear">x</b-button>
                  <b-tooltip target="tooltip-type" triggers="hover">
                    試合の形式。代表的な候補を選択肢に表示していますが、自由入力することも可能です。
                  </b-tooltip>
                </div>
              </div>
              <div v-show="resultVisibility.round" class="col-md-6">
                <div class="row">
                  <label for="round" class="col-4 my-auto">回戦</label>
                  <div class="col-8" id="round">
                    <input @input="validateRound" class="form-control" type="tel" v-model="result.round">
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.player" class="col-md-6">
                <div class="row">
                  <label for="player" id="tooltip-player" class="col-4 my-auto">プレイヤー
                    <font-awesome-icon :icon="['fas','question-circle']" />
                  </label>
                  <div class="col-8" id="player">
                    <input class="form-control" type="text" v-model="result.player" autocomplete="on">
                  </div>
                  <b-tooltip target="tooltip-player" triggers="hover">
                    記録対象の選手
                  </b-tooltip>
                </div>
              </div>
              <div v-show="resultVisibility.place" class="col-md-6">
                <div class="row">
                  <label for="place" class="col-4 my-auto">会場</label>
                  <div class="col-8" id="place">
                    <input class="form-control" type="text" v-model="result.place" autocomplete="on">
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.reader" class="col-md-6">
                <div class="row">
                  <label for="reader" class="col-4 my-auto">読手</label>
                  <div class="col-8" id="place">
                    <input class="form-control" type="text" v-model="result.reader" autocomplete="on">
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.opponent" class="col-md-6">
                <div class="row">
                  <label for="opponent" class="col-4 my-auto">相手</label>
                  <div class="col-8" id="opponent">
                    <input @change="suggestClubAndRank" class="form-control" type="text" v-model="result.opponent" autocomplete="on" list="opponentList">
                    <datalist id="opponentList">
                      <option v-for="(item,key) in opponentSuggestionList" :key="key">{{item}}</option>
                    </datalist>
                    <b-button variant="link" @click="clearItem('opponent')" class="searchclear">x</b-button>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.club" class="col-md-6">
                <div class="row">
                  <label for="opponent" class="col-4 my-auto">所属</label>
                  <div class="col-8" id="club">
                    <input class="form-control" type="text" v-model="result.club" autocomplete="on" list="clubList">
                    <datalist id="clubList">
                      <option v-for="(item,key) in clubSuggestionList" :key="key">{{item}}</option>
                    </datalist>
                    <b-button variant="link" @click="clearItem('club')" class="searchclear">x</b-button>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.rank" class="col-md-6">
                <div class="row">
                  <label for="rank" class="col-4 my-auto">級</label>
                  <div class="col-8" id="rank">
                    <b-form-select v-model="result.rank" :options="rankOptions"></b-form-select>
                    <b-button variant="link" @click="clearItem('rank')" class="searchclear">x</b-button>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.dan" class="col-md-6">
                <div class="row">
                  <label for="dan" class="col-4 my-auto">段位</label>
                  <div class="col-8" id="dan">
                    <b-form-select v-model="result.dan" :options="danOptions"></b-form-select>
                    <b-button variant="link" @click="clearItem('dan')" class="searchclear">x</b-button>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.hand" class="col-md-6">
                <div class="row">
                  <label for="hand" class="col-4 my-auto">有効手</label>
                  <div class="col-8" id="hand">
                    <b-form-select v-model="result.hand" :options="handOptions"></b-form-select>
                    <b-button variant="link" @click="clearItem('hand')" class="searchclear">x</b-button>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.result" class="col-md-6">
                <div class="row">
                  <label for="result" class="col-4 my-auto">勝敗</label>
                  <div class="col-8" id="result">
                    <b-form-select v-model="result.result" :options="resultOptions"></b-form-select>
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.cardnumber" class="col-md-6">
                <div class="row">
                  <label for="cardnumber" class="col-4 my-auto">枚数</label>
                  <div class="col-8" id="cardnumber">
                    <input @input="validateCardNumber" class="form-control" type="tel" v-model="result.cardnumber">
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.otetsukiNumber" class="col-md-6">
                <div class="row">
                  <label for="cardnumber" class="col-4 my-auto">お手</label>
                  <div class="col-8" id="otetsukiNumber">
                    <input @input="validateOtetsukiNumber" class="form-control" type="tel" v-model="result.otetsukiNumber">
                  </div>
                </div>
              </div>
              <div v-show="resultVisibility.comment" class="col-md-6">
                <div class="row row-eq-height">
                  <label for="comment" class="col-4 my-auto">コメント</label>
                  <div class="col-8" id="comment">
                    <b-form-textarea class="form-control" v-model="result.comment" rows="3" max-rows="8"></b-form-textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-12 align-bottom">
                <div class="row row-eq-height">
                  <div class="col-sm-12 text-center">
                    <b-button size="sm" variant="success" @click="onsubmit">記録</b-button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 text-left">
                <p v-if="errors.length">
                  <b>入力エラーがあるようです:</b>
                <ul>
                  <li v-for="(error,key) in errors" :key="key">{{ error }}</li>
                </ul>
                </p>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-else>
          <div class="row">
            <div v-show="resultVisibility.Date" class="col-md-6">
              <div class="row">
                <label for="datepicker" class="col-4 my-auto">日付</label>
                <div class="col-8" id="date_picker">
                  <b-form-input v-model="result.defaultDate" :type="'date'"></b-form-input>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.type" class="col-md-6">
              <div class="row">
                <label for="type" id="tooltip-type" class="col-4 my-auto">形式
                  <font-awesome-icon :icon="['fas','question-circle']" />
                </label>
                <div class="col-8" id="hand">
                  <input class="form-control" type="text" v-model="result.type" autocomplete="on" list="typeList">
                  <datalist id="typeList">
                    <option v-for="(item,key) in typeSuggestionList" :key="key">{{item}}</option>
                  </datalist>
                </div>
                <b-button variant="link" @click="clearItem('type')" class="searchclear">x</b-button>
                <b-tooltip target="tooltip-type" triggers="hover">
                  試合の形式。代表的な候補を選択肢に表示していますが、自由入力することも可能です。
                </b-tooltip>
              </div>
            </div>
            <div v-show="resultVisibility.round" class="col-md-6">
              <div class="row">
                <label for="round" class="col-4 my-auto">回戦</label>
                <div class="col-8" id="round">
                  <input @input="validateRound" class="form-control" type="tel" v-model="result.round">
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.player" class="col-md-6">
              <div class="row">
                <label for="player" id="tooltip-player" class="col-4 my-auto">プレイヤー
                  <font-awesome-icon :icon="['fas','question-circle']" />
                </label>
                <div class="col-8" id="player">
                  <input class="form-control" type="text" v-model="result.player" autocomplete="on">
                </div>
                <b-tooltip target="tooltip-player" triggers="hover">
                  記録対象の選手
                </b-tooltip>
              </div>
            </div>
            <div v-show="resultVisibility.place" class="col-md-6">
              <div class="row">
                <label for="place" class="col-4 my-auto">会場</label>
                <div class="col-8" id="place">
                  <input class="form-control" type="text" v-model="result.place" autocomplete="on">
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.reader" class="col-md-6">
              <div class="row">
                <label for="reader" class="col-4 my-auto">読手</label>
                <div class="col-8" id="place">
                  <input class="form-control" type="text" v-model="result.reader" autocomplete="on">
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.opponent" class="col-md-6">
              <div class="row">
                <label for="opponent" class="col-4 my-auto">相手</label>
                <div class="col-8" id="opponent">
                  <input @change="suggestClubAndRank" class="form-control" type="text" v-model="result.opponent" autocomplete="on" list="opponentList">
                  <datalist id="opponentList">
                    <option v-for="(item,key) in opponentSuggestionList" :key="key">{{item}}</option>
                  </datalist>
                  <b-button variant="link" @click="clearItem('opponent')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.club" class="col-md-6">
              <div class="row">
                <label for="opponent" class="col-4 my-auto">所属</label>
                <div class="col-8" id="club">
                  <input class="form-control" type="text" v-model="result.club" autocomplete="on" list="clubList">
                  <datalist id="clubList">
                    <option v-for="(item,key) in clubSuggestionList" :key="key">{{item}}</option>
                  </datalist>
                  <b-button variant="link" @click="clearItem('club')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.rank" class="col-md-6">
              <div class="row">
                <label for="rank" class="col-4 my-auto">級</label>
                <div class="col-8" id="rank">
                  <b-form-select v-model="result.rank" :options="rankOptions"></b-form-select>
                  <b-button variant="link" @click="clearItem('rank')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.dan" class="col-md-6">
              <div class="row">
                <label for="dan" class="col-4 my-auto">段位</label>
                <div class="col-8" id="dan">
                  <b-form-select v-model="result.dan" :options="danOptions"></b-form-select>
                  <b-button variant="link" @click="clearItem('dan')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.hand" class="col-md-6">
              <div class="row">
                <label for="hand" class="col-4 my-auto">有効手</label>
                <div class="col-8" id="hand">
                  <b-form-select v-model="result.hand" :options="handOptions"></b-form-select>
                  <b-button variant="link" @click="clearItem('hand')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.result" class="col-md-6">
              <div class="row">
                <label for="result" class="col-4 my-auto">勝敗</label>
                <div class="col-8" id="result">
                  <b-form-select v-model="result.result" :options="resultOptions"></b-form-select>
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.cardnumber" class="col-md-6">
              <div class="row">
                <label for="cardnumber" class="col-4 my-auto">枚数</label>
                <div class="col-8" id="cardnumber">
                  <input @input="validateCardNumber" class="form-control" type="tel" v-model="result.cardnumber">
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.otetsukiNumber" class="col-md-6">
              <div class="row">
                <label for="cardnumber" class="col-4 my-auto">お手</label>
                <div class="col-8" id="otetsukiNumber">
                  <input @input="validateOtetsukiNumber" class="form-control" type="tel" v-model="result.otetsukiNumber">
                </div>
              </div>
            </div>
            <div v-show="resultVisibility.comment" class="col-md-6">
              <div class="row row-eq-height">
                <label for="comment" class="col-4 my-auto">コメント</label>
                <div class="col-8" id="comment">
                  <b-form-textarea class="form-control" v-model="result.comment" rows="3" max-rows="8"></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="col-md-12 align-bottom">
              <div class="row row-eq-height">
                <div class="col-sm-12 text-center">
                  <b-button size="sm" variant="success" @click="onsubmit">記録</b-button>
                </div>
              </div>
            </div>
            <div class="col-sm-12 text-left">
              <p v-if="errors.length">
                <b>入力エラーがあるようです:</b>
              <ul>
                <li v-for="(error,key) in errors" :key="key">{{ error }}</li>
              </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div id="summary">
      <!-- <b-form-input v-model="heatMapEndDate" :type="'date'"></b-form-input>
      <CalendarHeatmap :values="entries" :endDate="heatMapEndDate" :range-color="['#ebedf0','#dae289', '#9cc069', '#669d45', '#637939', '#3b6427']">
      </CalendarHeatmap> -->
      <p>通算{{this.rows}}試合記録しました！</p>
      <div v-if="this.recentResultGoal.content!==''"><b>現在の目標（結果面）</b></div>
      <div v-if="this.recentResultGoal.content!==''">
        <div v-for="(line,index) in this.recentResultGoal.content.split('\n')" :key="`${index}`">{{line}}<br></div>
      </div>
      <br>
      <div v-if="this.recentActionGoal.content!==''"><b>現在の目標（行動面）</b></div>
      <div v-if="this.recentActionGoal.content!==''">
        <div v-for="(line,index) in this.recentActionGoal.content.split('\n')" :key="`${index}`">{{line}}<br></div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <b-button size="sm" variant="outline-success" v-b-toggle.columnVisibilitySettings class="m-1 float-right">
          <font-awesome-icon icon="cog" />
        </b-button>
      </div>
    </div>
    <b-collapse id="columnVisibilitySettings">
      <b-card>
        <div class="row" style="text-align:left">
          <div class="col-12" style="text-align:center">
            <b-form-group label="表示形式">
              <b-form-radio @input="changeTableType()" size="lg" v-model="tableType" value="table" style="text-align:left">パソコン向け</b-form-radio>
              <b-form-radio @input="changeTableType()" size="lg" v-model="tableType" value="block" style="text-align:left">スマホ・タブレット向け</b-form-radio>
            </b-form-group>
          </div>
          <div class="col-12" style="text-align:center">
            <p>表示項目</p>
          </div>
          <div class="col-6">
            <b-form-checkbox size="lg" switch v-model="visibility.Date" @input="changeVisibility('Date')" disabled>日付</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.type" @input="changeVisibility('type')">形式</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.round" @input="changeVisibility('round')">回戦</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.player" @input="changeVisibility('player')">プレイヤー</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.place" @input="changeVisibility('place')">会場</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.reader" @input="changeVisibility('reader')">読手</b-form-checkbox>
          </div>
          <div class="col-6">
            <b-form-checkbox size="lg" switch v-model="visibility.opponent" @input="changeVisibility('opponent')">相手</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.club" @input="changeVisibility('club')">所属</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.rank" @input="changeVisibility('rank')">級</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.dan" @input="changeVisibility('dan')">段位</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.hand" @input="changeVisibility('hand')">有効手</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.result" @input="changeVisibility('result')">結果</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.cardnumber" @input="changeVisibility('cardnumber')">枚数</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.otetsukiNumber" @input="changeVisibility('otetsukiNumber')">お手</b-form-checkbox>
            <b-form-checkbox size="lg" switch v-model="visibility.comment" @input="changeVisibility('comment')">コメント</b-form-checkbox>
          </div>
        </div>
      </b-card>
      <br>
    </b-collapse>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page=25 first-number last-number aria-controls="record-table"></b-pagination>
    <!-- 表形式のテーブル -->
    <b-table v-if="tableType=='table'" id="record-table" bordered responsive hover :items="this.recordsForTable" :fields="fields" :per-page=25 :current-page="currentPage">
      <template v-slot:[`cell(date)`]="data">
        <div class="pc">{{data.item.value.defaultDate | dayjs}}</div>
        <div class="mb">{{data.item.value.defaultDate | dayjs2}}</div>
        <br>
        <b-button size="sm" class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingResult(data.item.value);$bvModal.show('edit')">詳細</b-button>

      </template>
      <template v-slot:[`cell(value.type)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { type: data.item.value.type }}">{{data.item.value.type}}</router-link>
      </template>
      <template v-slot:[`cell(value.opponent)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { opponent: data.item.value.opponent }}">{{data.item.value.opponent}}</router-link>
      </template>
      <template v-slot:[`cell(value.club)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { club: data.item.value.club }}">{{data.item.value.club}}</router-link>
      </template>
      <template v-slot:[`cell(value.rank)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { rank: data.item.value.rank }}">{{data.item.value.rank}}</router-link>
      </template>
      <template v-slot:[`cell(value.dan)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { dan: data.item.value.dan }}">{{toKanji(data.item.value.dan)}}</router-link>
      </template>
      <template v-slot:[`cell(value.hand)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { hand: data.item.value.hand }}">{{data.item.value.hand}}</router-link>
      </template>
      <template v-slot:[`cell(value.result)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { result: data.item.value.result }}">{{data.item.value.result}}</router-link>
      </template>
    </b-table>
    <!-- ブロック形式のテーブル -->
    <b-table v-if="tableType=='block'" id="record-table" striped bordered responsive :items="this.recordsForTable" :fields="fieldsForMobile" :per-page=25 :current-page="currentPage">
      <template v-slot:[`cell(record)`]="data">
        <div v-show="visibility.Date" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">日付</div>
          <div class="col-8 text-left">{{data.item.value.defaultDate | dayjs}}</div>
        </div>
        <div v-show="visibility.type" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">形式</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { type: data.item.value.type }}">{{data.item.value.type}}</router-link>
          </div>
        </div>
        <div v-show="visibility.round" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">回戦</div>
          <div class="col-8 text-left">{{data.item.value.round}}</div>
        </div>
        <div v-show="visibility.player" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">プレイヤー</div>
          <div class="col-8 text-left">{{data.item.value.player}}</div>
        </div>
        <div v-show="visibility.place" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">会場</div>
          <div class="col-8 text-left">{{data.item.value.place}}</div>
        </div>
        <div v-show="visibility.reader" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">読手</div>
          <div class="col-8 text-left">{{data.item.value.reader}}</div>
        </div>
        <hr class="my-1 mx-2" style="height:1px">
        <div v-show="visibility.opponent" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">相手</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { opponent: data.item.value.opponent }}">{{data.item.value.opponent}}</router-link>
          </div>
        </div>
        <div v-show="visibility.club" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">所属</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { club: data.item.value.club }}">{{data.item.value.club}}</router-link>
          </div>
        </div>
        <div v-show="visibility.rank" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">級</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { rank: data.item.value.rank }}">{{data.item.value.rank}}</router-link>
          </div>
        </div>
        <div v-show="visibility.dan" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">段位</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { dan: data.item.value.dan }}">{{toKanji(data.item.value.dan)}}</router-link>
          </div>
        </div>
        <div v-show="visibility.hand" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">有効手</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { hand: data.item.value.hand }}">{{data.item.value.hand}}</router-link>
          </div>
        </div>
        <hr class="my-1 mx-2" style="height:1px">
        <div v-show="visibility.result" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">結果</div>
          <div class="col-8 text-left">
            <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { result: data.item.value.result }}">{{data.item.value.result}}</router-link>
          </div>
        </div>
        <div v-show="visibility.cardnumber" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">枚数</div>
          <div class="col-8 text-left">{{data.item.value.cardnumber}}</div>
        </div>
        <div v-show="visibility.otetsukiNumber" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">お手</div>
          <div class="col-8 text-left">{{data.item.value.otetsukiNumber}}</div>
        </div>
        <div v-show="visibility.comment" class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-4 font-weight-bold">コメント</div>
          <div class="col-8 text-left">{{data.item.value.comment}}</div>
        </div>
        <b-button size="sm" class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingResult(data.item.value);$bvModal.show('edit')">詳細</b-button>
      </template>
      <template v-slot:[`cell(value.result)`]="data">
        <router-link class="moveToStatistics" :to="{ name: 'statistics', hash: '#aggregateResults', params: { result: data.item.value.result }}">{{data.item.value.result}}</router-link>
      </template>
    </b-table>
    <b-modal v-bind:id="'edit'" hide-header hide-footer>
      <template v-slot:modal-title>
      </template>
      <div class=" text-center">
        <b-button size="sm" class="m-1 float-left" variant="danger" id="show-btn" @click="$bvModal.show('remove')">
          <font-awesome-icon :icon="['far','trash-alt']" />
        </b-button>
        <b-button size="sm" class="m-1 float-none" variant="success" v-b-toggle.editingResultVisibilitySettings>表示項目選択</b-button>
        <b-button size="sm" class="m-1 float-none" variant="success">
          <router-link style="color:white" :to="{ name: 'detailRecord', query: { key: this.focusKey }}">1枚ごとの記録</router-link>
        </b-button>
        <b-button size="sm" class="m-1 float-right" variant="secondary" @click="$bvModal.hide('edit')">閉じる</b-button>
        <b-collapse id="editingResultVisibilitySettings">
          <b-card>
            <div class="row" style="text-align:left">
              <div class="col-6">
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.Date" @input="changeEditingResultVisibility()" disabled>日付</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.type" @input="changeEditingResultVisibility()">形式</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.round" @input="changeEditingResultVisibility()">回戦</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.player" @input="changeEditingResultVisibility()">プレイヤー</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.place" @input="changeEditingResultVisibility()">会場</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.reader" @input="changeEditingResultVisibility()">読手</b-form-checkbox>
              </div>
              <div class="col-6">
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.opponent" @input="changeEditingResultVisibility()">相手</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.club" @input="changeEditingResultVisibility()">所属</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.rank" @input="changeEditingResultVisibility()">級</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.dan" @input="changeEditingResultVisibility()">段位</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.hand" @input="changeEditingResultVisibility()">有効手</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.result" @input="changeEditingResultVisibility()">結果</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.cardnumber" @input="changeEditingResultVisibility()">枚数</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.otetsukiNumber" @input="changeEditingResultVisibility()">お手</b-form-checkbox>
                <b-form-checkbox size="lg" switch v-model="editingResultVisibility.comment" @input="changeEditingResultVisibility()">コメント</b-form-checkbox>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <br>
        <div class="form-group border-success" id="record" style="margin-top:1rem">
          <div class="row">
            <div v-show="editingResultVisibility.Date" class="col-md-6">
              <div class="row">
                <label for="datepicker" class="col-4 my-auto">日付</label>
                <div class="col-8" id="date_picker">
                  <b-form-input v-model="editingResult.defaultDate" :type="'date'"></b-form-input>
                  <!-- <Datepicker
                    :language="ja"
                    :format="DatePickerFormat"
                    class="DatepickerStyle"
                    input-class="form-control"
                    v-model="editingResult.defaultDate"
                    name="datepicker"></Datepicker> -->
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.type" class="col-md-6">
              <div class="row">
                <label for="type" class="col-4 my-auto">形式</label>
                <div class="col-8" id="hand">
                  <input class="form-control" type="text" v-model="editingResult.type" autocomplete="on" list="typeList">
                  <datalist id="typeList">
                    <option v-for="(item,key) in typeSuggestionList" :key="key">{{item}}</option>
                  </datalist>
                  <b-button variant="link" @click="clearEditingItem('type')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.round" class="col-md-6">
              <div class="row">
                <label for="round" class="col-4 my-auto">回戦</label>
                <div class="col-8" id="round">
                  <input @input="validateEditingRound" class="form-control" type="tel" v-model="editingResult.round">
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.player" class="col-md-6">
              <div class="row">
                <label for="player" class="col-4 my-auto">プレイヤー</label>
                <div class="col-8" id="player">
                  <input class="form-control" type="text" v-model="editingResult.player" autocomplete="on">

                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.place" class="col-md-6">
              <div class="row">
                <label for="place" class="col-4 my-auto">会場</label>
                <div class="col-8" id="place">
                  <input class="form-control" type="text" v-model="editingResult.place" autocomplete="on">
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.reader" class="col-md-6">
              <div class="row">
                <label for="reader" class="col-4 my-auto">読手</label>
                <div class="col-8" id="place">
                  <input class="form-control" type="text" v-model="editingResult.reader" autocomplete="on">
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.opponent" class="col-md-6">
              <div class="row">
                <label for="opponent" class="col-4 my-auto">相手</label>
                <div class="col-8" id="opponent">
                  <input @change="suggestClubAndRankForEditingResult" class="form-control" type="text" v-model="editingResult.opponent" autocomplete="on" list="opponentList">
                  <datalist id="opponentList">
                    <option v-for="(item,key) in opponentSuggestionList" :key="key">{{item}}</option>
                  </datalist>
                  <b-button variant="link" @click="clearEditingItem('opponent')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.club" class="col-md-6">
              <div class="row">
                <label for="opponent" class="col-4 my-auto">所属</label>
                <div class="col-8" id="club">
                  <input class="form-control" type="text" v-model="editingResult.club" autocomplete="on" list="clubList">
                  <datalist id="clubList">
                    <option v-for="(item,key) in clubSuggestionList" :key="key">{{item}}</option>
                  </datalist>
                  <b-button variant="link" @click="clearEditingItem('club')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.rank" class="col-md-6">
              <div class="row">
                <label for="rank" class="col-4 my-auto">級</label>
                <div class="col-8" id="rank">
                  <b-form-select v-model="editingResult.rank" :options="rankOptions"></b-form-select>
                  <b-button variant="link" @click="clearEditingItem('rank')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.dan" class="col-md-6">
              <div class="row">
                <label for="dan" class="col-4 my-auto">段位</label>
                <div class="col-8" id="dan">
                  <b-form-select v-model="editingResult.dan" :options="danOptions"></b-form-select>
                  <b-button variant="link" @click="clearEditingItem('dan')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.hand" class="col-md-6">
              <div class="row">
                <label for="hand" class="col-4 my-auto">有効手</label>
                <div class="col-8" id="hand">
                  <b-form-select v-model="editingResult.hand" :options="handOptions"></b-form-select>
                  <b-button variant="link" @click="clearEditingItem('hand')" class="searchclear">x</b-button>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.result" class="col-md-6">
              <div class="row">
                <label for="result" class="col-4 my-auto">勝敗</label>
                <div class="col-8" id="result">
                  <b-form-select v-model="editingResult.result" :options="resultOptions"></b-form-select>
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.cardnumber" class="col-md-6">
              <div class="row">
                <label for="cardnumber" class="col-4 my-auto">枚数</label>
                <div class="col-8" id="cardnumber">
                  <input @input="validateEditingCardnumber" class="form-control" type="tel" v-model="editingResult.cardnumber">
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.otetsukiNumber" class="col-md-6">
              <div class="row">
                <label for="cardnumber" class="col-4 my-auto">お手</label>
                <div class="col-8" id="otetsukiNumber">
                  <input @input="validateEditingOtetsukiNumber" class="form-control" type="tel" v-model="editingResult.otetsukiNumber">
                </div>
              </div>
            </div>
            <div v-show="editingResultVisibility.comment" class="col-md-6">
              <div class="row row-eq-height">
                <label for="comment" class="col-4 my-auto">コメント</label>
                <div class="col-8" id="comment">
                  <b-form-textarea class="form-control" v-model="editingResult.comment" rows="3" max-rows="8"></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="col-md-6 align-bottom">
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <b-button class="mx-1" size="sm" variant="secondary" @click="$bvModal.hide('edit')">閉じる</b-button>
        <b-button class="mx-1" size="sm" variant="success" @click="edit();$bvModal.hide('edit')">更新</b-button>
      </div>
    </b-modal>
    <b-modal v-bind:id="'remove'" hide-footer hide-header>
      <template v-slot:modal-title>
      </template>
      <div class="d-block text-center">
        <p>本当に記録を削除しますか？</p>
      </div>
      <div class="text-center">
        <b-button class="mx-1" variant="secondary" @click="$bvModal.hide('remove')">やめる</b-button>
        <b-button class="mx-1" variant="danger" @click="remove();$bvModal.hide('remove');$bvModal.hide('edit')">削除</b-button>
      </div>
    </b-modal>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page=25 first-number last-number aria-controls="record-table"></b-pagination>

    <b-button size="sm" variant="success" v-on:click="downloadCSV" class="m-1">記録をダウンロード</b-button>
    <br>
  </div>
</div>
</template>

<style>
@media screen and (max-width: 768px) {

  /*タブレット、スマホの場合*/
  .container {
    margin-bottom: 64px;
  }
}

.mt-4 {
  margin-top: 4px;
}

.row {
  margin-bottom: 4px;
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.vdp-datepicker__calendar {
  width: auto !important;
}

/*PCの場合*/
.pc {
  display: inline !important;
}

.mb {
  display: none !important;
}

@media screen and (max-width: 768px) {

  /*タブレット、スマホの場合*/
  .pc {
    display: none !important;
  }

  .mb {
    display: inline !important;
  }
}

.mx-1 {
  margin-bottom: 0.75em;
}

.table th {
  padding: 0.25em;
}

.table td {
  padding: 0.25em;
  vertical-align: middle;
}

.table td.records-3em {
  min-width: 3em;
}

.table td.records-1em {
  min-width: 1em;
}

.table td.records-10em {
  min-width: 10em;
}

.modal-dialog {
  max-width: 95%;
  margin: 1.75rem auto;
}

.tooltip-inner {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.searchclear {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ccc;
  vertical-align: middle;
}

.moveToStatistics {
  color: #212529;
}
</style>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
// import Datepicker from 'vuejs-datepicker';
// import {ja} from 'vuejs-datepicker/dist/locale'
import Header from '@/components/Header.vue'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
// import 'vue-calendar-heatmap/dist/vue-calendar-heatmap.css'
// import { CalendarHeatmap } from 'vue-calendar-heatmap/dist/vue-calendar-heatmap.common'

export default {
  name: 'success',
  created: function() {
    this.database = firebase.database();
    this.uid = firebase.auth().currentUser.uid;

    //現在の目標の読み込み
    this.database.ref('goals/' + this.uid + '/recentResultGoal').on('value', snapshot => {
      snapshot.forEach((data) => {
        this.recentResultGoal[data.key] = data.val();
      })
    })
    this.database.ref('goals/' + this.uid + '/recentActionGoal').on('value', snapshot => {
      snapshot.forEach((data) => {
        this.recentActionGoal[data.key] = data.val();
      })
    })


    this.list = this.database.ref('records/' + this.uid + '/').orderByChild("reverseDefaultDate");

    var _this = this;
    this.list.on('value', function(snapshot) {
      let array = {};
      let opponentSet = new Set();
      let clubSet = new Set();
      let dateArray = [];
      const typeSet = new Set();

      snapshot.forEach(child => {
        array[child.key] = child.val();
        opponentSet.add(child.val()["opponent"])
        clubSet.add(child.val()["club"])
        typeSet.add(child.val()["type"])
        let recordDate = new dayjs(child.val()["defaultDate"]).format("YYYY-MM-DD")
        dateArray.push({
          "date": recordDate
        })

      });
      let groupBy = dateArray.reduce((result, current) => {
        let element = result.find(value => value.date === current.date);
        if (element) {
          element.count++;
        } else {
          result.push({
            date: current.date,
            count: 1,
          })
        }
        return result;
      }, []);
      _this.entries = groupBy

      _this.records = array;
      _this.recordsForTable = Object.entries(array).map(([key, value]) => ({
        key,
        value
      }))

      typeSet.add(...["自会練習","他会練習","大会","一人取り","競技かるた ONLINE","払い練","札落とし"])

      _this.opponentSuggestionList = [...opponentSet]
      _this.clubSuggestionList = [...clubSet]
      _this.opponentSuggestionSet = opponentSet
      _this.clubSuggestionSet = clubSet
      _this.typeSuggestionList = [...typeSet]
    });
    firebase.analytics().logEvent('record_loaded', {
      username: this.user.displayName
    })
  },
  methods: {
    //記録を登録する
    onsubmit() {
      this.errors = [];
      console.log(this.result.defaultDate);
      let defaultDate = dayjs(this.result.defaultDate);
      console.log(defaultDate);
      if (this.result.defaultDate) {
        firebase.database().ref('records/' + this.uid).push({
          uid: this.uid,
          defaultDate: defaultDate.valueOf(),
          reverseDefaultDate: -(defaultDate.valueOf() + this.result.round),
          round: this.result.round,
          opponent: this.result.opponent,
          club: this.result.club,
          rank: this.result.rank,
          result: this.result.result,
          cardnumber: this.result.cardnumber,
          otetsukiNumber: this.result.otetsukiNumber,
          comment: this.result.comment,
          player: this.result.player,
          hand: this.result.hand,
          place: this.result.place,
          reader: this.result.reader,
          username: this.user.displayName,
          type: this.result.type,
          dan: this.result.dan,
        })
        firebase.analytics().logEvent('record_submitted', {
          username: this.user.displayName
        })
        this.result.defaultDate = defaultDate.format('YYYY-MM-DD');
        this.result.round = ""
        this.result.opponent = ""
        this.result.club = ""
        this.result.rank = ""
        this.result.result = ""
        this.result.cardnumber = ""
        this.result.otetsukiNumber = ""
        this.result.comment = ""
        this.result.player = ""
        this.result.hand = ""
        this.result.place = ""
        this.result.reader = ""
        this.result.type = ""
        this.result.dan = ""
      } else {
        this.errors.push("日付が入力されていません。");
      }

    },
    validateRound() {
      var round = Number(this.result.round.replace(/\D/g, ''));
      if (round == 0) {
        this.result.round = ''
      } else {
        this.result.round = round
      }
    },
    validateCardNumber() {
      var cardnumber = Number(this.result.cardnumber.replace(/\D/g, ''));
      if (cardnumber == 0) {
        this.result.cardnumber = ''
      } else {
        if (cardnumber > 48) {
          this.result.cardnumber = 48
        } else {
          this.result.cardnumber = cardnumber
        }
      }
    },
    validateOtetsukiNumber() {
      if (this.result.otetsukiNumber != '') {
        var otetsukiNumber = Number(this.result.otetsukiNumber.replace(/\D/g, ''));
        this.result.otetsukiNumber = otetsukiNumber
      }
    },
    suggestClubAndRank() {
      if (this.opponentSuggestionSet.has(this.result.opponent)) {

        for (var k = 0; k < this.rows; k++) {
          if (this.recordsForTable[k].value.opponent != "" && this.recordsForTable[k].value.opponent == this.result.opponent) {
            this.result.club = this.recordsForTable[k].value.club
            this.result.rank = this.recordsForTable[k].value.rank
            if (!(typeof this.recordsForTable[k].value.hand === "undefined")) {
              this.result.hand = this.recordsForTable[k].value.hand
            } else {
              this.result.hand = ""
            }
            if (!(typeof this.recordsForTable[k].value.dan === "undefined")) {
              this.result.dan = this.recordsForTable[k].value.dan
            } else {
              this.result.dan = ""
            }
            break;
          }
        }
      }
    },
    suggestClubAndRankForEditingResult() {
      if (this.opponentSuggestionSet.has(this.editingResult.opponent)) {

      for (var k = 0; k < this.rows; k++) {
        if (this.recordsForTable[k].value.opponent != "" && this.recordsForTable[k].value.opponent == this.editingResult.opponent) {
          this.editingResult.club = this.recordsForTable[k].value.club
          this.editingResult.rank = this.recordsForTable[k].value.rank
          if (!(typeof this.recordsForTable[k].value.hand === "undefined")) {
            this.editingResult.hand = this.recordsForTable[k].value.hand
          } else {
            this.editingResult.hand = ""
          }
          if (!(typeof this.recordsForTable[k].value.dan === "undefined")) {
            this.editingResult.dan = this.recordsForTable[k].value.dan
          } else {
            this.editingResult.dan = ""
          }
          break;
        }
      }
    }
    },
    setFocusKey: function(key) {
      this.focusKey = key;
    },
    remove: function() {
      // 削除
      firebase.database().ref('records/' + this.uid).child(this.focusKey).remove();

      // detailRecordsに記録がある場合、それを削除する
      firebase.database().ref('detailRecords/' + this.uid + '/' + this.focusKey).once("value", snapshot => {
        if (snapshot.exists()) {
          firebase.database().ref('detailRecords/' + this.uid).child(this.focusKey).remove();
        }
      });

      firebase.analytics().logEvent('record_removed', {
        username: this.user.displayName
      })
    },
    edit() {
      let defaultDate = dayjs(this.editingResult.defaultDate, "YYYY-MM-DD")
      this.editingResult.defaultDate = defaultDate.valueOf();
      this.editingResult.reverseDefaultDate = -(this.editingResult.defaultDate + this.editingResult.round);

      // 更新
      firebase.database().ref('records/' + this.uid).child(this.focusKey).update(this.editingResult);

      // detailRecordsに記録がある場合、それを更新する
      firebase.database().ref('detailRecords/' + this.uid + '/' + this.focusKey).once("value", snapshot => {
        if (snapshot.exists()) {
          firebase.database().ref('detailRecords/' + this.uid).child(this.focusKey).child('result').update(this.editingResult);
        }
      });

      firebase.analytics().logEvent('record_edited', {
        username: this.user.displayName
      })
    },
    loadEditingResult: function(item) {
      this.editingResult.defaultDate = dayjs(item.defaultDate).format('YYYY-MM-DD')
      this.editingResult.round = item.round;
      this.editingResult.opponent = item.opponent;
      this.editingResult.club = item.club;
      this.editingResult.rank = item.rank;
      this.editingResult.result = item.result;
      this.editingResult.cardnumber = item.cardnumber;
      this.editingResult.otetsukiNumber = item.otetsukiNumber;
      this.editingResult.comment = item.comment;
      if (!(typeof item.player === "undefined")) {
        this.editingResult.player = item.player;
      } else {
        this.editingResult.player = ""
      }
      if (!(typeof item.hand === "undefined")) {
        this.editingResult.hand = item.hand;
      } else {
        this.editingResult.hand = ""
      }
      if (!(typeof item.place === "undefined")) {
        this.editingResult.place = item.place;
      } else {
        this.editingResult.place = "";
      }
      if (!(typeof item.reader === "undefined")) {
        this.editingResult.reader = item.reader;
      } else {
        this.editingResult.reader = "";
      }
      if (!(typeof item.type === "undefined")) {
        this.editingResult.type = item.type;
      } else {
        this.editingResult.type = "";
      }
      if (!(typeof item.dan === "undefined")) {
        this.editingResult.dan = item.dan;
      } else {
        this.editingResult.dan = "";
      }
    },
    validateEditingRound() {
      var editingRound = Number(this.editingResult.round.replace(/\D/g, ''));
      if (editingRound == 0) {
        this.editingResult.round = ''
      } else {
        this.editingResult.round = editingRound
      }
    },
    validateEditingCardnumber() {
      var editingCardnumber = Number(this.editingResult.cardnumber.replace(/\D/g, ''));
      if (editingCardnumber == 0) {
        this.editingResult.cardnumber = ''
      } else {
        if (editingCardnumber > 48) {
          this.editingResult.cardnumber = 48
        } else {
          this.editingResult.cardnumber = editingCardnumber
        }
      }
    },
    validateEditingOtetsukiNumber() {
      if (this.editingResult.otetsukiNumber != '') {
        var editingOtetsukiNumber = Number(this.editingResult.otetsukiNumber.replace(/\D/g, ''));
        this.editingResult.otetsukiNumber = editingOtetsukiNumber
      }
    },
    // opponentSuggestionList() {
    //   var opponentList = new Array()
    //   for (var k=0;k<Math.min(1,this.rows);k++){
    //     if(this.recordsForTable[k].value.opponent != ''){
    //       opponentList.push(this.recordsForTable[k].value.opponent)
    //     }
    //   }
    //   var opponentSet = new Set(opponentList)
    //   opponentList = Array.from(opponentSet)
    //   return opponentList
    // },
    // clubSuggestionList() {
    //   var clubList = new Array()
    //   for (var k=0;k<Math.min(1,this.rows);k++){
    //     if(this.recordsForTable[k].value.club != ''){
    //       clubList.push(this.recordsForTable[k].value.club)
    //     }
    //   }
    //   var clubSet = new Set(clubList)
    //   clubList = Array.from(clubSet)
    //   clubList = clubList.sort((x,y)=>x.localeCompare(y,'ja'))
    //   return clubList
    // },
    clearItem(item) {
      this.result[item] = ''
    },
    clearEditingItem(item) {
      this.editingResult[item] = ''
    },
    changeVisibility(column) {
      var key = 0
      for (var k = 0; k < this.fields.length; k++) {
        if (this.fields[k].key.indexOf(column) != -1) {
          key = k
          break
        }
      }
      if (this.visibility[column]) {
        if (['round', 'hand', 'cardnumber', 'otetsukiNumber'].includes(column)) {
          this.fields[key].tdClass = "records-1em"
        } else {
          if (column == 'comment') {
            this.fields[key].tdClass = "records-10em"
          } else {
            this.fields[key].tdClass = "records-3em"
          }
        }
        this.fields[key].thClass = ""
      } else {
        this.fields[key].tdClass = "d-none"
        this.fields[key].thClass = "d-none"
      }
      localStorage.setItem('success-records-visibility', JSON.stringify(this.visibility))
    },
    changeResultVisibility() {
      localStorage.setItem('success-result-visibility', JSON.stringify(this.resultVisibility))
    },
    changeEditingResultVisibility() {
      localStorage.setItem('success-editingResult-visibility', JSON.stringify(this.editingResultVisibility))
    },
    changeTableType() {
      localStorage.setItem('success-Table-Type', JSON.stringify(this.tableType))
    },
    readVisibilityFromStorage() {
      this.visibility = JSON.parse(localStorage.getItem('success-records-visibility'));
      if (!this.visibility) {
        this.visibility = {
          Date: true,
          type: true,
          round: false,
          opponent: true,
          club: true,
          rank: true,
          dan: true,
          result: true,
          cardnumber: true,
          otetsukiNumber: false,
          comment: false,
          player: false,
          hand: false,
          place: false,
          reader: false,
        }
      }
    },
    readResultVisibilityFromStorage() {
      this.resultVisibility = JSON.parse(localStorage.getItem('success-result-visibility'));
      if (!this.resultVisibility) {
        this.resultVisibility = {
          Date: true,
          type: true,
          round: true,
          opponent: true,
          club: true,
          rank: true,
          dan: true,
          result: true,
          cardnumber: true,
          otetsukiNumber: true,
          comment: true,
          player: false,
          hand: false,
          place: false,
          reader: false,
          inputForm: false,
        }
      }
    },
    readEditingResultVisibilityFromStorage() {
      this.editingResultVisibility = JSON.parse(localStorage.getItem('success-editingResult-visibility'));
      if (!this.editingResultVisibility) {
        this.editingResultVisibility = {
          Date: true,
          type: true,
          round: true,
          opponent: true,
          club: true,
          rank: true,
          dan: true,
          result: true,
          cardnumber: true,
          otetsukiNumber: true,
          comment: true,
          player: false,
          hand: false,
          place: false,
          reader: false,
        }
      }
    },
    readTableTypeFromStorage() {
      this.tableType = JSON.parse(localStorage.getItem('success-Table-Type'));
      if (!this.tableType) {
        if (screen.width <= 768) {
          this.tableType = 'block'
        } else {
          this.tableType = 'table'
        }
      }
    },
    downloadCSV() {
      var csv = '\ufeff' + 'ユーザー名,日付,形式,回戦,相手,所属,級,段,有効手,勝敗,枚数,お手,会場,読手,コメント\n'
      this.recordsForTable.forEach(el => {
        var date = new Date(el.value['defaultDate'])
        var formatedDateTime = dayjs(date).format("YYYY/MM/DD")
        var line = this.user.displayName + ',' +
          formatedDateTime + ',' +
          el.value['type'] + ',' +
          el.value['round'] + ',' +
          el.value['opponent'] + ',' +
          el.value['club'] + ',' +
          el.value['rank'] + ','
        if (typeof el.value['dan'] === "undefined") {
          line = line + ','
        } else {
          line = line + el.value['dan'] + ','
        }
        line = line + el.value['hand'] + ',' +
          el.value['result'] + ',' +
          el.value['cardnumber'] + ',' +
          el.value['otetsukiNumber'] + ',' +
          el.value['place'] + ',' +
          el.value['reader'] + ',' +
          el.value['comment'] + '\n'
        csv += line
      })
      let blob = new Blob([csv], {
        type: 'text/csv'
      })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      var now = dayjs().format('YYYYMMDDHHmmss')
      link.download = '記録_' + now + '.csv'
      link.click()
    },
    toKanji(befText) {
      if (typeof befText === "undefined" || befText == '') {
        return ""
      }
      var str = ""
      switch (befText) {
        case 0:
          str = "無"
          break;
        case 1:
          str = "初"
          break;
        case 2:
          str = "二"
          break;
        case 3:
          str = "三"
          break;
        case 4:
          str = "四"
          break;
        case 5:
          str = "五"
          break;
        case 6:
          str = "六"
          break;
        case 7:
          str = "七"
          break;
        case 8:
          str = "八"
          break;
        case 9:
          str = "九"
          break;
        case 10:
          str = "十"
          break;
        default:
      }
      return str + "段";
    }
  },
  components: {
    Header,
    // CalendarHeatmap,
  },
  data() {
    return {
      errors: [],
      DatePickerFormat: 'yyyy/MM/dd',
      user: firebase.auth().currentUser,
      uid: firebase.auth().currentUser.uid,
      result: {
        defaultDate: dayjs(new Date()).format('YYYY-MM-DD'),
        round: '',
        opponent: '',
        club: '',
        rank: '',
        result: '',
        cardnumber: '',
        otetsukiNumber: '',
        comment: '',
        player: '',
        hand: '',
        place: '',
        reader: '',
        type: '',
        dan: '',
      },
      editingResult: {
        defaultDate: '',
        round: '',
        opponent: '',
        club: '',
        rank: '',
        result: '',
        cardnumber: '',
        otetsukiNumber: '',
        comment: '',
        player: '',
        hand: '',
        place: '',
        reader: '',
        type: '',
        dan: '',
      },
      focusKey: '',
      records: [],
      currentPage: 1,
      recordsForTable: [],
      fieldsForMobile: [{
        key: 'record',
        label: '記録',
      }],
      fields: [{
          key: 'Date',
          label: '日付',
        },
        {
          key: 'value.type',
          label: '形式',
          tdClass: 'records-3em',
        },
        {
          key: 'value.round',
          label: '回戦',
          tdClass: 'records-1em',
        },
        {
          key: 'value.player',
          label: 'プレイヤー',
          tdClass: 'records-3em',
        },
        {
          key: 'value.opponent',
          label: '相手',
          tdClass: 'records-3em',
        },
        {
          key: 'value.club',
          label: '所属',
          tdClass: 'records-3em',
        },
        {
          key: 'value.rank',
          label: '級',
          tdClass: 'records-3em',
        },
        {
          key: 'value.dan',
          label: '段位',
          tdClass: 'records-3em',
        },
        {
          key: 'value.hand',
          label: '有効手',
          tdClass: 'records-1em',
        },
        {
          key: 'value.result',
          label: '勝敗',
          tdClass: 'records-3em',
        },
        {
          key: 'value.cardnumber',
          label: '枚数',
          tdClass: 'records-1em'
        },
        {
          key: 'value.otetsukiNumber',
          label: 'お手',
          tdClass: 'records-1em'
        },
        {
          key: 'value.place',
          label: '会場',
          tdClass: 'records-3em',
        },
        {
          key: 'value.reader',
          label: '読手',
          tdClass: 'records-3em',
        },
        {
          key: 'value.comment',
          label: 'コメント',
          tdClass: 'records-10em',
        },
      ],
      visibility: {
        Date: true,
        type: true,
        round: true,
        opponent: true,
        club: true,
        rank: true,
        result: true,
        cardnumber: true,
        otetsukiNumber: true,
        comment: true,
        player: true,
        hand: true,
        place: true,
        reader: true,
        dan: true,
      },
      resultVisibility: {
        Date: true,
        type: true,
        round: true,
        opponent: true,
        club: true,
        rank: true,
        result: true,
        cardnumber: true,
        otetsukiNumber: true,
        comment: true,
        player: false,
        hand: false,
        place: false,
        reader: false,
        dan: false,
        inputForm: false,
      },
      editingResultVisibility: {
        Date: true,
        type: true,
        round: true,
        opponent: true,
        club: true,
        rank: true,
        result: true,
        cardnumber: true,
        otetsukiNumber: true,
        comment: true,
        player: false,
        hand: false,
        place: false,
        reader: false,
        dan: false,
      },
      tableType: '',
      danOptions: [{
          value: '',
          text: ''
        },
        {
          value: 0,
          text: '無段'
        },
        {
          value: 1,
          text: '初段'
        },
        {
          value: 2,
          text: '二段'
        },
        {
          value: 3,
          text: '三段'
        },
        {
          value: 4,
          text: '四段'
        },
        {
          value: 5,
          text: '五段'
        },
        {
          value: 6,
          text: '六段'
        },
        {
          value: 7,
          text: '七段'
        },
        {
          value: 8,
          text: '八段'
        },
        {
          value: 9,
          text: '九段'
        },
        {
          value: 10,
          text: '十段'
        },
      ],
      rankOptions: [{
          value: '',
          text: ''
        },
        {
          value: 'A級',
          text: 'A級'
        },
        {
          value: 'B級',
          text: 'B級'
        },
        {
          value: 'C級',
          text: 'C級'
        },
        {
          value: 'D級',
          text: 'D級'
        },
        {
          value: 'E級',
          text: 'E級'
        },
        {
          value: 'F級',
          text: 'F級'
        },
      ],
      handOptions: [{
          value: '',
          text: ''
        },
        {
          value: '右',
          text: '右'
        },
        {
          value: '左',
          text: '左'
        },
      ],
      resultOptions: [{
          value: '',
          text: ''
        },
        {
          value: '勝ち',
          text: '勝ち'
        },
        {
          value: '負け',
          text: '負け'
        },
        {
          value: 'その他',
          text: 'その他'
        },
      ],
      recentActionGoal: {
        content: "",
        startDate: "",
        endDate: "",
      },
      recentResultGoal: {
        content: "",
        startDate: "",
        endDate: "",
      },
      opponentList: [],
      opponentSuggestionList: [],
      clubSuggestionList: [],
      opponentAndClubMap: new Map(),
      opponentSuggestionSet: new Set(),
      clubSuggestionSet: new Set(),
      entries: [],
      heatMapEndDate: new dayjs().format("YYYY-MM-DD"),
      typeSuggestionList: []
    }
  },
  filters: {
    dayjs: function(date) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    dayjs2: function(date) {
      return dayjs(date).format('MM/DD');
    },
  },
  computed: {
    rows() {
      return this.recordsForTable.length
    },
  },
  mounted: function() {
    this.readVisibilityFromStorage();
    this.readResultVisibilityFromStorage();
    this.readEditingResultVisibilityFromStorage();
    this.readTableTypeFromStorage();
  }
}
</script>
