import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'

// firebase
import { firebase } from '@firebase/app';
import "firebase/analytics";
import "firebase/functions";

// BootstapVue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)


/* Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* PWA対応 */
import './registerServiceWorker'
Vue.use(VueHead)

/* VeeValidate */
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

/* dayjs */
import 'dayjs/locale/ja'
import dayjs from 'dayjs'
dayjs.locale('ja')


// ルールの追加
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true
})

// コンポーネントの登録
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false

// 1.Firebase側の設定のウェブ設定で表示したコードをコピペする
const firebaseConfig = {
  apiKey: "AIzaSyD2KU0kGGJGQuvkbQ1C3YlpP1qLD4nV0mE",
  authDomain: "karuta-record-eaf67.firebaseapp.com",
  databaseURL: "https://karuta-record-eaf67.firebaseio.com",
  projectId: "karuta-record-eaf67",
  storageBucket: "karuta-record-eaf67.appspot.com",
  messagingSenderId: "713490065687",
  appId: "1:713490065687:web:814f8cd28a0d6363266f52",
  measurementId: "G-GWM8K1X74E"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const functions = firebase.functions();


import router from './router'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
