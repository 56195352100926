<template>
  <div class="container">
    <div class="border">
      <Header />
      <h6>記録用紙</h6>
      <div class="row">
        <b-form-input class="col-6" v-model="gameInfo" placeholder="大会名（回戦）"></b-form-input>
        <b-form-input class="col-6" v-model="place" placeholder="会場"></b-form-input>
        <b-form-input class="col-6" v-model="date" :type="'date'" placeholder="日付"></b-form-input>
        <b-form-input class="col-6" v-model="scorer" placeholder="記入者"></b-form-input>
        <b-form-input class="col-6" v-model="reader" placeholder="読手"></b-form-input>
      </div>
      <div class="row sticky-top">
        <b-form-input class="col-4" v-model="player1" placeholder="選手1" @update="setPlayer1"></b-form-input>
        <b-form-input class="col-2" v-model="player1dan" placeholder="選手1の段位"></b-form-input>
        <b-form-input class="col-4" v-model="player2" placeholder="選手2" @update="setPlayer2"></b-form-input>
        <b-form-input class="col-2" v-model="player2dan" placeholder="選手2の段位"></b-form-input>
      </div>
      <div class="sticky-top" style="background-color: white;">
      </div>
      <br>
      <b-table ref="recordtable" id="record-table" hover :items="this.detailRecord" :fields="recordFields" :per-page=100 :current-page="recordCurrentPage">
        <template v-slot:cell(numeroSign1)="data">
          {{ data.index + 1 + 10*(recordCurrentPage-1) }}
        </template>
        <template v-slot:cell(player1situation)="data">
          <div v-if="data.item.detailRecord=='player1'&&data.item.player1Otetsuki=='0'">○</div>
          <div v-if="data.item.detailRecord=='player1'&&data.item.player1Otetsuki=='1'">⊗</div>
          <div v-if="data.item.detailRecord!='player1'&&data.item.player1Otetsuki=='0'"></div>
          <div v-if="data.item.detailRecord!='player1'&&data.item.player1Otetsuki=='1'">×</div>
          <div v-if="data.item.detailRecord!='player1'&&data.item.player1Otetsuki=='2'">●</div>
          <div v-if="data.item.detailRecord==''&&data.item.player1Otetsuki==data.item.player2Otetsuki">△</div>
          <div v-else></div>
        </template>
        <template v-slot:cell(defuda)="data">
          {{data.item.card}}
        </template>
        <template v-slot:cell(player2situation)="data">
          <div v-if="data.item.detailRecord=='player2'&&data.item.player2Otetsuki=='0'">○</div>
          <div v-if="data.item.detailRecord=='player2'&&data.item.player2Otetsuki=='1'">⊗</div>
          <div v-if="data.item.detailRecord!='player2'&&data.item.player2Otetsuki=='0'"></div>
          <div v-if="data.item.detailRecord!='player2'&&data.item.player2Otetsuki=='1'">×</div>
          <div v-if="data.item.detailRecord!='player2'&&data.item.player2Otetsuki=='2'">●</div>
          <div v-if="data.item.detailRecord==''&&data.item.player1Otetsuki==data.item.player2Otetsuki">△</div>
        </template>
        <template v-slot:cell(numeroSign2)="data">
          {{ data.index + 1 + 10*(recordCurrentPage-1) }}
        </template>
      </b-table>
      <b-button size="sm" class="mx-1" variant="success" @click="setInsertNumber();$bvModal.show('input')">入力</b-button>
      <b-modal v-bind:id="'input'" hide-footer hide-header>
        <div class="text-center">
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">何枚目？</label>
            <b-form-input class="col-8" type=number v-model="insertNumber" :max="this.detailRecord.length+1" :min=1>
            </b-form-input>
          </div>
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">出札？</label>
            <b-form-select class="col-8" v-model="param.defuda" :options="defudaOptions" @change="changeOtetsukiOptions();removeParamValue()">
            </b-form-select>
          </div>
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">読札</label>
            <b-form-select class="col-8" v-model="param.cardGroup" :options="hyakuninnisshuGroup">
            </b-form-select>
            <label class="col-4 my-auto" for="inline-form-custom-select-pref"></label>
            <b-form-select class="col-8" v-model="param.card" :options="getUnusedCards(param.cardGroup)" @change="suggestKimariji">
            </b-form-select>
          </div>

          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">決まり字</label>
            <b-form-select :disabled="!param.defuda" class="col-4" v-model="param.kimariji" :options="kimarijiOptions">
            </b-form-select>
          </div>
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">取った選手</label>
            <b-form-select :disabled="!param.defuda" class="col-8" v-model="param.detailRecord" :options="detailRecordOptions">
            </b-form-select>
          </div>
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">{{player1}}<br>お手付き</label>
            <b-form-select class="col-8" v-model="param.player1Otetsuki" :options="otetsukiOptions">
            </b-form-select>
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">{{player2}}<br>お手付き</label>
            <b-form-select class="col-8" v-model="param.player2Otetsuki" :options="otetsukiOptions">
            </b-form-select>
          </div>
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">{{player1}}<br>コメント</label>
            <b-form-textarea class="col-8 form-control" v-model="param.player1comment" rows="1" max-rows="5"></b-form-textarea>
          </div>
          <div class="row">
            <label class="col-4 my-auto" for="inline-form-custom-select-pref">{{player2}}<br>コメント</label>
            <b-form-textarea class="col-8 form-control" v-model="param.player2comment" rows="1" max-rows="5"></b-form-textarea>
          </div>
          <b-button class="mx-1" variant="secondary" @click="resetParam();$bvModal.hide('input')">やめる</b-button>
          <b-button class="mx-1" variant="success" @click="$bvModal.hide('input');onsubmit()">記録</b-button>
        </div>
      </b-modal>
      <b-modal v-bind:id="'edit'" hide-footer hide-header>
        <div class="text-center">
          <div class="row">
            <div class="col-12">
              <b-button size="sm" class="m-1 float-left" variant="danger" id="show-btn" @click="$bvModal.show('remove')"><font-awesome-icon :icon="['far','trash-alt']"/></b-button>
            </div>
          </div>
          <div class="form-group border-success" id="record" style="margin-top:1rem">
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">出札？</label>
              <b-form-select class="col-8" v-model="editParam.defuda" :options="defudaOptions" @change="removeEditParamValue">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">読札</label>
              <b-form-select class="col-8" v-model="editParam.cardGroup" :options="hyakuninnisshuGroup">
              </b-form-select>
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">読札</label>
              <b-form-select class="col-8" v-model="editParam.card" :options="hyakuninnisshu[editParam.cardGroup]">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">決まり字</label>
              <b-form-select :disabled="!editParam.defuda" class="col-4" v-model="editParam.kimariji" :options="kimarijiOptions">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">場所</label>
              <b-form-select :disabled="!editParam.defuda" class="col-4" v-model="editParam.place1" :options="place1Options">
              </b-form-select>
              <b-form-select :disabled="!editParam.defuda" class="col-4" v-model="editParam.place2" :options="place2Options">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">状況</label>
              <b-form-select :disabled="!editParam.defuda" class="col-8" v-model="editParam.situation" :options="situationOptions">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">取った</label>
              <b-form-select :disabled="!editParam.defuda" class="col-8" v-model="editParam.detailRecord" :options="detailRecordOptions">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">評価</label>
              <b-form-select :disabled="!editParam.defuda" class="col-8" v-model="editParam.evaluation" :options="evaluationOptions">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">お手付き</label>
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">自分</label>
              <b-form-select class="col-4" v-model="editParam.player1Otetsuki" :options="otetsukiOptions">
              </b-form-select>
              <label class="col-4 my-auto" for="inline-form-custom-select-pref"></label>
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">相手</label>
              <b-form-select class="col-4" v-model="editParam.player2Otetsuki" :options="otetsukiOptions">
              </b-form-select>
            </div>
            <div class="row">
              <label class="col-4 my-auto" for="inline-form-custom-select-pref">コメント</label>
              <b-form-textarea class="col-8 form-control" v-model="editParam.comment" rows="1" max-rows="5"></b-form-textarea>
            </div>
            <b-button class="mx-1" variant="secondary" @click="$bvModal.hide('edit')">やめる</b-button>
            <b-button class="mx-1" variant="success" @click="$bvModal.hide('edit');edit()">更新</b-button>
          </div>
        </div>
      </b-modal>
      <b-modal v-bind:id="'remove'" hide-footer hide-header>
        <div class="text-center">
          <p>削除します。</p>
          <b-button size="sm" class="mx-1" variant="secondary" @click="$bvModal.hide('remove')">閉じる</b-button>
          <b-button size="sm" class="mx-1" variant="danger" @click="removeRecord();$bvModal.hide('remove');$bvModal.hide('edit')">削除</b-button>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<style>
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .container {
    margin-bottom: 64px;
  }
}
.mt-4 {
  margin-top: 4px;
}
.row {
  margin-bottom: 4px;
  margin-left: 0px;
  margin-right: 0px;
}
.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}
.vdp-datepicker__calendar {
  width: auto !important;
}
/*PCの場合*/
.pc	{ display:inline!important; }
.mb	{ display:none!important; }
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .pc	{ display:none!important; }
  .mb { display:inline!important; }
}
.mx-1 {
  margin-bottom: 0.75em;
}
.table th{
  padding: 0.25em;
}
.table td{
  padding: 0.25em;
  vertical-align: middle;
}
.table td.records-3em{
  min-width: 3em;
}
.table td.records-1em{
  min-width: 1em;
}
.table td.records-10em{
  min-width: 10em;
}
.modal-dialog {
  max-width: 95%;
  margin: 1.75rem auto;
}
.tooltip-inner {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap:break-word;
}
.searchclear {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ccc;
  vertical-align: middle;
}
</style>

<script>
import Header from '@/components/Header.vue'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

export default {
  name: 'detailRecord',
  components:{
    Header,
  },
  created: function() {


  },
  methods: {
    setInsertNumber(){
      this.insertNumber = this.detailRecord.length + 1;
    },
    onsubmit(){
      this.detailRecord.splice(this.insertNumber-1,0,this.param)
      localStorage.setItem('detailRecordPublic', JSON.stringify(this.detailRecord))
      this.param = {
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        player1Otetsuki:0,
        player2Otetsuki:0,
        comment:'',
      },
      this.insertNumber = this.detailRecord.length+1;
      this.scrollToEnd();
    },
    edit(){
      this.detailRecord[this.index]=this.editParam;
      localStorage.setItem('detailRecordPublic', JSON.stringify(this.detailRecord))
      this.editParam = {
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        player1Otetsuki:0,
        player2Otetsuki:0,
        comment:'',
      }
      this.index='';
    },
    removeRecord(){
      this.detailRecord.splice(this.index,1)
      localStorage.setItem('detailRecordPublic', JSON.stringify(this.detailRecord))
      this.index = ''
      this.insertNumber = this.detailRecord.length+1
    },
    scrollToEnd: function() {
      var element = document.documentElement;
      var bottom = element.scrollHeight - element.clientHeight;
      window.scroll(0, bottom+500);
    },
    getUnusedCards(cardGroup){
      if(cardGroup == '') {
        return []
      }
      if(this.detailRecord.length==0){
        return this.hyakuninnisshu[cardGroup]
      }
      var usedCards = []
      for (const item of this.detailRecord){
        if(item.card !== ''){
          usedCards.push(item.card)
        }
      }
      var unUsedCards = []
      if(cardGroup !== ''){
        for (const item of this.hyakuninnisshu[cardGroup]) {

          if(usedCards.indexOf(item)===-1){
            unUsedCards.push(item)
          }
        }
      }
      return unUsedCards
    },
    setIndex(index){
      this.index = index
    },
    setEditParam(){
      // this.editParam = this.detailRecord[this.index];
      this.editParam = JSON.parse(JSON.stringify(this.detailRecord[this.index]));
    },
    onRowSelected(items) {
      this.selected = items
    },
    readDataFromStorage(){
      this.detailRecord = JSON.parse(localStorage.getItem('detailRecordPublic'));
      if (!this.detailRecord) {
        this.detailRecord = [];
        this.insertNumber=1
      } else {
        this.insertNumber = this.detailRecord.length+1
      }
    },
    removeParamValue(){
      if(!this.param.defuda){
        this.param.kimariji=''
        this.param.place1='',
        this.param.place2=''
        this.param.situation=''
        this.param.detailRecord=''
        this.param.evaluation=''
      }
    },
    resetParam(){
      this.param={
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        player1Otetsuki:false,
        player2Otetsuki:false,
        comment:'',
      }
    },
    removeEditParamValue(){
      if(!this.editParam.defuda){
        this.editParam.kimariji=''
        this.editParam.place1='',
        this.editParam.place2=''
        this.editParam.situation=''
        this.editParam.detailRecord=''
        this.editParam.evaluation=''
      }
    },
    suggestKimariji(){
      console.log(this.param.cardGroup)
      var kimariji = 0;
      console.log(kimariji)
      var unUsedCards = this.getUnusedCards(this.param.cardGroup);
      unUsedCards.forEach((item) => {
        if(item!=this.param.card){
          var sameCharacterNumber = 0;
          var cardLength = this.param.card.length;
          var itemLength = item.length;
          for(let i=1;i<=Math.min(cardLength,itemLength);i++){
            if(this.param.card.slice(0,i)!=item.slice(0,i)){
              break;
            } else {
              sameCharacterNumber = i;
            }
          }
          if(sameCharacterNumber>kimariji){
            kimariji = sameCharacterNumber;
          }
        }
      });
      this.param.kimariji = String(kimariji+1)+"字";
    },
    setPlayer1(){
      for(let i in this.detailRecordOptions){
        if(this.detailRecordOptions[i].value=="player1"){
          this.detailRecordOptions[i].text=this.player1
        }
      }
    },
    setPlayer2(){
      for(let i in this.detailRecordOptions){
        if(this.detailRecordOptions[i].value=="player2"){
          this.detailRecordOptions[i].text=this.player2
        }
      }
    },
    changeOtetsukiOptions(){
      console.log("changed")
      if(this.param.defuda==true){
        this.otetsukiOptions=[{value:0,text:'-'},{value:1,text:'お手付き'}];
      } else {
        this.otetsukiOptions=[{value:0,text:'-'},{value:1,text:'お手付き'},{value:2,text:'空ダブ'}];
      }
    }
  },
  data() {
    return {
      gameInfo:'',
      place:'',
      date:'',
      reader:'',
      scorer:'',
      player1:'選手1',
      player1dan:'',
      player2dan:'',
      player2:'選手2',
      testObject:{},
      key:'',
      selected:{},
      hyakuninnisshu : {
        'むすめふさほせ':['む','す','め','ふ','さ','ほ','せ'],
        'うつしもゆ':['うか','うら','つき','つく','しら','しの','もも','もろ','ゆう','ゆら'],
        'いちひき':['いに','いまこ','いまは','ちは','ちぎりき','ちぎりお','ひさ','ひとは','ひとも','きり','きみがためは','きみがためお'],
        'はやよか':['はるす','はるの','はなさ','はなの','やえ','やす','やまが','やまざ','よを','よも','よのなかよ','よのなかは','かさ','かく','かぜそ','かぜを'],
        'み':['みち','みよ','みせ','みかき','みかの'],
        'た':['たか','たち','たご','たま','たき','たれ'],
        'こ':['これ','こひ','この','こぬ','こころに','こころあ'],
        'お':['おく','おぐ','おと','おも','おおけ','おおこ','おおえ'],
        'わ':['わび','わがい','わがそ','わすれ','わすら','わたのはらこ','わたのはらや'],
        'な':['なつ','ながら','ながか','なげけ','なげき','なにし','なにわが','なにわえ'],
        'あ':['あわじ','あわれ','あらし','あらざ','あきの','あきか','あまつ','あまの','ありあ','ありま','あさじ','あさぼらけあ','あさぼらけう','あし','あい','あけ'],
      },
      hyakuninnisshuGroup : ['','むすめふさほせ','うつしもゆ','いちひき','はやよか','み','た','こ','お','わ','な','あ'],
      param:{
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        player1Otetsuki:0,
        player2Otetsuki:0,
        player1comment:'',
        player2comment:''
      },
      editParam:{
        card:'',
        defuda:'',
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        player1Otetsuki:'',
        player2Otetsuki:'',
        player1comment:'',
        player2comment:''
      },
      index:'',
      detailRecord:[],
      defudaOptions:[
        {value:true,text:'出札'},
        {value:false,text:'空札'},
      ],
      kimarijiOptions:[
        {value:'',text:''},
        {value:'1字',text:'1字'},
        {value:'2字',text:'2字'},
        {value:'3字',text:'3字'},
        {value:'4字',text:'4字'},
        {value:'5字',text:'5字'},
        {value:'6字',text:'6字'},
      ],
      place1Options:[
        {value:'',text:''},
        {value:'自陣',text:'自陣'},
        {value:'敵陣',text:'敵陣'},
      ],
      place2Options:[
        {value:'',text:''},
        {value:'右下段',text:'右下段'},
        {value:'右中段',text:'右中段'},
        {value:'右上段',text:'右上段'},
        {value:'左下段',text:'左下段'},
        {value:'左中段',text:'左中段'},
        {value:'左上段',text:'左上段'},
        {value:'浮き札（上段）',text:'浮き札（上段）'},
        {value:'浮き札（中段）',text:'浮き札（中段）'},
        {value:'浮き札（下段）',text:'浮き札（下段）'},
      ],
      situationOptions:[
        {value:'',text:''},
        {value:'単独',text:'単独'},
        {value:'右対角分かれ',text:'右対角分かれ'},
        {value:'左対角分かれ',text:'左対角分かれ'},
        {value:'右縦分かれ',text:'右縦分かれ'},
        {value:'左縦分かれ',text:'左縦分かれ'},
        {value:'自陣左右',text:'自陣左右'},
        {value:'敵陣左右',text:'敵陣左右'},
        {value:'3箇所',text:'3箇所'},
        {value:'4箇所',text:'4箇所'},
      ],
      detailRecordOptions:[
        {value:'player1',text:'選手1'},
        {value:'player2',text:'選手2'},
      ],
      evaluationOptions:[
        {value:'◎',text:'◎'},
        {value:'○',text:'○'},
        {value:'△',text:'△'},
        {value:'×',text:'×'},
      ],
      otetsukiOptions:[
        {value:0,text:'-'},
        {value:1,text:'お手つき'},
      ],
      recordCurrentPage:1,
      result:{
        defaultDate:new Date(),
        round:'',
        opponent:'',
        club:'',
        rank:'',
        result:'',
        cardnumber:'',
        otetsukiNumber:'',
        comment:'',
        player:'',
        hand:'',
        place:'',
        reader:'',
        type:'',
        dan:'',
      },
      resultForTable:[],
      recordFields:[
        {
          key:'numeroSign1',
          label:'No.'
        },
        {
          key:'player1RestCard',
          label:'残枚数'
        },
        {
          key:'player1comment',
          label:'コメント'
        },
        {
          key:'player1situation',
          label:'選手1'
        },
        {
          key:'defuda',
          label:'出札'
        },
        {
          key:'player2situation',
          label:'選手2',
        },
        {
          key:'player2comment',
          label:'コメント'
        },
        {
          key:'player2RestCard',
          label:'残枚数'
        },
        {
          key:'numeroSign2',
          label:'No.'
        },
      ],
      fields:[{key:'date',label:'日付'},{key:'opponent',label:'相手'},{key:'club',label:'所属'},{key:'result',label:'結果'},{key:'cardnumber',label:'枚数'}],
      insertNumber:1,
    }
  },
  filters: {
    dayjs: function (date) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    dayjs2: function (date) {
      return dayjs(date).format('MM/DD');
    },
    truncate: function(value) {
      var length2 = 5;
      var ommision =  '...';

      if (value.length <= length2) {
        return value;
      }

      return value.substring(0, length2) + ommision;
    },
  },
  mounted: function(){
    this.readDataFromStorage();
  }
}
</script>
