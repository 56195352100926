<template>
  <div class="container">
    <div class="border">
      <Header />
      <h5>集計条件</h5>
      <div class="form-group border-success" id="record">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <label for="datepicker" class="col-4 my-auto">開始日</label>
              <div class="col-8" id="date_picker">
                <Datepicker
                :language="ja"
                :format="DatePickerFormat"
                input-class="form-control"
                v-model="params.start"
                @closed="hideGraph"
                :typeable="false"
                name="datepicker"></Datepicker>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label for="datepicker" class="col-4 my-auto">終了日</label>
              <div class="col-8" id="date_picker">
                <Datepicker
                :language="ja"
                :format="DatePickerFormat"
                input-class="form-control"
                v-model="params.end"
                @closed="hideGraph"
                :typeable="false"
                name="datepicker"></Datepicker>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">対戦相手<br><input type="checkbox" v-model="params.opponentPerfectMatchingFlag" @input="hideGraph()"/><small>完全一致</small></label>
              <div class="col-8 my-auto" id="opponent">
                <input class="form-control" type="text" v-model="params.opponent" @input="hideGraph()">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">所属<br><input type="checkbox" v-model="params.clubPerfectMatchingFlag" @input="hideGraph()"/><small>完全一致</small></label>
              <div class="col-8 my-auto" id="club">
                <input class="form-control" type="text" v-model="params.club" @input="hideGraph()">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">形式</label>
              <div class="col-8" id="opponent">
                <input class="form-control" type="text" v-model="params.type" autocomplete="on" list="typeList" @input="hideGraph()">
                <datalist id="typeList">
                  <option>自会練習</option>
                  <option>他会練習</option>
                  <option>大会</option>
                  <option>一人取り</option>
                  <option>競技かるた ONLINE</option>
                  <option>払い練</option>
                  <option>札落とし</option>
                </datalist>
              </div>
            </div>
          </div>
          <div class="col-md-6 my-auto">
            <div class="row">
              <label for="hand" class="col-4 my-auto">有効手</label>
              <div class="col-8" id="result">
                <div class="pc">
                  <b-form-group class="my-auto">
                    <b-form-checkbox-group
                    v-model="params.hand"
                    :options="handOptionsForParams"
                    @input="hideGraph()"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div class="mb">
                  <b-form-select v-model="params.hand" :options="handOptionsForParams" multiple @input="hideGraph()"></b-form-select>
                  <b-button variant="link" @click="clearItem('hand')" class="searchclear">x</b-button>
                </div>
                <!-- <select class="form-control" v-model="params.hand" @input="hideGraph()">
                <option></option>
                <option>右</option>
                <option>左</option>
              </select> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 my-auto">
          <div class="row">
            <label for="rank" class="col-4 my-auto">級</label>
            <div class="col-8" id="rank">
              <div class="pc">
                <b-form-group class="my-auto">
                  <b-form-checkbox-group
                  v-model="params.rank"
                  :options="rankOptionsForParams"
                  @input="hideGraph()"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="mb">
                <b-form-select v-model="params.rank" :options="rankOptionsForParams" multiple @input="hideGraph()"></b-form-select>
                <b-button variant="link" @click="clearItem('rank')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-auto">
          <div class="row">
            <label for="rank" class="col-4 my-auto">段位</label>
            <div class="col-8" id="dan">
              <div class="pc">
                <b-form-group class="my-auto">
                  <b-form-checkbox-group
                  v-model="params.dan"
                  :options="danOptionsForParams"
                  @input="hideGraph()"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="mb">
                <b-form-select v-model="params.dan" :options="danOptionsForParams" multiple @input="hideGraph()"></b-form-select>
                <b-button variant="link" @click="clearItem('dan')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-auto">
          <div class="row">
            <label for="result" class="col-4 my-auto">勝敗</label>
            <div class="col-8" id="result">
              <div class="pc">
                <b-form-group class="my-auto">
                  <b-form-checkbox-group
                  v-model="params.result"
                  :options="resultOptionsForParams"
                  @input="hideGraph()"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="mb">
                <b-form-select v-model="params.result" :options="resultOptionsForParams" multiple @input="hideGraph()"></b-form-select>
                <b-button variant="link" @click="clearItem('result')" class="searchclear">x</b-button>
              </div>
              <!-- <select class="form-control" v-model="params.result" @input="hideGraph()">
              <option></option>
              <option>勝ち</option>
              <option>負け</option>
              <option>その他</option>
            </select> -->
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label for="cardnumber" class="col-4 my-auto">枚数</label>
          <div class="col-8" id="cardnumber">
            <input @input="validateCardNumberStart;hideGraph()" class="form-control" style="width:45px;display:inline" type="tel" v-model="params.cardNumberStart">枚～
            <input @input="validateCardNumberEnd;hideGraph()" class="form-control" style="width:45px;display:inline" type="tel" v-model="params.cardNumberEnd">枚
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label for="otetsukiNumber" id="tooltip-otetsukiNumber-description" class="col-4 my-auto">お手<font-awesome-icon :icon="['fas','question-circle']"/></label>
          <div class="col-8" id="otetsukiNumber">
            <input @input="validateOtetsukiNumberStart;hideGraph()" class="form-control" style="width:45px;display:inline" type="tel" v-model="params.otetsukiNumberStart">回～
            <input @input="validateOtetsukiNumberEnd;hideGraph()" class="form-control" style="width:45px;display:inline" type="tel" v-model="params.otetsukiNumberEnd">回
          </div>
          <b-tooltip target="tooltip-otetsukiNumber-description" triggers="hover">
            集計条件として使用した場合、お手つきの回数が空白の記録は集計対象外となります。
          </b-tooltip>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label for="opponent" class="col-4 my-auto">コメント</label>
          <div class="col-8 my-auto" id="opponent">
            <input class="form-control" type="text" v-model="params.comment" @input="hideGraph()">
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div id="aggregateResults">
    <h5>集計結果（表）</h5>
  </div>
  <b-tabs>
    <b-tab title="勝敗" active>
      <table class="table col-sm-8 offset-sm-2 align-middle">
        <th>級</th><th>勝ち</th><th>負け</th><th>全体</th><th>勝率(%)</th>
        <tr v-show="(totalWin('A級')+totalLose('A級'))>0">
          <td>A級</td><td>{{totalWin('A級')}}</td><td>{{totalLose('A級')}}</td><td>{{totalWin('A級')+totalLose('A級')}}</td><td>{{winningPercentage('A級')}}</td>
        </tr>
        <tr v-show="(totalWin('B級')+totalLose('B級'))>0">
          <td>B級</td><td>{{totalWin('B級')}}</td><td>{{totalLose('B級')}}</td><td>{{totalWin('B級')+totalLose('B級')}}</td><td>{{winningPercentage('B級')}}</td>
        </tr>
        <tr v-show="(totalWin('C級')+totalLose('C級'))>0">
          <td>C級</td><td>{{totalWin('C級')}}</td><td>{{totalLose('C級')}}</td><td>{{totalWin('C級')+totalLose('C級')}}</td><td>{{winningPercentage('C級')}}</td>
        </tr>
        <tr v-show="(totalWin('D級')+totalLose('D級'))>0">
          <td>D級</td><td>{{totalWin('D級')}}</td><td>{{totalLose('D級')}}</td><td>{{totalWin('D級')+totalLose('D級')}}</td><td>{{winningPercentage('D級')}}</td>
        </tr>
        <tr v-show="(totalWin('E級')+totalLose('E級'))>0">
          <td>E級</td><td>{{totalWin('E級')}}</td><td>{{totalLose('E級')}}</td><td>{{totalWin('E級')+totalLose('E級')}}</td><td>{{winningPercentage('E級')}}</td>
        </tr>
        <tr v-show="(totalWin('F級')+totalLose('F級'))>0">
          <td>F級</td><td>{{totalWin('F級')}}</td><td>{{totalLose('F級')}}</td><td>{{totalWin('F級')+totalLose('F級')}}</td><td>{{winningPercentage('F級')}}</td>
        </tr>
        <tr v-show="(totalWin('')+totalLose(''))>0">
          <td>その他</td><td>{{totalWin('')}}</td><td>{{totalLose('')}}</td><td>{{totalWin('')+totalLose('')}}</td><td>{{winningPercentage('')}}</td>
        </tr>
        <tr v-show="(totalWin('all')+totalLose('all'))>0">
          <td>合計</td><td>{{totalWin('all')}}</td><td>{{totalLose('all')}}</td><td>{{totalWin('all')+totalLose('all')}}</td><td>{{winningPercentage('all')}}</td>
        </tr>
      </table>
      <div class="row">
        <form class="col-12">
          <b-button v-if="!showGraph" size="sm" :pressed.sync="showGraph" @click="setGraph" variant="success">グラフを見る</b-button>
          <b-button v-else size="sm" :pressed.sync="showGraph" @click="setGraph" variant="success">グラフを閉じる</b-button>
          <!-- <label for="showGraph">グラフ表示</label>
          <input type="checkbox" id="showGraph" v-model="showGraph" /> -->
        </form>
        <!-- {{setGraph()}} -->
        <div class="col-12">
          <div class="container" v-if="showGraph">
            <chart
            :chartType="chartType"
            :chartData="chartData"
            :chartOptions="chartOptions"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="container" v-if="showGraph">
            <chart
            :chartType="chartType2"
            :chartData="chartData2"
            :chartOptions="chartOptions2"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="container" v-if="showGraph">
            <chart
            :chartType="chartType3"
            :chartData="chartData3"
            :chartOptions="chartOptions3"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="container" v-if="showGraph">
            <chart
            :chartType="chartType4"
            :chartData="chartData4"
            :chartOptions="chartOptions4"
            />
          </div>
        </div>
      </div>
    </b-tab>
    <b-tab title="枚数">
      <table class="table col-sm-8 offset-sm-2 align-middle">
        <th>級</th><th>勝ち</th><th>負け</th>
        <tr v-show="(totalWin('A級')+totalLose('A級'))>0">
          <td>A級</td>
          <td><small><div v-html="measureCardNumber('A級','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('A級','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('B級')+totalLose('B級'))>0">
          <td>B級</td>
          <td><small><div v-html="measureCardNumber('B級','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('B級','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('C級')+totalLose('C級'))>0">
          <td>C級</td>
          <td><small><div v-html="measureCardNumber('C級','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('C級','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('D級')+totalLose('D級'))>0">
          <td>D級</td>
          <td><small><div v-html="measureCardNumber('D級','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('D級','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('E級')+totalLose('E級'))>0">
          <td>E級</td>
          <td><small><div v-html="measureCardNumber('E級','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('E級','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('F級')+totalLose('F級'))>0">
          <td>F級</td>
          <td><small><div v-html="measureCardNumber('F級','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('F級','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('')+totalLose(''))>0">
          <td>その他</td>
          <td><small><div v-html="measureCardNumber('','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('all')+totalLose('all'))>0">
          <td>全体</td>
          <td><small><div v-html="measureCardNumber('all','勝ち')"></div></small></td>
          <td><small><div v-html="measureCardNumber('all','負け')"></div></small></td>
        </tr>
      </table>
    </b-tab>
    <b-tab title="お手つき">
      <table class="table col-sm-8 offset-sm-2 align-middle">
        <th>級</th><th>勝ち</th><th>負け</th><th>全体</th>
        <tr v-show="(totalWin('A級')+totalLose('A級'))>0">
          <td>A級</td>
          <td><small><div v-html="measureOtetsukiNumber('A級','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('A級','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('A級','all')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('B級')+totalLose('B級'))>0">
          <td>B級</td>
          <td><small><div v-html="measureOtetsukiNumber('B級','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('B級','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('B級','all')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('C級')+totalLose('C級'))>0">
          <td>C級</td>
          <td><small><div v-html="measureOtetsukiNumber('C級','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('C級','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('C級','all')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('D級')+totalLose('D級'))>0">
          <td>D級</td>
          <td><small><div v-html="measureOtetsukiNumber('D級','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('D級','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('D級','all')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('E級')+totalLose('E級'))>0">
          <td>E級</td>
          <td><small><div v-html="measureOtetsukiNumber('E級','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('E級','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('E級','all')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('F級')+totalLose('F級'))>0">
          <td>F級</td>
          <td><small><div v-html="measureOtetsukiNumber('F級','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('F級','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('F級','all')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('')+totalLose(''))>0">
          <td>その他</td>
          <td><small><div v-html="measureOtetsukiNumber('','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('','all')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('','負け')"></div></small></td>
        </tr>
        <tr v-show="(totalWin('all')+totalLose('all'))>0">
          <td>全体</td>
          <td><small><div v-html="measureOtetsukiNumber('all','勝ち')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('all','負け')"></div></small></td>
          <td><small><div v-html="measureOtetsukiNumber('all','all')"></div></small></td>
        </tr>
      </table>
    </b-tab>
    <b-tab title="ランキング">
      <div>
        <label>種類：</label><b-form-select v-model="rankingSelected" :options="rankingOptions" style="width:auto;display:inline"></b-form-select><br>
        総試合数が<input class="form-control" style="width:45px;display:inline" type="tel" v-model="params.lowerLimit">試合以上のみを対象とする
        <div class="mt-3">
          <b-pagination
          v-model="rankingCurrentPage"
          :total-rows="rankingTotalRows"
          :per-page=10
          aria-controls="ranking-table"
          ></b-pagination>
          <b-table id="ranking-table" v-if="rankingSelected!=null" striped hover :items="showRanking(rankingSelected)" :fields="rankingFields" :per-page=10 :current-page="rankingCurrentPage">
            <template v-slot:[`cell(index)`]="data">
              {{ data.index + 1 + 10*(rankingCurrentPage-1) }}
            </template>
          </b-table>
          <b-pagination
          v-model="rankingCurrentPage"
          :total-rows="rankingTotalRows"
          :per-page=10
          aria-controls="ranking-table"
          ></b-pagination>
        </div>
      </div>
    </b-tab>
  </b-tabs>
  <hr>

  <div class="row">
    <div class="col-2 my-auto"></div>
    <div class="col-8 my-auto"><span style="font-size:1.25rem;font-weight:500">集計結果（データ）</span></div>
    <div class="col-2 my-auto">
      <b-button size="sm" variant="outline-success" v-b-toggle.columnVisibilitySettings class="m-1 float-right"><font-awesome-icon icon="cog"/></b-button>
      <!-- <a v-b-toggle.columnVisibilitySettings class="m-1 float-right"><font-awesome-icon icon="cog"/></a> -->
      <!-- <b-button size="sm" variant="success" v-b-toggle.columnVisibilitySettings class="m-1 float-right"><font-awesome-icon icon="cog"/></b-button> -->
    </div>
  </div>
  <b-collapse id="columnVisibilitySettings">
    <b-card>
      <div class="row" style="text-align:left">
        <div class="col-12" style="text-align:center">
          <b-form-group label="表示形式">
            <b-form-radio  @input="changeTableType()" size="lg" v-model="tableType" value="table" style="text-align:left">パソコン向け</b-form-radio>
            <b-form-radio  @input="changeTableType()" size="lg" v-model="tableType" value="block" style="text-align:left">スマホ・タブレット向け</b-form-radio>
          </b-form-group>
        </div>
        <div class="col-12" style="text-align:center">
          <p>表示項目</p>
        </div>
        <div class="col-6">
          <b-form-checkbox size="lg" switch v-model="visibility.Date" @input="changeVisibility('Date')" disabled>日付</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.type" @input="changeVisibility('type')">形式</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.round" @input="changeVisibility('round')">回戦</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.player" @input="changeVisibility('player')">プレイヤー</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.place" @input="changeVisibility('place')">会場</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.reader" @input="changeVisibility('reader')">読手</b-form-checkbox>
        </div>
        <div class="col-6">
          <b-form-checkbox size="lg" switch v-model="visibility.opponent" @input="changeVisibility('opponent')">相手</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.club" @input="changeVisibility('club')">所属</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.rank" @input="changeVisibility('rank')">級</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.dan" @input="changeVisibility('dan')">段位</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.hand" @input="changeVisibility('hand')">有効手</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.result" @input="changeVisibility('result')">結果</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.cardnumber" @input="changeVisibility('cardnumber')">枚数</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.otetsukiNumber" @input="changeVisibility('otetsukiNumber')">お手</b-form-checkbox>
          <b-form-checkbox size="lg" switch v-model="visibility.comment" @input="changeVisibility('comment')">コメント</b-form-checkbox>
        </div>
      </div>
    </b-card>
  </b-collapse>
  <br>
  <b-pagination
  v-model="currentPage"
  :total-rows="filteredRecords.length"
  :per-page=25
  first-number
  last-number
  aria-controls="record-table"
  ></b-pagination>
  <!-- 表形式のテーブル -->
  <b-table v-if="tableType=='table'" id="record-table" bordered responsive hover :items="this.filteredRecords" :fields="fields" :per-page=25 :current-page="currentPage">
    <template v-slot:[`cell(date)`]="data">
      <div class="pc">{{data.item.value.defaultDate | dayjs}}</div>
      <div class="mb">{{data.item.value.defaultDate | dayjs2}}</div>
      <br>
      <b-button size="sm" class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingResult(data.item.value);$bvModal.show('edit')">詳細</b-button>
    </template>
    <template v-slot:[`cell(value.dan)`]="data">
      {{toKanji(data.item.value.dan)}}
    </template>
    <template v-slot:[`cell(value.opponent)`]="data">
      {{data.item.value.opponent}}
    </template>
  </b-table>
  <!-- ブロック形式のテーブル -->
  <b-table  v-if="tableType=='block'" id="record-table" striped bordered responsive :items="this.filteredRecords" :fields="fieldsForMobile" :per-page=25 :current-page="currentPage">
    <template v-slot:[`cell(record)`]="data">
      <!-- <b-card class="p-0"> -->
      <div v-show="visibility.Date" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">日付</div>
        <div class="col-8 text-left">{{data.item.value.defaultDate | dayjs}}</div>
      </div>
      <div v-show="visibility.type" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">形式</div>
        <div class="col-8 text-left">{{data.item.value.type}}</div>
      </div>
      <div v-show="visibility.round" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">回戦</div>
        <div class="col-8 text-left">{{data.item.value.round}}</div>
      </div>
      <div v-show="visibility.player" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">プレイヤー</div>
        <div class="col-8 text-left">{{data.item.value.player}}</div>
      </div>
      <div v-show="visibility.place" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">会場</div>
        <div class="col-8 text-left">{{data.item.value.place}}</div>
      </div>
      <div v-show="visibility.reader" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">読手</div>
        <div class="col-8 text-left">{{data.item.value.reader}}</div>
      </div>
      <hr class="my-1 mx-2" style="height:1px">
      <div v-show="visibility.opponent" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">相手</div>
        <div class="col-8 text-left">{{data.item.value.opponent}}</div>
      </div>
      <div v-show="visibility.club" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">所属</div>
        <div class="col-8 text-left">{{data.item.value.club}}</div>
      </div>
      <div v-show="visibility.rank" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">級</div>
        <div class="col-8 text-left">{{data.item.value.rank}}</div>
      </div>
      <div v-show="visibility.rank" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">段位</div>
        <div class="col-8 text-left">{{toKanji(data.item.value.dan)}}</div>
      </div>
      <div v-show="visibility.hand" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">有効手</div>
        <div class="col-8 text-left">{{data.item.value.hand}}</div>
      </div>
      <hr class="my-1 mx-2" style="height:1px">
      <div v-show="visibility.result" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">結果</div>
        <div class="col-8 text-left">{{data.item.value.result}}</div>
      </div>
      <div v-show="visibility.cardnumber" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">枚数</div>
        <div class="col-8 text-left">{{data.item.value.cardnumber}}</div>
      </div>
      <div v-show="visibility.otetsukiNumber" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">お手</div>
        <div class="col-8 text-left">{{data.item.value.otetsukiNumber}}</div>
      </div>
      <div v-show="visibility.comment" class="row" style="margin-right:0px;margin-left:0px">
        <div class="col-4 font-weight-bold">コメント</div>
        <div class="col-8 text-left">{{data.item.value.comment}}</div>
      </div>
      <b-button size="sm" class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingResult(data.item.value);$bvModal.show('edit')">詳細</b-button>
      <!-- </b-card> -->
    </template>
  </b-table>
  <b-modal v-bind:id="'edit'" hide-footer hide-header>
    <template v-slot:modal-title>
    </template>
    <div class="d-block text-center">
      <b-button size="sm" class="m-1 float-left" variant="danger" id="show-btn" @click="$bvModal.show('remove')"><font-awesome-icon :icon="['far','trash-alt']"/></b-button>
      <b-button size="sm" class="m-1 float-none" variant="success" v-b-toggle.editingResultVisibilitySettings>表示項目選択</b-button>
      <b-button size="sm" class="m-1 float-right" variant="secondary" @click="$bvModal.hide('edit')">閉じる</b-button>
      <b-collapse id="editingResultVisibilitySettings">
        <b-card>
          <div class="row" style="text-align:left">
            <div class="col-6">
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.Date" @input="changeEditingResultVisibility()" disabled>日付</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.type" @input="changeEditingResultVisibility()">形式</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.round" @input="changeEditingResultVisibility()">回戦</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.player" @input="changeEditingResultVisibility()">プレイヤー</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.place" @input="changeEditingResultVisibility()">会場</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.reader" @input="changeEditingResultVisibility()">読手</b-form-checkbox>
            </div>
            <div class="col-6">
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.opponent" @input="changeEditingResultVisibility()">相手</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.club" @input="changeEditingResultVisibility()">所属</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.rank" @input="changeEditingResultVisibility()">級</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.dan" @input="changeEditingResultVisibility()">段位</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.hand" @input="changeEditingResultVisibility()">有効手</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.result" @input="changeEditingResultVisibility()">結果</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.cardnumber" @input="changeEditingResultVisibility()">枚数</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.otetsukiNumber" @input="changeEditingResultVisibility()">お手</b-form-checkbox>
              <b-form-checkbox size="lg" switch v-model="editingResultVisibility.comment" @input="changeEditingResultVisibility()">コメント</b-form-checkbox>
            </div>
          </div>
        </b-card>
      </b-collapse>
      <br>
      <div class="form-group border-success" id="record" style="margin-top:1rem">
        <div class="row">
          <div v-show="editingResultVisibility.Date" class="col-md-6">
            <div class="row">
              <label for="datepicker" class="col-4 my-auto">日付</label>
              <div class="col-8" id="date_picker">
                <b-form-input v-model="editingResult.defaultDate" :type="'date'"></b-form-input>
                <!-- <Datepicker
                :language="ja"
                :format="DatePickerFormat"
                class="DatepickerStyle"
                input-class="form-control"
                v-model="editingResult.defaultDate"
                name="datepicker"></Datepicker> -->
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.type" class="col-md-6">
            <div class="row">
              <label for="type" class="col-4 my-auto">形式</label>
              <div class="col-8" id="hand">
                <input class="form-control" type="text" v-model="editingResult.type" autocomplete="on" list="typeList">
                <datalist id="typeList">
                  <option>自会練習</option>
                  <option>他会練習</option>
                  <option>大会</option>
                  <option>一人取り</option>
                  <option>競技かるた ONLINE</option>
                  <option>払い練</option>
                  <option>札落とし</option>
                </datalist>
                <b-button variant="link" @click="clearEditingItem('type')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.round" class="col-md-6">
            <div class="row">
              <label for="round" class="col-4 my-auto">回戦</label>
              <div class="col-8" id="round">
                <input @input="validateEditingRound" class="form-control" type="tel" v-model="editingResult.round">
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.player" class="col-md-6">
            <div class="row">
              <label for="player" class="col-4 my-auto">プレイヤー</label>
              <div class="col-8" id="player">
                <input class="form-control" type="text" v-model="editingResult.player" autocomplete="on">

              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.place" class="col-md-6">
            <div class="row">
              <label for="place" class="col-4 my-auto">会場</label>
              <div class="col-8" id="place">
                <input class="form-control" type="text" v-model="editingResult.place" autocomplete="on">
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.reader" class="col-md-6">
            <div class="row">
              <label for="reader" class="col-4 my-auto">読手</label>
              <div class="col-8" id="place">
                <input class="form-control" type="text" v-model="editingResult.reader" autocomplete="on">
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.opponent" class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">相手</label>
              <div class="col-8" id="opponent">
                <input @change="suggestClubAndRankForEditingResult" class="form-control" type="text" v-model="editingResult.opponent" autocomplete="on" list="opponentList">
                <datalist id="opponentList">
                  <option v-for="(item,key) in opponentSuggestionList" :key="key">{{item}}</option>
                </datalist>
                <b-button variant="link" @click="clearEditingItem('opponent')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.club" class="col-md-6">
            <div class="row">
              <label for="opponent" class="col-4 my-auto">所属</label>
              <div class="col-8" id="club">
                <!-- <input class="form-control" type="text" v-model="editingResult.club"> -->
                <input class="form-control" type="text" v-model="editingResult.club" autocomplete="on" list="clubList">
                <datalist id="clubList">
                  <option v-for="(item,key) in clubSuggestionList" :key="key">{{item}}</option>
                </datalist>
                <b-button variant="link" @click="clearEditingItem('club')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.rank" class="col-md-6">
            <div class="row">
              <label for="rank" class="col-4 my-auto">級</label>
              <div class="col-8" id="rank">
                <b-form-select v-model="editingResult.rank" :options="rankOptions"></b-form-select>
                <b-button variant="link" @click="clearEditingItem('rank')" class="searchclear">x</b-button>
              </div>

            </div>
          </div>
          <div v-show="editingResultVisibility.dan" class="col-md-6">
            <div class="row">
              <label for="dan" class="col-4 my-auto">段位</label>
              <div class="col-8" id="dan">
                <b-form-select v-model="editingResult.dan" :options="danOptions"></b-form-select>
                <b-button variant="link" @click="clearEditingItem('dan')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.hand" class="col-md-6">
            <div class="row">
              <label for="hand" class="col-4 my-auto">有効手</label>
              <div class="col-8" id="hand">
                <b-form-select v-model="editingResult.hand" :options="handOptions"></b-form-select>
                <b-button variant="link" @click="clearEditingItem('hand')" class="searchclear">x</b-button>
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.result" class="col-md-6">
            <div class="row">
              <label for="result" class="col-4 my-auto">勝敗</label>
              <div class="col-8" id="result">
                <b-form-select v-model="editingResult.result" :options="resultOptions"></b-form-select>
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.cardnumber" class="col-md-6">
            <div class="row">
              <label for="cardnumber" class="col-4 my-auto">枚数</label>
              <div class="col-8" id="cardnumber">
                <input @input="validateEditingCardnumber" class="form-control" type="tel" v-model="editingResult.cardnumber">
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.otetsukiNumber" class="col-md-6">
            <div class="row">
              <label for="cardnumber" class="col-4 my-auto">お手</label>
              <div class="col-8" id="otetsukiNumber">
                <input @input="validateEditingOtetsukiNumber" class="form-control" type="tel" v-model="editingResult.otetsukiNumber">
              </div>
            </div>
          </div>
          <div v-show="editingResultVisibility.comment" class="col-md-6">
            <div class="row row-eq-height">
              <label for="comment" class="col-4 my-auto">コメント</label>
              <div class="col-8" id="comment">
                <b-form-textarea class="form-control" v-model="editingResult.comment" rows="3" max-rows="8"></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="col-md-6 align-bottom">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <b-button class="mx-1" variant="secondary" @click="$bvModal.hide('edit')">閉じる</b-button>
      <b-button class="mx-1" variant="success" @click="edit();$bvModal.hide('edit')">更新</b-button>
    </div>
  </b-modal>
  <b-modal v-bind:id="'remove'" hide-footer hide-header>
    <template v-slot:modal-title>
    </template>
    <div class="d-block text-center">
      <p>本当に記録を削除しますか？</p>
    </div>
    <div class="text-center">
      <b-button class="mx-1" variant="secondary" @click="$bvModal.hide('remove')">やめる</b-button>
      <b-button class="mx-1" variant="danger" @click="remove();$bvModal.hide('remove');$bvModal.hide('edit')">削除</b-button>
    </div>
  </b-modal>
  <b-pagination
  v-model="currentPage"
  :total-rows="filteredRecords.length"
  :per-page=25
  first-number
  last-number
  aria-controls="record-table"
  ></b-pagination>
  <b-button size="sm" variant="success" v-on:click="downloadCSV" class="m-1">集計結果をダウンロード</b-button>
</div>
</div>
</template>

<style>
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .container {
    margin-bottom: 64px;
  }
}
.mt-4 {
  margin-top: 4px;
}
.row {
  margin-bottom: 4px;
}
.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}
.vdp-datepicker__calendar {
  width: auto !important;
}
/*PCの場合*/
.pc	{ display:inline!important; }
.mb	{ display:none!important; }
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .pc	{ display:none!important; }
  .mb { display:inline!important; }
}

.mx-1 {
  margin-bottom: 0.75em;
}
.table th{
  padding: 0.25em;
}
.table td{
  padding: 0.25em;
}
.table td.records-3em{
  min-width: 3em;
}
.table td.records-1em{
  min-width: 1em;
}
.table td.records-10em{
  min-width: 10em;
}
.modal-dialog {
  max-width: 95%;
  margin: 1.75rem auto;
}

.tooltip-inner {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap:break-word;
}

.table caption {
  caption-side: top;
}

</style>

<script>
import firebase from 'firebase/app'
import'firebase/auth'
import'firebase/database'
import Datepicker from 'vuejs-datepicker';
import {ja} from 'vuejs-datepicker/dist/locale';
import Header from '@/components/Header.vue'
import Chart from '@/components/Chart.vue'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'


export default {
  name: 'statistics',
  created: function(){
    this.database = firebase.database();
    this.uid = firebase.auth().currentUser.uid;
    this.list = this.database.ref('records/'+this.uid+'/').orderByChild("reverseDefaultDate");
    var _this = this;
    // キャッシュ化？
    // this.recordsForTable = JSON.parse(localStorage.getItem('recordsForTable'));
    // localStorage.setItem('recordsForTable', JSON.stringify(_this.recordsForTable))
    this.list.on('value',function(snapshot){
      let array = {};
      let opponentSet = new Set();
      let clubSet = new Set();
      snapshot.forEach(child => {
        array[child.key]=child.val();
        opponentSet.add(child.val()["opponent"])
        clubSet.add(child.val()["club"])
      });
      _this.records = array;
      _this.recordsForTable = Object.entries(array).map(([key, value]) => ({key, value}))
      _this.opponentSuggestionList = [...opponentSet]
      _this.clubSuggestionList = [...clubSet]
      _this.opponentSuggestionSet = opponentSet
      _this.clubSuggestionSet = clubSet
    });

    // 記録ページから遷移してきた場合、パラメータを集計条件にセット
    if(!(typeof this.$route.params.opponent === "undefined")){
      this.params.opponent = this.$route.params.opponent
    }
    if(!(typeof this.$route.params.club === "undefined")){
      this.params.club = this.$route.params.club
    }
    if(!(typeof this.$route.params.type === "undefined")){
      this.params.type = this.$route.params.type
    }
    if(!(typeof this.$route.params.rank === "undefined")){
      this.params.rank = [this.$route.params.rank]
    }
    if(!(typeof this.$route.params.dan === "undefined")){
      this.params.dan = [this.$route.params.dan]
    }
    if(!(typeof this.$route.params.hand === "undefined")){
      this.params.hand = [this.$route.params.hand]
    }
    if(!(typeof this.$route.params.result === "undefined")){
      this.params.result = [this.$route.params.result]
    }

    //firebase analytics
    firebase.analytics().logEvent('record_loaded',{
      username:this.user.displayName
    })
  },
  methods: {
    totalWin(rank) {
      let sum = 0;
      for (const item of this.filteredRecords) {
        if(rank!=''){
          if(rank=='all') {
            if(item.value.result=='勝ち'){
              sum += + 1;
            }
          } else {
            if(item.value.rank==rank && item.value.result=='勝ち'){
              sum += + 1;
            }
          }
        } else {
          if(item.value.rank=='' && item.value.result=='勝ち')
          sum += + 1;
        }
      }
      return sum;
    },
    totalLose(rank) {
      let sum = 0;
      for (const item of this.filteredRecords) {
        if(rank!=''){
          if(rank=='all') {
            if(item.value.result=='負け'){
              sum += + 1;
            }
          } else {
            if(item.value.rank==rank && item.value.result=='負け'){
              sum += + 1;
            }
          }
        } else {
          if(item.value.rank=='' && item.value.result=='負け')
          sum += + 1;
        }
      }
      return sum;
    },
    winningPercentage(rank){
      if((this.totalWin(rank)+this.totalLose(rank))==0){
        switch (rank) {
          case 'A級':
          this.chartData[1] = ['A級','']
          break;
          case 'B級':
          this.chartData[2] = ['B級','']
          break;
          case 'C級':
          this.chartData[3] = ['C級','']
          break;
          case 'D級':
          this.chartData[4] = ['D級','']
          break;
          case 'E級':
          this.chartData[5] = ['E級','']
          break;
          case 'F級':
          this.chartData[6] = ['F級','']
          break;
          case '':
          this.chartData[7] = ['その他','']
          break;
          default:
        }
        return "NaN"
      } else {
        var winningPercentage = Math.floor(this.totalWin(rank)/(this.totalWin(rank)+this.totalLose(rank))*100)
        switch (rank) {
          case 'A級':
          this.chartData[1] = ['A級',winningPercentage]
          break;
          case 'B級':
          this.chartData[2] = ['B級',winningPercentage]
          break;
          case 'C級':
          this.chartData[3] = ['C級',winningPercentage]
          break;
          case 'D級':
          this.chartData[4] = ['D級',winningPercentage]
          break;
          case 'E級':
          this.chartData[5] = ['E級',winningPercentage]
          break;
          case 'F級':
          this.chartData[6] = ['F級',winningPercentage]
          break;
          case '':
          this.chartData[7] = ['その他',winningPercentage]
          break;
          default:

        }
        return winningPercentage;
      }
    },
    measureCardNumber(rank,result){
      var cardNumberArray = new Array()
      this.filteredRecords.forEach(function(item){
        if(rank!='all'){
          if(item.value.rank==rank&&item.value.result==result&&item.value.cardnumber!=''){
            cardNumberArray.push(item.value.cardnumber)
          }
        } else {
          if(item.value.result==result&&item.value.cardnumber!=''){
            cardNumberArray.push(item.value.cardnumber)
          }
        }
      })
      if(cardNumberArray.length>0){
        var sum = cardNumberArray.reduce(function (acc, cur) {
          return acc + cur;
        },0);
        var ave = Math.round(sum/cardNumberArray.length*10)/10
        var max = cardNumberArray.reduce(function (acc, cur) {
          return Math.max(acc,cur)
        },cardNumberArray[0]);
        var min = cardNumberArray.reduce(function (acc, cur) {
          return Math.min(acc,cur)
        },cardNumberArray[0]);
        return '平均'+ave+'枚<br>最大'+max+'枚<br>最小'+min+'枚<br>(n='+cardNumberArray.length+')'
      } else {
        return '-'
      }
    },
    measureOtetsukiNumber(rank,result){
      var otetsukiNumberArray = new Array()
      this.filteredRecords.forEach(function(item){
        if(rank!='all'){
          if(result!='all'){
            //級と結果の両方指定
            if(item.value.rank==rank&&item.value.result==result&&item.value.otetsukiNumber!==''){
              otetsukiNumberArray.push(item.value.otetsukiNumber)
            }
          } else {
            //級のみ指定
            if(item.value.rank==rank&&item.value.otetsukiNumber!==''){
              otetsukiNumberArray.push(item.value.otetsukiNumber)
            }
          }
        } else {
          if(result!='all'){
            //結果のみ指定
            if(item.value.result==result&&item.value.otetsukiNumber!==''){
              otetsukiNumberArray.push(item.value.otetsukiNumber)
            }
          } else {
            //指定なし
            if(item.value.otetsukiNumber!==''){
              otetsukiNumberArray.push(item.value.otetsukiNumber)
            }
          }
        }
      })
      if(otetsukiNumberArray.length>0){
        var sum = otetsukiNumberArray.reduce(function (acc, cur) {
          return acc + cur;
        },0);
        var ave = Math.round(sum/otetsukiNumberArray.length*10)/10
        var max = otetsukiNumberArray.reduce(function (acc, cur) {
          return Math.max(acc,cur)
        },otetsukiNumberArray[0]);
        var min = otetsukiNumberArray.reduce(function (acc, cur) {
          return Math.min(acc,cur)
        },otetsukiNumberArray[0]);
        return '平均'+ave+'回<br>最大'+max+'回<br>最小'+min+'回<br>(n='+otetsukiNumberArray.length+')'
      } else {
        return '-'
      }
    },
    setGraph() {
      this.chartData2[1] = ['A級',this.totalWin('A級')+this.totalLose('A級')]
      this.chartData2[2] = ['B級',this.totalWin('B級')+this.totalLose('B級')]
      this.chartData2[3] = ['C級',this.totalWin('C級')+this.totalLose('C級')]
      this.chartData2[4] = ['D級',this.totalWin('D級')+this.totalLose('D級')]
      this.chartData2[5] = ['E級',this.totalWin('E級')+this.totalLose('E級')]
      this.chartData2[6] = ['F級',this.totalWin('F級')+this.totalLose('F級')]
      this.chartData2[7] = ['その他',this.totalWin('')+this.totalLose('')]
      this.chartData3[1] = ['A級',this.totalWin('A級')]
      this.chartData3[2] = ['B級',this.totalWin('B級')]
      this.chartData3[3] = ['C級',this.totalWin('C級')]
      this.chartData3[4] = ['D級',this.totalWin('D級')]
      this.chartData3[5] = ['E級',this.totalWin('E級')]
      this.chartData3[6] = ['F級',this.totalWin('F級')]
      this.chartData3[7] = ['その他',this.totalWin('')]
      this.chartData4[1] = ['A級',this.totalLose('A級')]
      this.chartData4[2] = ['B級',this.totalLose('B級')]
      this.chartData4[3] = ['C級',this.totalLose('C級')]
      this.chartData4[4] = ['D級',this.totalLose('D級')]
      this.chartData4[5] = ['E級',this.totalLose('E級')]
      this.chartData4[6] = ['F級',this.totalLose('F級')]
      this.chartData4[7] = ['その他',this.totalLose('')]
    },
    validateRound(){
      var round = Number(this.result.round.replace(/\D/g, ''));
      if (round == 0){
        this.result.round = ''
      } else {
        this.result.round = round
      }
    },
    validateCardNumberStart(){
      var cardnumber = Number(this.params.cardNumberStart.replace(/\D/g, ''));
      if (cardnumber == 0){
        this.params.cardNumberStart = ''
      } else {
        if(cardnumber > 48) {
          this.params.cardNumberStart = 48
        } else {
          this.params.cardNumberStart = cardnumber
        }
      }
    },
    validateCardNumberEnd(){
      var cardnumber = Number(this.params.cardNumberEnd.replace(/\D/g, ''));
      if (cardnumber == 0){
        this.params.cardNumberEnd = ''
      } else {
        if(cardnumber > 48) {
          this.params.cardNumberEnd = 48
        } else {
          this.params.cardNumberEnd = cardnumber
        }
      }
    },
    validateOtetsukiNumberStart(){
      if (this.params.otetsukiNumberStart!=''){
        var otetsukiNumber = Number(this.params.otetsukiNumberStart.replace(/\D/g, ''));
        this.params.otetsukiNumberStart = otetsukiNumber
      }
    },
    validateOtetsukiNumberEnd(){
      if (this.params.otetsukiNumberEnd!=''){
        var otetsukiNumber = Number(this.params.otetsukiNumberEnd.replace(/\D/g, ''));
        this.params.otetsukiNumberEnd = otetsukiNumber
      }
    },
    setFocusKey : function(key){
      this.focusKey = key;
    },
    remove : function(){
      // 削除
      firebase.database().ref('records/'+this.uid).child(this.focusKey).remove();

      // detailRecordsに記録がある場合、それを削除する
      firebase.database().ref('detailRecords/'+this.uid+'/'+this.focusKey).once("value",snapshot => {
        if(snapshot.exists()){
          firebase.database().ref('detailRecords/'+this.uid).child(this.focusKey).remove();
        }
      });

      firebase.analytics().logEvent('record_removed',{
        username:this.user.displayName
      })
    },
    edit : function(){
      let defaultDate = dayjs(this.editingResult.defaultDate,"YYYY-MM-DD")
      this.editingResult.defaultDate = defaultDate.valueOf();
      this.editingResult.reverseDefaultDate = -(this.editingResult.defaultDate+this.editingResult.round);

      // 更新
      firebase.database().ref('records/'+this.uid).child(this.focusKey).update(this.editingResult);

      // detailRecordsに記録がある場合、それを更新する
      firebase.database().ref('detailRecords/'+this.uid+'/'+this.focusKey).once("value",snapshot => {
        if(snapshot.exists()){
          firebase.database().ref('detailRecords/'+this.uid).child(this.focusKey).child('result').update(this.editingResult);
        }
      });

      firebase.analytics().logEvent('record_edited',{
        username:this.user.displayName
      })
    },
    loadEditingResult : function(item){
      this.editingResult.defaultDate = dayjs(item.defaultDate).format('YYYY-MM-DD')
      this.editingResult.round = item.round;
      this.editingResult.opponent = item.opponent;
      this.editingResult.club = item.club;
      this.editingResult.rank = item.rank;
      this.editingResult.result = item.result;
      this.editingResult.cardnumber = item.cardnumber;
      this.editingResult.otetsukiNumber = item.otetsukiNumber;
      this.editingResult.comment = item.comment;
      if (!(typeof item.player === "undefined")) {
        this.editingResult.player =item.player;
      } else {
        this.editingResult.player =""
      }
      if (!(typeof item.hand === "undefined")) {
        this.editingResult.hand =item.hand;
      } else {
        this.editingResult.hand =""
      }
      if (!(typeof item.place === "undefined")) {
        this.editingResult.place =item.place;
      } else {
        this.editingResult.place ="";
      }
      if (!(typeof item.reader === "undefined")) {
        this.editingResult.reader =item.reader;
      } else {
        this.editingResult.reader ="";
      }
      if (!(typeof item.type === "undefined")) {
        this.editingResult.type =item.type;
      } else {
        this.editingResult.type ="";
      }
      if (!(typeof item.dan === "undefined")) {
        this.editingResult.dan =item.dan;
      } else {
        this.editingResult.dan ="";
      }
    },
    validateEditingRound(){
      var editingRound = Number(this.editingResult.round.replace(/\D/g, ''));
      if (editingRound == 0){
        this.editingResult.round = ''
      } else {
        this.editingResult.round = editingRound
      }
    },
    validateEditingCardnumber(){
      var editingCardnumber = Number(this.editingResult.cardnumber.replace(/\D/g, ''));
      if (editingCardnumber == 0){
        this.editingResult.cardnumber = ''
      } else {
        if(editingCardnumber > 48) {
          this.editingResult.cardnumber = 48
        } else {
          this.editingResult.cardnumber = editingCardnumber
        }
      }
    },
    validateEditingOtetsukiNumber(){
      if (this.editingResult.otetsukiNumber!=''){
        var editingOtetsukiNumber = Number(this.editingResult.otetsukiNumber.replace(/\D/g, ''));
        this.editingResult.otetsukiNumber = editingOtetsukiNumber
      }
    },
    // clubSuggestionList() {
    //   var clubList = new Array()
    //   for (var k=0;k<Math.min(1,this.rows);k++){
    //     if(this.recordsForTable[k].value.club != ''){
    //       clubList.push(this.recordsForTable[k].value.club)
    //     }
    //   }
    //   var clubSet = new Set(clubList)
    //   clubList = Array.from(clubSet)
    //   clubList = clubList.sort((x,y)=>x.localeCompare(y,'ja'))
    //   return clubList
    // },
    compareToStartDate(defaultDate,startDate){
      var dateForComparison = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate())
      var dateForComparisonInTimeStamp = dateForComparison.getTime()
      return (defaultDate>=dateForComparisonInTimeStamp);
    },
    compareToEndDate(defaultDate,endDate){
      var dateForComparison = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate()+1)
      var dateForComparisonInTimeStamp = dateForComparison.getTime()
      return (defaultDate<dateForComparisonInTimeStamp);
    },
    compareRank(rank,paramRank){
      if(paramRank.length==0){
        return false;
      } else {
        return paramRank.includes(rank);
      }
    },
    compareDan(dan,paramdan){
      if(paramdan.length==0){
        return false;
      } else {
        if(typeof(dan)==="undefined"){
          if(paramdan.includes("")){
            return true
          } else {
            return false
          }
        } else {
          if(paramdan.includes(dan)){
            return true;
          } else {
            return false;
          }
        }
      }
    },
    compareResult(result,paramResult){
      if(paramResult.length==0){
        return false;
      } else {
        return paramResult.includes(result);
      }
    },
    compareType(type,paramtype){
      if(paramtype==''){
        return true;
      } else {
        if(typeof(type)==="undefined"){
          return false
        } else {
          if(type.indexOf(paramtype) > -1){
            return true;
          } else {
            return false;
          }
        }
      }
    },
    compareHand(hand,paramhand){
      if(paramhand.length==0){
        return false;
      } else {
        if(typeof(hand)==="undefined"){
          if(paramhand.includes("")){
            return true
          } else {
            return false
          }
        } else {
          if(paramhand.includes(hand)){
            return true;
          } else {
            return false;
          }
        }
      }
    },
    compareName(name,paramName){
      if(paramName==''){
        return true;
      } else {
        if(this.params.opponentPerfectMatchingFlag){
          if(name == paramName){
            return true;
          } else {
            return false;
          }
        } else {
          if(name.indexOf(paramName) > -1){
            return true;
          } else {
            return false;
          }
        }
      }
    },
    compareClub(club,paramClub){
      if(paramClub==''){
        return true;
      } else {
        if(this.params.clubPerfectMatchingFlag){
          if(club == paramClub){
            return true;
          } else {
            return false;
          }
        } else {
          if(club.indexOf(paramClub) > -1){
            return true;
          } else {
            return false;
          }
        }
      }
    },
    compareCardNumberStart(cardnumber,paramCardNumberStart){
      if (paramCardNumberStart==''){
        return true;
      } else {
        return (cardnumber>=paramCardNumberStart)
      }
    },
    compareCardNumberEnd(cardnumber,paramCardNumberEnd){
      if (paramCardNumberEnd==''){
        return true;
      } else {
        return (cardnumber<=paramCardNumberEnd)
      }
    },
    compareOtetsukiNumberStart(otetsukiNumber,paramOtetsukiNumberStart){
      if (paramOtetsukiNumberStart===""){
        return true;
      } else {
        if (otetsukiNumber===''){
          return false;
        } else {
          return (otetsukiNumber>=paramOtetsukiNumberStart)
        }
      }
    },
    compareOtetsukiNumberEnd(otetsukiNumber,paramOtetsukiNumberEnd){
      if (paramOtetsukiNumberEnd===''){
        return true;
      } else {
        if (otetsukiNumber===''){
          return false;
        } else {
          return (Number(otetsukiNumber)<=paramOtetsukiNumberEnd)
        }
      }
    },
    compareComment(comment,paramComment){
      if(paramComment==''){
        return true;
      } else {
        if(comment.indexOf(paramComment) > -1){
          return true;
        } else {
          return false;
        }
      }
    },
    setStartDate(){
      var date = dayjs().add(-12,'months').toDate()
      return date
    },
    hideGraph(){
      this.showGraph = false
    },
    changeVisibility(column){
      var key = 0
      for (var k=0;k<this.fields.length;k++){
        if(this.fields[k].key.indexOf(column)!=-1){
          key = k
          break
        }
      }
      if(this.visibility[column]){
        if(['round','hand','cardnumber','otetsukiNumber'].includes(column)){
          this.fields[key].tdClass = "records-1em"
        } else {
          if (column=='comment'){
            this.fields[key].tdClass = "records-10em"
          } else {
            this.fields[key].tdClass = "records-3em"
          }
        }
        this.fields[key].thClass = ""
      } else {
        this.fields[key].tdClass = "d-none"
        this.fields[key].thClass = "d-none"
      }
      localStorage.setItem('statistics-records-visibility', JSON.stringify(this.visibility))
    },
    showRanking(rankingSelected){
      if(rankingSelected==null){
        return null
      }
      if(rankingSelected=='byIndividual'){
        var opponentArray = new Array()
        this.filteredRecords.forEach(function(item){
          var Arr = {
            opponent:item.value.opponent,
            result:item.value.result,
          }
          opponentArray.push(Arr)
        })
        var group = opponentArray.reduce(function (result, current) {
          var element = result.find(function (p) {
            return p.opponent === current.opponent
          });
          if (element) {
            element.count ++;
            // count
            if(current.result=='勝ち'){
              element.win = element.win+1;
            }
            if(current.result=='負け'){
              element.lose = element.lose+1;
            }
            if(element.win+element.lose >0){
              element.winper = Math.floor((element.win)/(element.win+element.lose)*100)
            }
          } else {
            var win = 0
            var lose = 0
            var winper = null
            if(current.result == '勝ち'){
              win = 1;
              winper = 100;
            }
            if(current.result=='負け'){
              lose = 1;
              winper = 0
            }
            result.push({
              opponent: current.opponent,
              count: 1,
              win: win,
              lose: lose,
              winper : winper
            });
          }
          return result;
        }, []);
        this.rankingFields[1].tdClass = ""
        this.rankingFields[1].thClass = ""
        this.rankingFields[2].tdClass = "d-none"
        this.rankingFields[2].thClass = "d-none"
      }
      if(rankingSelected=='byClub'){
        var clubArray = new Array()
        this.filteredRecords.forEach(function(item){
          var Arr = {
            club:item.value.club,
            result:item.value.result,
          }
          clubArray.push(Arr)
        })
        group = clubArray.reduce(function (result, current) {
          var element = result.find(function (p) {
            return p.club === current.club
          });
          if (element) {
            element.count ++;
            // count
            if(current.result=='勝ち'){
              element.win = element.win+1;
            }
            if(current.result=='負け'){
              element.lose = element.lose+1;
            }
            if(element.win+element.lose >0){
              element.winper = Math.floor((element.win)/(element.win+element.lose)*100)
            }
          } else {
            var win = 0
            var lose = 0
            var winper = null
            if(current.result == '勝ち'){
              win = 1;
              winper = 100;
            }
            if(current.result=='負け'){
              lose = 1;
              winper = 0
            }
            result.push({
              club: current.club,
              count: 1,
              win: win,
              lose: lose,
              winper : winper
            });
          }
          return result;
        }, []);
        this.rankingFields[1].tdClass = "d-none"
        this.rankingFields[1].thClass = "d-none"
        this.rankingFields[2].tdClass = ""
        this.rankingFields[2].thClass = ""
      }
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const genreA = a.count;
        const genreB = b.count;

        let comparison = 0;
        if (genreA > genreB) {
          comparison = 1;
        } else if (genreA < genreB) {
          comparison = -1;
        }
        return comparison*-1;
      }
      var lowerLimit = this.params.lowerLimit
      var filtered = group.filter(function(element) {
        var haveName = false
        if(rankingSelected=='byClub'){
          if(element.club != ''){
            haveName = true
          }
        }
        if(rankingSelected=='byIndividual'){
          if(element.opponent != ''){
            haveName = true
          }
        }
        return (element.count >= lowerLimit && haveName);
      });
      filtered.sort(compare)
      this.rankingTotalRows = filtered.length
      return filtered;
    },
    readVisibilityFromStorage(){
      this.visibility = JSON.parse(localStorage.getItem('statistics-records-visibility'));
      if (!this.visibility) {
        this.visibility = {
          Date:true,
          type:true,
          round:true,
          opponent:true,
          club:true,
          rank:true,
          result:true,
          cardnumber:true,
          otetsukiNumber:true,
          comment:true,
          player:true,
          hand:true,
          place:true,
          reader:true,
          dan:true,
        }
      }
    },
    changeEditingResultVisibility(){
      localStorage.setItem('success-editingResult-visibility', JSON.stringify(this.editingResultVisibility))
    },
    readEditingResultVisibilityFromStorage(){
      this.editingResultVisibility = JSON.parse(localStorage.getItem('success-editingResult-visibility'));
      if (!this.editingResultVisibility) {
        this.editingResultVisibility = {
          Date:true,
          type:true,
          round:true,
          opponent:true,
          club:true,
          rank:true,
          result:true,
          cardnumber:true,
          otetsukiNumber:true,
          comment:true,
          player:false,
          hand:false,
          place:false,
          reader:false,
          dan:false,
        }
      }
    },
    downloadCSV(){
      var csv = '\ufeff' + 'ユーザー名,日付,形式,回戦,相手,所属,級,段位,有効手,勝敗,枚数,お手,会場,読手,コメント\n'
      this.recordsForTable.forEach(el => {
        var date = new Date(el.value['defaultDate'])
        var formatedDateTime = dayjs(date).format("YYYY/MM/DD")
        var line = this.user.displayName+','
        + formatedDateTime + ','
        + el.value['type'] + ','
        + el.value['round'] + ','
        + el.value['opponent'] + ','
        + el.value['club'] + ','
        + el.value['rank'] + ','
        if(typeof el.value['dan']==="undefined"){
          line = line + ','
        } else {
          line = line + el.value['dan']+ ','
        }
        line= line + el.value['hand'] + ','
        + el.value['result'] + ','
        + el.value['cardnumber'] + ','
        + el.value['otetsukiNumber'] + ','
        + el.value['place'] + ','
        + el.value['reader'] + ','
        + el.value['comment'] + '\n'
        csv += line
      })
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      var now = dayjs().format('YYYYMMDDHHmmss')
      link.download = '統計ページ集計結果_'+now+'.csv'
      link.click()
    },
    suggestClubAndRankForEditingResult(){
      console.log("aaa")
      if (this.opponentSuggestionSet.has(this.editingResult.opponent)) {
        console.log("Yes")
        for (var k = 0; k < this.rows; k++) {
          console.log(k)
          if (this.recordsForTable[k].value.opponent != "" && this.recordsForTable[k].value.opponent == this.editingResult.opponent) {
            this.editingResult.club = this.recordsForTable[k].value.club
            this.editingResult.rank = this.recordsForTable[k].value.rank
            if (!(typeof this.recordsForTable[k].value.hand === "undefined")) {
              this.editingResult.hand = this.recordsForTable[k].value.hand
            } else {
              this.editingResult.hand = ""
            }
            if (!(typeof this.recordsForTable[k].value.dan === "undefined")) {
              this.editingResult.dan = this.recordsForTable[k].value.dan
            } else {
              this.editingResult.dan = ""
            }
            break;
          }
        }
      }
    },
    // opponentSuggestionList() {
    //   var opponentList = new Array()
    //   for (var k=0;k<Math.min(1,this.recordsForTable.length);k++){
    //     if(this.recordsForTable[k].value.opponent != ''){
    //       opponentList.push(this.recordsForTable[k].value.opponent)
    //     }
    //   }
    //   var opponentSet = new Set(opponentList)
    //   opponentList = Array.from(opponentSet)
    //   opponentList = opponentList.sort((x,y)=>x.localeCompare(y,'ja'))
    //   return opponentList
    // },
    clearEditingItem(item){
      this.editingResult[item]=''
    },
    changeTableType(){
      localStorage.setItem('statistics-Table-Type', JSON.stringify(this.tableType))
    },
    readTableTypeFromStorage(){
      this.tableType = JSON.parse(localStorage.getItem('statistics-Table-Type'));
      if (!this.tableType) {
        if(screen.width<=768){
          this.tableType = 'block'
        } else {
          this.tableType = 'table'
        }
      }
    },
    toKanji(befText){
      if(typeof befText==="undefined" || befText == ''){
        return ""
      }
      var str=""
      switch (befText) {
        case 0:
        str = "無"
        break;
        case 1:
        str = "初"
        break;
        case 2:
        str = "二"
        break;
        case 3:
        str = "三"
        break;
        case 4:
        str = "四"
        break;
        case 5:
        str = "五"
        break;
        case 6:
        str = "六"
        break;
        case 7:
        str = "七"
        break;
        case 8:
        str = "八"
        break;
        case 9:
        str = "九"
        break;
        case 10:
        str = "十"
        break;
        default:
      }
      return str+"段";
    },
    clearItem(item){
      this.params[item]=[]
    },
  },
  components : {
    Header,
    Datepicker,
    Chart,
  },
  data () {
    return {
      user: firebase.auth().currentUser,
      showGraph: false,
      chartType: 'BarChart',
      chartData: [
        ['級', '勝率'],
      ],
      chartOptions: {
        title: '級別勝率(%)',
        subtitle: '',
        hAxis:{
          maxValue: 100,
        },
        legend: { position: 'none' },
      },
      chartType2: 'PieChart',
      chartData2: [
        ['級', '試合'],
        ['A級',0],
        ['B級',0],
        ['C級',0],
        ['D級',0],
        ['E級',0],
        ['F級',0],
      ],
      chartOptions2: {
        title: '全体 級別割合',
        subtitle: '',
      },
      chartType3: 'PieChart',
      chartData3: [
        ['級', '試合'],
        ['A級',0],
        ['B級',0],
        ['C級',0],
        ['D級',0],
        ['E級',0],
        ['F級',0],
      ],
      chartOptions3: {
        title: '勝った試合 級別割合',
        subtitle: '',
      },
      chartType4: 'PieChart',
      chartData4: [
        ['級', '試合'],
        ['A級',0],
        ['B級',0],
        ['C級',0],
        ['D級',0],
        ['E級',0],
        ['F級',0],
      ],
      chartOptions4: {
        title: '負けた試合 級別割合',
        subtitle: '',
      },
      errors: [],
      ja:ja,
      DatePickerFormat: 'yyyy/MM/dd',
      uid: firebase.auth().currentUser.uid,
      today: new Date(),
      params:{
        start:this.setStartDate(),
        end:new Date(),
        type:'',
        rank:["A級","B級","C級","D級","E級","F級",""],
        dan:[0,1,2,3,4,5,6,7,8,9,10,""],
        opponent:'',
        opponentPerfectMatchingFlag:false,
        club:'',
        clubPerfectMatchingFlag:false,
        result:["勝ち","負け","その他",""],
        hand:["右","左",""],
        cardNumberStart:'',
        cardNumberEnd:'',
        otetsukiNumberStart:'',
        otetsukiNumberEnd:'',
        lowerLimit:1,
        comment:'',
      },
      visibility: {
        Date:true,
        type:true,
        round:true,
        opponent:true,
        club:true,
        rank:true,
        result:true,
        cardnumber:true,
        otetsukiNumber:true,
        comment:true,
        player:true,
        hand:true,
        place:true,
        reader:true,
        dan:true,
      },
      editingResultVisibility: {
        Date:true,
        type:true,
        round:true,
        opponent:true,
        club:true,
        rank:true,
        result:true,
        cardnumber:true,
        otetsukiNumber:true,
        comment:true,
        player:false,
        hand:false,
        place:false,
        reader:false,
        dan:false,
      },
      recordsForTable:[],
      currentPage: 1,
      fieldsForMobile:[
        {
          key: 'record',
          label :'記録',
        }
      ],
      fields: [
        {
          key: 'Date',
          label: '日付',
        },
        {
          key: 'value.type',
          label: '形式',
          tdClass: 'records-3em',
        },
        {
          key: 'value.round',
          label: '回戦',
          tdClass: 'records-1em',
        },
        {
          key: 'value.player',
          label: 'プレイヤー',
          tdClass: 'records-3em',
        },
        {
          key: 'value.opponent',
          label: '相手',
          tdClass: 'records-3em',
        },
        {
          key: 'value.club',
          label: '所属',
          tdClass: 'records-3em',
        },
        {
          key: 'value.rank',
          label: '級',
          tdClass: 'records-3em',
        },
        {
          key: 'value.dan',
          label: '段位',
          tdClass: 'records-3em',
        },
        {
          key: 'value.hand',
          label: '有効手',
          tdClass: 'records-1em',
        },
        {
          key: 'value.result',
          label: '勝敗',
          tdClass: 'records-3em',
        },
        {
          key: 'value.cardnumber',
          label: '枚数',
          tdClass: 'records-1em'
        },
        {
          key: 'value.otetsukiNumber',
          label: 'お手',
          tdClass: 'records-1em'
        },
        {
          key: 'value.place',
          label: '会場',
          tdClass: 'records-3em',
        },
        {
          key: 'value.reader',
          label: '読手',
          tdClass: 'records-3em',
        },
        {
          key: 'value.comment',
          label: 'コメント',
          tdClass: 'records-10em',
        },
      ],

      editingResult:{
        defaultDate:'',
        round:'',
        opponent:'',
        club:'',
        rank:'',
        dan:'',
        result:'',
        cardnumber:'',
        otetsukiNumber:'',
        comment:'',
        player:'',
        hand:'',
        place:'',
        reader:'',
        type:'',
      },
      focusKey:'',
      records:[],
      rankingCurrentPage:1,
      rankingTotalRows:0,
      rankingSelected:'byIndividual',
      rankingOptions:[
        {value:'byIndividual', text: '個人別' },
        {value:'byClub', text: 'かるた会別' },
      ],
      rankingFields:[
        {
          key : 'index',
          label : '順位'
        },
        {
          key : 'opponent',
          label : '相手'
        },
        {
          key : 'club',
          label : 'かるた会'
        },
        {
          key : 'count',
          label : '総試合数',
          sortable: true,
        },
        {
          key : 'win',
          label : '勝ち',
          sortable: true,
        },
        {
          key : 'lose',
          label : '負け',
          sortable: true,
        },
        {
          key : 'winper',
          label : '勝率',
          sortable: true
        },
      ],
      opponentList:{

      },
      tableType:'',
      rankOptions:[
        {value:'',text:''},
        {value:'A級',text:'A級'},
        {value:'B級',text:'B級'},
        {value:'C級',text:'C級'},
        {value:'D級',text:'D級'},
        {value:'E級',text:'E級'},
        {value:'F級',text:'F級'},
      ],
      danOptions:[
        {value:'',text:''},
        {value:0,text:'無段'},
        {value:1,text:'初段'},
        {value:2,text:'二段'},
        {value:3,text:'三段'},
        {value:4,text:'四段'},
        {value:5,text:'五段'},
        {value:6,text:'六段'},
        {value:7,text:'七段'},
        {value:8,text:'八段'},
        {value:9,text:'九段'},
        {value:10,text:'十段'},
      ],
      handOptions:[
        {value:'',text:''},
        {value:'右',text:'右'},
        {value:'左',text:'左'},
      ],
      resultOptions:[
        {value:'',text:''},
        {value:'勝ち',text:'勝ち'},
        {value:'負け',text:'負け'},
        {value:'その他',text:'その他'},
      ],
      rankOptionsForParams:[
        {value:'A級',text:'A級'},
        {value:'B級',text:'B級'},
        {value:'C級',text:'C級'},
        {value:'D級',text:'D級'},
        {value:'E級',text:'E級'},
        {value:'F級',text:'F級'},
        {value:'',text:'未入力'},
      ],
      danOptionsForParams:[
        {value:0,text:'無段'},
        {value:1,text:'初段'},
        {value:2,text:'二段'},
        {value:3,text:'三段'},
        {value:4,text:'四段'},
        {value:5,text:'五段'},
        {value:6,text:'六段'},
        {value:7,text:'七段'},
        {value:8,text:'八段'},
        {value:9,text:'九段'},
        {value:10,text:'十段'},
        {value:'',text:'未入力'},
      ],
      handOptionsForParams:[
        {value:'右',text:'右'},
        {value:'左',text:'左'},
        {value:'',text:'未入力'},
      ],
      resultOptionsForParams:[
        {value:'勝ち',text:'勝ち'},
        {value:'負け',text:'負け'},
        {value:'その他',text:'その他'},
        {value:'',text:'未入力'},
      ],
      opponentSuggestionList:[],
      clubSuggestionList:[],
      opponentAndClubMap: new Map(),
      opponentSuggestionSet: new Set(),
      clubSuggestionSet: new Set(),
    }
  },
  filters: {
    dayjs: function (date) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    dayjs2: function (date) {
      return dayjs(date).format('MM/DD');
    },
  },
  mounted: function(){
    this.readVisibilityFromStorage();
    this.readEditingResultVisibilityFromStorage();
    this.readTableTypeFromStorage();
  },
  computed: {
    filteredRecords() {
      firebase.analytics().logEvent('record_filtered',{username:this.user.displayName})
      var recordsForTable = new Array();
      recordsForTable = this.recordsForTable.filter(item =>this.compareToStartDate(item.value.defaultDate,this.params.start)
      &&this.compareToEndDate(item.value.defaultDate,this.params.end)
      &&this.compareType(item.value.type,this.params.type)
      &&this.compareHand(item.value.hand,this.params.hand)
      &&this.compareRank(item.value.rank,this.params.rank)
      &&this.compareDan(item.value.dan,this.params.dan)
      &&this.compareName(item.value.opponent,this.params.opponent)
      &&this.compareClub(item.value.club,this.params.club)
      &&this.compareResult(item.value.result,this.params.result)
      &&this.compareCardNumberStart(item.value.cardnumber,this.params.cardNumberStart)
      &&this.compareCardNumberEnd(item.value.cardnumber,this.params.cardNumberEnd)
      &&this.compareOtetsukiNumberStart(item.value.otetsukiNumber,this.params.otetsukiNumberStart)
      &&this.compareOtetsukiNumberEnd(item.value.otetsukiNumber,this.params.otetsukiNumberEnd)
      &&this.compareComment(item.value.comment,this.params.comment))
      return recordsForTable
    },
    rows() {
      return this.recordsForTable.length
    },
  },
}
</script>
