<template>
  <div class="container">
    <div class="border">
      <Header />
      <div class="sticky-top" style="background-color: white; z-index:49;">
        <b-table id="record-table" bordered :items="this.resultForTable" :fields="fields">
          <template v-slot:cell(date)="data">
            {{data.item.defaultDate | dayjs2}}
          </template>
        </b-table>
        <!-- <hr> -->
      </div>
      <br>
      <b-table ref="recordtable" id="record-table" hover :items="this.detailRecord" :fields="recordFields" :per-page=100 :current-page="recordCurrentPage">
        <template v-slot:cell(content)="data">
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">{{ data.index + 1 + 10*(recordCurrentPage-1) }}枚目</div>
            <div v-if="data.item.defuda==true" class="col-8 text-left">{{ data.item.card }} ({{data.item.kimariji}})</div>
            <div v-else class="col-8 text-left">{{ data.item.card }} (空札)</div>
          </div>
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">結果</div>
            <div v-if="data.item.defuda==true" class="col-8 text-left">{{ data.item.detailRecord }}</div>
            <div v-else class="col-8 text-left">-</div>
          </div>
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">場所</div>
            <div v-if="data.item.defuda==true" class="col-8 text-left">{{ data.item.place1+data.item.place2 }}</div>
            <div v-else class="col-8 text-left">-</div>
          </div>
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">状況</div>
            <div v-if="data.item.defuda==true" class="col-8 text-left">{{ data.item.situation }}</div>
            <div v-else class="col-8 text-left">-</div>
          </div>
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">評価</div>
            <div v-if="data.item.defuda==true" class="col-8 text-left">{{ data.item.evaluation }}</div>
            <div v-else class="col-8 text-left">-</div>
          </div>
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">お手つき</div>
            <div class="col-8 text-left">
              <div v-if="data.item.myOtetsuki==0&&data.item.opponentOtetsuki==0">自分：- 相手：-</div>
              <div v-if="data.item.myOtetsuki==0&&data.item.opponentOtetsuki==1">自分：- 相手：○</div>
              <div v-if="data.item.myOtetsuki==0&&data.item.opponentOtetsuki==2">自分：- 相手：◎</div>
              <div v-if="data.item.myOtetsuki==1&&data.item.opponentOtetsuki==0">自分：○ 相手：-</div>
              <div v-if="data.item.myOtetsuki==1&&data.item.opponentOtetsuki==1">自分：○ 相手：○</div>
              <div v-if="data.item.myOtetsuki==1&&data.item.opponentOtetsuki==2">自分：○ 相手：◎</div>
              <div v-if="data.item.myOtetsuki==2&&data.item.opponentOtetsuki==0">自分：◎ 相手：-</div>
              <div v-if="data.item.myOtetsuki==2&&data.item.opponentOtetsuki==1">自分：◎ 相手：○</div>
              <div v-if="data.item.myOtetsuki==2&&data.item.opponentOtetsuki==2">自分：◎ 相手：◎</div>
            </div>
          </div>
          <div class="row" style="margin-right:0px;margin-left:0px">
            <div class="col-4">コメント</div>
            <div class="col-8 text-left">{{ data.item.comment | truncate}}</div>
            <!-- <div class="col-8 text-left">{{ data.item.comment }}</div> -->
          </div>
          <!-- <div class="row" style="margin-right:0px;margin-left:0px">
          <div class="col-12"><b-button size="sm" class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingOldGoal(data.item.value);$bvModal.show('editOldGoal')">更新</b-button></div>
        </div> -->
        <b-button size="sm" variant="success" class="mx-1 my-auto" @click="setIndex(data.index);setEditParam();$bvModal.show('edit')"><font-awesome-icon :icon="['far','edit']"/></b-button>
      </template>
      <!-- <template v-slot:cell(button)="data">
      <b-button size="sm" variant="success" class="mx-1 my-auto" @click="setIndex(data.index);setEditParam();$bvModal.show('edit')"><font-awesome-icon :icon="['far','edit']"/></b-button>
    </template>
    <template v-slot:cell(index)="data">
    {{ data.index + 1 + 10*(recordCurrentPage-1) }}
    <p class="my-auto" @click="setIndex(data.index);setEditParam();$bvModal.show('edit')"><b>{{ data.index + 1 + 10*(recordCurrentPage-1) }}</b></p>
  </template>
  <template v-slot:cell(card)="data">
  {{ data.item.card }}
</template>
<template v-slot:cell(kimariji)="data">
<div v-if="data.item.defuda">{{ data.item.kimariji }}</div>
<div v-else>-</div>
</template>
<template v-slot:cell(place)="data">
<div v-if="data.item.defuda">{{ data.item.place1+data.item.place2 }}</div>
<div v-else>-</div>
</template>
<template v-slot:cell(situation)="data">
<div v-if="data.item.defuda">{{ data.item.situation }}</div>
<div v-else>-</div>
</template>
<template v-slot:cell(detailRecord)="data">
<div v-if="data.item.defuda">{{ data.item.detailRecord }}</div>
<div v-else>-</div>
</template>
<template v-slot:cell(evaluation)="data">
<div v-if="data.item.defuda">{{ data.item.evaluation }}</div>
<div v-else>-</div>
</template> -->
</b-table>
<b-button size="sm" class="mx-1" variant="success" @click="setInsertNumber();$bvModal.show('input')">入力</b-button>
<b-modal v-bind:id="'input'" hide-footer hide-header>
  <div class="text-center">
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">何枚目？</label>
      <b-form-input class="col-8" type=number v-model="insertNumber" :max="this.detailRecord.length+1" :min=1>
      </b-form-input>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">出札？</label>
      <b-form-select class="col-8" v-model="param.defuda" :options="defudaOptions" @change="removeParamValue">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">読札</label>
      <b-form-select class="col-8" v-model="param.cardGroup" :options="hyakuninnisshuGroup">
      </b-form-select>
      <label class="col-4 my-auto" for="inline-form-custom-select-pref"></label>
      <b-form-select class="col-8" v-model="param.card" :options="getUnusedCards(param.cardGroup)" @change="suggestKimariji">
      </b-form-select>
    </div>

    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">決まり字</label>
      <b-form-select :disabled="!param.defuda" class="col-4" v-model="param.kimariji" :options="kimarijiOptions">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">結果</label>
      <b-form-select :disabled="!param.defuda" class="col-8" v-model="param.detailRecord" :options="detailRecordOptions">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">場所</label>
      <b-form-select :disabled="!param.defuda" class="col-4" v-model="param.place1" :options="place1Options">
      </b-form-select>
      <b-form-select :disabled="!param.defuda" class="col-4" v-model="param.place2" :options="place2Options">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">状況</label>
      <b-form-select :disabled="!param.defuda" class="col-8" v-model="param.situation" :options="situationOptions">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">評価</label>
      <b-form-select :disabled="!param.defuda" class="col-8" v-model="param.evaluation" :options="evaluationOptions">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref" id="tooltip-otetsuki">お手つき<font-awesome-icon :icon="['fas','question-circle']"/></label>
      <b-tooltip target="tooltip-otetsuki" triggers="hover">
        空ダブは◎
      </b-tooltip>
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">自分</label>
      <b-form-select class="col-4" v-model="param.myOtetsuki" :options="otetsukiOptions">
      </b-form-select>
      <label class="col-4 my-auto" for="inline-form-custom-select-pref"></label>
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">相手</label>
      <b-form-select class="col-4" v-model="param.opponentOtetsuki" :options="otetsukiOptions">
      </b-form-select>
    </div>
    <div class="row">
      <label class="col-4 my-auto" for="inline-form-custom-select-pref">コメント</label>
      <b-form-textarea class="col-8 form-control" v-model="param.comment" rows="1" max-rows="5"></b-form-textarea>
    </div>
    <b-button class="mx-1" variant="secondary" @click="resetParam();$bvModal.hide('input')">やめる</b-button>
    <b-button class="mx-1" variant="success" @click="$bvModal.hide('input');onsubmit()">記録</b-button>
  </div>
</b-modal>
<b-modal v-bind:id="'edit'" hide-footer hide-header>
  <div class="text-center">
    <div class="row">
      <div class="col-12">
        <b-button size="sm" class="m-1 float-left" variant="danger" id="show-btn" @click="$bvModal.show('remove')"><font-awesome-icon :icon="['far','trash-alt']"/></b-button>
      </div>
    </div>
    <div class="form-group border-success" id="record" style="margin-top:1rem">
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">出札？</label>
        <b-form-select class="col-8" v-model="editParam.defuda" :options="defudaOptions" @change="removeEditParamValue">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">読札</label>
        <b-form-select class="col-8" v-model="editParam.cardGroup" :options="hyakuninnisshuGroup">
        </b-form-select>
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">読札</label>
        <b-form-select class="col-8" v-model="editParam.card" :options="hyakuninnisshu[editParam.cardGroup]">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">決まり字</label>
        <b-form-select :disabled="!editParam.defuda" class="col-4" v-model="editParam.kimariji" :options="kimarijiOptions">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">場所</label>
        <b-form-select :disabled="!editParam.defuda" class="col-4" v-model="editParam.place1" :options="place1Options">
        </b-form-select>
        <b-form-select :disabled="!editParam.defuda" class="col-4" v-model="editParam.place2" :options="place2Options">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">状況</label>
        <b-form-select :disabled="!editParam.defuda" class="col-8" v-model="editParam.situation" :options="situationOptions">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">取った</label>
        <b-form-select :disabled="!editParam.defuda" class="col-8" v-model="editParam.detailRecord" :options="detailRecordOptions">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">評価</label>
        <b-form-select :disabled="!editParam.defuda" class="col-8" v-model="editParam.evaluation" :options="evaluationOptions">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref" id="tooltip-otetsuki">お手つき<font-awesome-icon :icon="['fas','question-circle']"/></label>
        <b-tooltip target="tooltip-otetsuki" triggers="hover">
          空ダブは◎
        </b-tooltip>
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">自分</label>
        <b-form-select class="col-4" v-model="editParam.myOtetsuki" :options="otetsukiOptions">
        </b-form-select>
        <label class="col-4 my-auto" for="inline-form-custom-select-pref"></label>
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">相手</label>
        <b-form-select class="col-4" v-model="editParam.opponentOtetsuki" :options="otetsukiOptions">
        </b-form-select>
      </div>
      <div class="row">
        <label class="col-4 my-auto" for="inline-form-custom-select-pref">コメント</label>
        <b-form-textarea class="col-8 form-control" v-model="editParam.comment" rows="1" max-rows="5"></b-form-textarea>
      </div>
      <b-button class="mx-1" variant="secondary" @click="$bvModal.hide('edit')">やめる</b-button>
      <b-button class="mx-1" variant="success" @click="$bvModal.hide('edit');edit()">更新</b-button>
    </div>
  </div>
</b-modal>
<b-modal v-bind:id="'remove'" hide-footer hide-header>
  <div class="text-center">
    <p>削除します。</p>
    <b-button size="sm" class="mx-1" variant="secondary" @click="$bvModal.hide('remove')">閉じる</b-button>
    <b-button size="sm" class="mx-1" variant="danger" @click="removeRecord();$bvModal.hide('remove');$bvModal.hide('edit')">削除</b-button>
  </div>
</b-modal>

</div>
</div>
</template>

<style>
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .container {
    margin-bottom: 64px;
  }
}
.mt-4 {
  margin-top: 4px;
}
.row {
  margin-bottom: 4px;
  margin-left: 0px;
  margin-right: 0px;
}
.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}
.vdp-datepicker__calendar {
  width: auto !important;
}
/*PCの場合*/
.pc	{ display:inline!important; }
.mb	{ display:none!important; }
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .pc	{ display:none!important; }
  .mb { display:inline!important; }
}
.mx-1 {
  margin-bottom: 0.75em;
}
.table th{
  padding: 0.25em;
}
.table td{
  padding: 0.25em;
  vertical-align: middle;
}
.table td.records-3em{
  min-width: 3em;
}
.table td.records-1em{
  min-width: 1em;
}
.table td.records-10em{
  min-width: 10em;
}
.modal-dialog {
  max-width: 95%;
  margin: 1.75rem auto;
}
.tooltip-inner {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap:break-word;
}
.searchclear {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ccc;
  vertical-align: middle;
}
</style>

<script>
import Header from '@/components/Header.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore';
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

export default {
  name: 'detailRecord',
  components:{
    Header,
  },
  created: function() {

    if(typeof this.$route.query.key==="undefined") {
      this.$router.push('success')
    } else {
      this.key = this.$route.query.key
      this.database = firebase.database();
      this.uid = firebase.auth().currentUser.uid;

      // recordsからロード
      this.database.ref('records/'+this.uid+'/'+this.key).on('value', snapshot => {
        snapshot.forEach((data) => {
          this.result[data.key] = data.val();
        })
      })
      this.resultForTable.push(this.result)

      // detailRecordsに記録がない場合、作成する
      firebase.database().ref('detailRecords/'+this.uid+'/'+this.focusKey).once("value",snapshot => {
        if(!snapshot.exists()){
          firebase.database().ref('detailRecords/'+this.uid).child(this.key).child('result').update(this.result);
        }
      });

      // detailRecordsからロード
      this.list = this.database.ref('detailRecords/'+this.uid+'/'+this.key+'/detailRecord');
      var _this = this;
      this.list.on('value',function(snapshot){
        let obj = {};
        snapshot.forEach(child => {
          obj[child.key]=child.val();
        });
        _this.detailRecord = [];
        for(let k in obj){
          _this.detailRecord.push(obj[k]);
        }
      });
    }
  },
  methods: {
    setInsertNumber(){
      this.insertNumber = this.detailRecord.length + 1;
    },
    onsubmit(){
      this.detailRecord.splice(this.insertNumber-1,0,this.param)
      const obj = {}
      this.detailRecord.forEach((data, i) => {
        obj[i+1] = data;
      });
      firebase.database().ref('detailRecords/'+this.uid).child(this.key).child('detailRecord').update(obj);
      this.param = {
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:0,
        opponentOtetsuki:0,
        comment:'',
      },
      this.insertNumber = this.detailRecord.length+1;
      this.scrollToEnd();
    },
    edit(){
      this.detailRecord[this.index]=this.editParam;
      // localStorage.setItem('detailRecord', JSON.stringify(this.detailRecord))
      this.editParam = {
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:0,
        opponentOtetsuki:0,
        comment:'',
      }
      const obj = {}
      this.detailRecord.forEach((data, i) => {
        obj[i+1] = data;
      });
      firebase.database().ref('detailRecords/'+this.uid).child(this.key).child('detailRecord').update(obj);
      this.index='';
    },
    removeRecord(){
      this.detailRecord.splice(this.index,1)
      // localStorage.setItem('detailRecord', JSON.stringify(this.detailRecord))
      const obj = {}
      this.detailRecord.forEach((data, i) => {
        obj[i+1] = data;
      });
      firebase.database().ref('detailRecords/'+this.uid).child(this.key).child('detailRecord').set(obj);
      this.index = ''
      this.insertNumber = this.detailRecord.length+1
    },
    scrollToEnd: function() {
      // this.$nextTick(() => {
      //   const el = this.$el.querySelector("#record-table");
      //   if(el){
      //     el.scrollTop = el.scrollHeight
      //   }
      // })
      var element = document.documentElement;
      var bottom = element.scrollHeight - element.clientHeight;
      window.scroll(0, bottom+500);
    },
    getUnusedCards(cardGroup){
      if(cardGroup == '') {
        return []
      }
      if(this.detailRecord.length==0){
        return this.hyakuninnisshu[cardGroup]
      }
      var usedCards = []
      for (const item of this.detailRecord){
        if(item.card !== ''){
          usedCards.push(item.card)
        }
      }
      var unUsedCards = []
      if(cardGroup !== ''){
        for (const item of this.hyakuninnisshu[cardGroup]) {

          if(usedCards.indexOf(item)===-1){
            unUsedCards.push(item)
          }
        }
      }
      return unUsedCards
    },
    setIndex(index){
      this.index = index
    },
    setEditParam(){
      // this.editParam = this.detailRecord[this.index];
      this.editParam = JSON.parse(JSON.stringify(this.detailRecord[this.index]));
    },
    onRowSelected(items) {
      this.selected = items
    },
    readDataFromStorage(){
      this.detailRecord = JSON.parse(localStorage.getItem('detailRecord'));
      if (!this.detailRecord) {
        this.detailRecord = [];
        this.insertNumber=1
      } else {
        this.insertNumber = this.detailRecord.length+1
      }
    },
    removeParamValue(){
      if(!this.param.defuda){
        this.param.kimariji=''
        this.param.place1='',
        this.param.place2=''
        this.param.situation=''
        this.param.detailRecord=''
        this.param.evaluation=''
      }
    },
    resetParam(){
      this.param={
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:0,
        opponentOtetsuki:0,
        comment:'',
      }
    },
    removeEditParamValue(){
      if(!this.editParam.defuda){
        this.editParam.kimariji=''
        this.editParam.place1='',
        this.editParam.place2=''
        this.editParam.situation=''
        this.editParam.detailRecord=''
        this.editParam.evaluation=''
      }
    },
    suggestKimariji(){
      console.log(this.param.cardGroup)
      var kimariji = 0;
      console.log(kimariji)
      var unUsedCards = this.getUnusedCards(this.param.cardGroup);
      unUsedCards.forEach((item) => {
        if(item!=this.param.card){
          var sameCharacterNumber = 0;
          var cardLength = this.param.card.length;
          var itemLength = item.length;
          for(let i=1;i<=Math.min(cardLength,itemLength);i++){
            if(this.param.card.slice(0,i)!=item.slice(0,i)){
              break;
            } else {
              sameCharacterNumber = i;
            }
          }
          if(sameCharacterNumber>kimariji){
            kimariji = sameCharacterNumber;
          }
        }
      });
      this.param.kimariji = String(kimariji+1)+"字";
    }
  },
  data() {
    return {
      testObject:{},
      key:'',
      uid: firebase.auth().currentUser.uid,
      selected:{},
      hyakuninnisshu : {
        'むすめふさほせ':['む','す','め','ふ','さ','ほ','せ'],
        'うつしもゆ':['うか','うら','つき','つく','しら','しの','もも','もろ','ゆう','ゆら'],
        'いちひき':['いに','いまこ','いまは','ちは','ちぎりき','ちぎりお','ひさ','ひとは','ひとも','きり','きみがためは','きみがためお'],
        'はやよか':['はるす','はるの','はなさ','はなの','やえ','やす','やまが','やまざ','よを','よも','よのなかよ','よのなかは','かさ','かく','かぜそ','かぜを'],
        'み':['みち','みよ','みせ','みかき','みかの'],
        'た':['たか','たち','たご','たま','たき','たれ'],
        'こ':['これ','こひ','この','こぬ','こころに','こころあ'],
        'お':['おく','おぐ','おと','おも','おおけ','おおこ','おおえ'],
        'わ':['わび','わがい','わがそ','わすれ','わすら','わたのはらこ','わたのはらや'],
        'な':['なつ','ながら','ながか','なげけ','なげき','なにし','なにわが','なにわえ'],
        'あ':['あわじ','あわれ','あらし','あらざ','あきの','あきか','あまつ','あまの','ありあ','ありま','あさじ','あさぼらけあ','あさぼらけう','あし','あい','あけ'],
      },
      hyakuninnisshuGroup : ['','むすめふさほせ','うつしもゆ','いちひき','はやよか','み','た','こ','お','わ','な','あ'],
      param:{
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:0,
        opponentOtetsuki:0,
        comment:''
      },
      editParam:{
        card:'',
        defuda:'',
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:0,
        opponentOtetsuki:0,
        comment:''
      },
      index:'',
      detailRecord:[],
      defudaOptions:[
        {value:true,text:'出札'},
        {value:false,text:'空札'},
      ],
      kimarijiOptions:[
        {value:'',text:''},
        {value:'1字',text:'1字'},
        {value:'2字',text:'2字'},
        {value:'3字',text:'3字'},
        {value:'4字',text:'4字'},
        {value:'5字',text:'5字'},
        {value:'6字',text:'6字'},
      ],
      place1Options:[
        {value:'',text:''},
        {value:'自陣',text:'自陣'},
        {value:'敵陣',text:'敵陣'},
      ],
      place2Options:[
        {value:'',text:''},
        {value:'右下段',text:'右下段'},
        {value:'右中段',text:'右中段'},
        {value:'右上段',text:'右上段'},
        {value:'左下段',text:'左下段'},
        {value:'左中段',text:'左中段'},
        {value:'左上段',text:'左上段'},
        {value:'浮き札（上段）',text:'浮き札（上段）'},
        {value:'浮き札（中段）',text:'浮き札（中段）'},
        {value:'浮き札（下段）',text:'浮き札（下段）'},
      ],
      situationOptions:[
        {value:'',text:''},
        {value:'単独',text:'単独'},
        {value:'右対角分かれ',text:'右対角分かれ'},
        {value:'左対角分かれ',text:'左対角分かれ'},
        {value:'右縦分かれ',text:'右縦分かれ'},
        {value:'左縦分かれ',text:'左縦分かれ'},
        {value:'自陣左右',text:'自陣左右'},
        {value:'敵陣左右',text:'敵陣左右'},
        {value:'3箇所',text:'3箇所'},
        {value:'4箇所',text:'4箇所'},
      ],
      detailRecordOptions:[
        {value:'自分の取り',text:'自分の取り'},
        {value:'相手の取り',text:'相手の取り'},
      ],
      evaluationOptions:[
        {value:'◎',text:'◎'},
        {value:'○',text:'○'},
        {value:'△',text:'△'},
        {value:'×',text:'×'},
      ],
      otetsukiOptions:[
        {value:0,text:'-'},
        {value:1,text:'○'},
        {value:2,text:'◎'}
      ],
      recordCurrentPage:1,
      result:{
        defaultDate:new Date(),
        round:'',
        opponent:'',
        club:'',
        rank:'',
        result:'',
        cardnumber:'',
        otetsukiNumber:'',
        comment:'',
        player:'',
        hand:'',
        place:'',
        reader:'',
        type:'',
        dan:'',
      },
      resultForTable:[],
      recordFields:[
        {
          key:'content',
          label:'内容'
        },
        // {
        //   key : 'button',
        //   label : '',
        // },
        // {
        //   key : 'index',
        //   label : 'No'
        // },
        // {
        //   key : 'card',
        //   label : '読札'
        // },
        // {
        //   key : 'kimariji',
        //   label : ''
        // },
        // {
        //   key : 'place',
        //   label : '場所',
        // },
        // {
        //   key : 'situation',
        //   label : '状況',
        // },
        // {
        //   key : 'detailRecord',
        //   label : '結果',
        // },
        // {
        //   key : 'evaluation',
        //   label : '評価',
        // },
      ],
      fields:[{key:'date',label:'日付'},{key:'opponent',label:'相手'},{key:'club',label:'所属'},{key:'result',label:'結果'},{key:'cardnumber',label:'枚数'}],
      insertNumber:1,
    }
  },
  filters: {
    dayjs: function (date) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    dayjs2: function (date) {
      return dayjs(date).format('MM/DD');
    },
    truncate: function(value) {
      var length2 = 20;
      var ommision =  '...';

      if (value.length <= length2) {
        return value;
      }

      return value.substring(0, length2) + ommision;
    },
  },
  mounted: function(){
    // this.readDataFromStorage();
  }
}
</script>
