<template>
  <div class="container">
    <div class="border">
      <Header />
      <br>
    </div>
  </div>
</template>

<style>
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .container {
    margin-bottom: 64px;
  }
}
.mt-4 {
  margin-top: 4px;
}
.row {
  margin-bottom: 4px;
  margin-left: 0px;
  margin-right: 0px;
}
.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}
.vdp-datepicker__calendar {
  width: auto !important;
}
/*PCの場合*/
.pc	{ display:inline!important; }
.mb	{ display:none!important; }
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .pc	{ display:none!important; }
  .mb { display:inline!important; }
}
.mx-1 {
  margin-bottom: 0.75em;
}

.modal-dialog {
  max-width: 95%;
  margin: 1.75rem auto;
}
.tooltip-inner {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap:break-word;
}
.searchclear {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ccc;
  vertical-align: middle;
}
</style>

<script>
import Header from '@/components/Header.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore';
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

export default {
  name: 'detailRecordStatistics',
  components:{
    Header,
  },
  created: function() {
    this.database = firebase.database();
    this.uid = firebase.auth().currentUser.uid;
    this.db = firebase.firestore()

    var detailRecordRef = this.db.collection("detailRecord");
    var query = detailRecordRef.where("uid","==",this.uid);
    var _this = this
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        console.log(doc.id, ' => ', doc.data());
        _this.testObject[doc.id] = doc.data();
      });
    });
  },
  methods: {
  },
  data() {
    return {
      testObject:{},
      key:'',
      uid: firebase.auth().currentUser.uid,
      selected:{},
      hyakuninnisshu : {
        'むすめふさほせ':['む','す','め','ふ','さ','ほ','せ'],
        'うつしもゆ':['うか','うら','つき','つく','しら','しの','もも','もろ','ゆう','ゆら'],
        'いちひき':['いに','いまこ','いまは','ちは','ちぎりき','ちぎちお','ひさ','ひとは','ひとも','きり','きみがためは','きみがためお'],
        'はやよか':['はるす','はるの','はなさ','はなの','やえ','やす','やまが','やまざ','よを','よも','よのなかよ','よのなかは','かさ','かく','かぜそ','かぜを'],
        'み':['みち','みよ','みせ','みかき','みかの'],
        'た':['たか','たち','たご','たま','たき','たれ'],
        'こ':['これ','こひ','この','こぬ','こころに','こころあ'],
        'お':['おく','おぐ','おと','おも','おおけ','おおこ','おおえ'],
        'わ':['わび','わがい','わがそ','わすれ','わすら','わたのはらこ','わたのはらや'],
        'な':['なつ','ながら','ながか','なげけ','なげき','なにし','なにわが','なにわえ'],
        'あ':['あわじ','あわれ','あらし','あらざ','あきの','あきか','あまつ','あまの','ありあ','ありま','あさじ','あさぼらけあ','あさぼらけう','あし','あい','あけ'],
      },
      param:{
        card:'',
        defuda:true,
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:false,
        opponentOtetsuki:false,
        comment:''
      },
      editParam:{
        card:'',
        defuda:'',
        kimariji:'',
        cardGroup:'',
        place1:'',
        place2:'',
        situation:'',
        detailRecord:'',
        evaluation:'',
        myOtetsuki:'',
        opponentOtetsuki:'',
        comment:''
      },
      index:'',
      detailRecord:[],
      defudaOptions:[
        {value:true,text:'出札'},
        {value:false,text:'空札'},
      ],
      kimarijiOptions:[
        {value:'',text:''},
        {value:'1字',text:'1字'},
        {value:'2字',text:'2字'},
        {value:'3字',text:'3字'},
        {value:'4字',text:'4字'},
        {value:'5字',text:'5字'},
        {value:'6字',text:'6字'},
      ],
      place1Options:[
        {value:'',text:''},
        {value:'自陣',text:'自陣'},
        {value:'敵陣',text:'敵陣'},
      ],
      place2Options:[
        {value:'',text:''},
        {value:'右下段',text:'右下段'},
        {value:'右中段',text:'右中段'},
        {value:'右上段',text:'右上段'},
        {value:'左下段',text:'左下段'},
        {value:'左中段',text:'左中段'},
        {value:'左上段',text:'左上段'},
        {value:'浮き札（上段）',text:'浮き札（上段）'},
        {value:'浮き札（中段）',text:'浮き札（中段）'},
        {value:'浮き札（下段）',text:'浮き札（下段）'},
      ],
      situationOptions:[
        {value:'',text:''},
        {value:'単独',text:'単独'},
        {value:'右対角分かれ',text:'右対角分かれ'},
        {value:'左対角分かれ',text:'左対角分かれ'},
        {value:'右縦分かれ',text:'右縦分かれ'},
        {value:'左縦分かれ',text:'左縦分かれ'},
        {value:'自陣左右',text:'自陣左右'},
        {value:'敵陣左右',text:'敵陣左右'},
        {value:'3箇所',text:'3箇所'},
        {value:'4箇所',text:'4箇所'},
      ],
      detailRecordOptions:[
        {value:'自分の取り',text:'自分の取り'},
        {value:'自分の取り',text:'相手の取り'},
      ],
      evaluationOptions:[
        {value:'◎',text:'◎'},
        {value:'○',text:'○'},
        {value:'△',text:'△'},
        {value:'×',text:'×'},
      ],
      otetsukiOptions:[
        {value:false,text:'-'},
        {value:true,text:'○'},
      ],
      recordCurrentPage:1,
      result:{
        defaultDate:new Date(),
        round:'',
        opponent:'',
        club:'',
        rank:'',
        result:'',
        cardnumber:'',
        otetsukiNumber:'',
        comment:'',
        player:'',
        hand:'',
        place:'',
        reader:'',
        type:'',
        dan:'',
      },
      resultForTable:[],
      recordFields:[
        {
          key:'content',
          label:'内容'
        },
      ],
      fields:[{key:'date',label:'日付'},{key:'opponent',label:'相手'},{key:'club',label:'所属'},{key:'result',label:'結果'},{key:'cardnumber',label:'枚数'}],
      insertNumber:1,
    }
  },
  filters: {
    dayjs: function (date) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    dayjs2: function (date) {
      return dayjs(date).format('MM/DD');
    },
    truncate: function(value) {
      var length2 = 20;
      var ommision =  '...';

      if (value.length <= length2) {
        return value;
      }

      return value.substring(0, length2) + ommision;
    },
  },
  mounted: function(){
    // this.readDataFromStorage();
  }
}
</script>
