var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"border"},[_c('Header'),_c('h5',[_vm._v("ご説明")]),_vm._m(0),_c('hr'),_c('h5',[_vm._v("機能")]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-12"},[_c('router-link',{attrs:{"to":"/success"}},[_vm._v("記録")])],1),_c('dd',{staticClass:"col-12"},[_vm._v("対戦結果を記録できます。")]),_c('dt',{staticClass:"col-12"},[_c('router-link',{attrs:{"to":"/statistics"}},[_vm._v("統計")])],1),_vm._m(1),_c('dt',{staticClass:"col-12"},[_c('router-link',{attrs:{"to":"/goal"}},[_vm._v("目標")])],1),_vm._m(2)]),_c('hr'),_c('h5',[_vm._v("使い方")]),_vm._m(3),_c('router-link',{attrs:{"to":"/success"}},[_vm._v("記録ページ")]),_vm._v("をお気に入り登録する。"),_c('br'),_vm._v(" または"),_c('br'),_vm._v(" 歌留多録をホーム画面に追加する。"),_c('br'),_vm._v(" やりかた："),_c('a',{attrs:{"href":"https://www.ipodwave.com/iphone/howto/website_home.html","target":"_blank"}},[_vm._v("iPhone")]),_vm._v("、"),_c('a',{attrs:{"href":"https://getsaitan.blog.fc2.com/blog-entry-276.html","target":"_blank"}},[_vm._v("Android")]),_c('br'),_c('br'),_vm._m(4),_vm._v(" 記録ページを開いて、記録する。"),_c('br'),_vm._v(" 必須入力は日付のみです。"),_c('br'),_vm._m(5),_c('br'),_c('br'),_vm._m(6),_vm._v(" 統計ページを開いて、ふりかえる。"),_c('br'),_c('hr'),_c('h5',[_vm._v("端末について")]),_vm._m(7),_vm._v(" ログアウトは不要です。"),_c('br'),_vm._v(" 次回記録ページを直接開くと、"),_c('br'),_vm._v(" ログイン操作をスキップできます。"),_c('br'),_c('br'),_vm._m(8),_c('div',{staticClass:"text-danger font-weight-bold"},[_vm._v("使用後に必ずログアウトしてください。")]),_c('br')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" 小倉百人一首競技かるたの対戦記録・統計アプリです。"),_c('br'),_vm._v(" 作者：おくさん（"),_c('a',{attrs:{"href":"https://twitter.com/0kdynnkw","target":"_blank"}},[_vm._v("@0kdynnkw")]),_vm._v("）"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',{staticClass:"col-12"},[_c('span',{staticClass:"description"},[_vm._v("記録を集計し、")]),_c('span',{staticClass:"description"},[_vm._v("集計結果を表やグラフで確認できます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',{staticClass:"col-12"},[_c('span',{staticClass:"description"},[_vm._v("結果面、行動面に分けて")]),_c('span',{staticClass:"description"},[_vm._v("現在の目標を登録できます。")]),_c('span',{staticClass:"description"},[_vm._v("以前の目標を5段階で振り返り、")]),_c('span',{staticClass:"description"},[_vm._v("過去の目標として登録できます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("準備")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("記録")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('small',[_vm._v("暗記時間中の記録はあまりオススメしません……")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("ふりかえり")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("本人しか操作できない端末")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('b',[_vm._v("それ以外の端末")])])
}]

export { render, staticRenderFns }