<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-footer">
          <b-navbar class="header" fixed="bottom" type="light" variant="light" style="z-index:50">
            <div class="col-1-5" style="padding:0px"><div id="nav" style="padding:0px"><router-link to="/success"><font-awesome-icon icon="pencil-alt"/><br>記録</router-link></div></div>
            <div class="col-2-5" style="padding:0px"><div id="nav" style="padding:0px"><router-link router-link-active to="/statistics"><font-awesome-icon icon="chart-pie"/><br>統計</router-link></div></div>
            <div class="col-3-5" style="padding:0px"><div id="nav" style="padding:0px"><router-link to="/goal"><font-awesome-icon icon="bullseye"/><br>目標</router-link></div></div>
            <div class="col-4-5" style="padding:0px"><div class="text-secondary"><font-awesome-icon icon="book-open"/><br><b><s>メモ</s></b></div></div>
            <div class="col-5-5" style="padding:0px">
              <div id="nav" style="padding:0px"><a v-b-toggle.sidebar-1><font-awesome-icon icon="bars"/><br><b>その他</b></a></div>
              <b-sidebar id="sidebar-1" width="60%" right shadow backdrop>
                <div class="px-3 py-2">
                  <p><b-img :src="this.user.photoURL" width="50px" class="align-items-center"></b-img><br>{{this.user.displayName}}</p>
                  <hr>
                  <div id="nav" style="padding:0px"><router-link to="/about">ご説明</router-link></div>
                  <div id="nav" style="padding:0px"><router-link to="/contact">お問い合わせ</router-link></div>
                  <div id="nav" style="padding:0px"><router-link to="/read">読み札アプリ</router-link></div>
                  <div id="nav" style="padding:0px"><a href="" @click="logout" style="padding-left:0px">ログアウト</a></div>
                </div>
              </b-sidebar>
            </div>
            <router-view/>
          </b-navbar>
        </div>
        <div class="pc">
          <h2>歌留多録</h2>
          <div class="row">
            <div class="col-sm-12">
              <div id="nav" style="padding:0px">
                <p><b-img :src="this.user.photoURL" width="35" class="align-items-center"></b-img>{{this.user.displayName}} | <a href="" @click="logout" style="padding-left:0px">ログアウト</a></p>
                <router-link to="/success">記録</router-link> |
                <router-link router-link-active to="/statistics">統計</router-link> |
                <router-link to="/goal">目標</router-link> |
                <router-link to="/about">ご説明</router-link> |
                <router-link to="/contact">お問い合わせ</router-link> |
                <!-- <router-link router-link-active to="/detailRecordStatistics">1枚ごとの詳細を分析</router-link> | -->
                <router-link to="/read">読み札アプリ</router-link>
              </div>
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@media screen and (min-width: 769px) {
  /*PCの場合*/
  .pc	{ display:inline!important; }
  .mb-footer	{ display:none!important; }
}

@media screen and (max-width: 768px) and (min-height:451px) {
  /*タブレット、スマホの場合*/
  .pc	{ display:none!important; }
  .mb-footer { display:inline!important; }
}

@media screen and (max-width: 768px) and (max-height:450px) {
  /*タブレット、スマホの場合で、キーボードが表示された場合はフッターを消す*/
  .pc	{ display:none!important; }
  .mb-footer { display:none!important; }
}

#nav a.router-link-exact-active {
  color:#28a745;
}

.col-1-5,.col-2-5,.col-3-5,.col-4-5,.col-5-5{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-1-5 {
  width: 20%;
  float: left;
}
.col-2-5 {
  width: 20%;
  float: left;
}

.col-3-5 {
  width: 20%;
  float: left;
}

.col-4-5 {
  width: 20%;
  float: left;
}

.col-5-5 {
  width: 20%;
  float: left;
}
</style>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import router from '../router'

export default{
  name: 'header-menu',
  created: function(){
    this.database = firebase.database();
  },
  methods: {
    logout() {
      firebase.auth().signOut().then(function() {
        router.push('/')
      }).catch(error => {
        console.log(error.message)
        router.push('/')
      })
    },
  },
  data (){
    return{
      user: firebase.auth().currentUser,
    }
  }
}
</script>
