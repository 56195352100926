<template>
  <div>
    <h5>お問い合わせ</h5>
    <b-container ref="form" fluid>
      <b-card class="text-left">
        歌留多録についてお問い合わせがありましたら、下記のフォームまたは<a href="https://twitter.com/0kdynnkw" target="_blank">twitterアカウント</a>からお問い合わせください。<br>
        返信を希望される場合は、メールアドレスまたはTwitter IDをご入力ください。
      </b-card>
      <hr>
      <validation-observer ref="validationObserver" v-slot="{ invalid }">
        <validation-provider rules="required" v-slot="{ errors }" name="お名前">
          <b-row class="my-1">
            <b-col sm="3" class="my-auto">
              <label class="my-auto">お名前<span class="text-danger font-weight-bold">*</span></label>
            </b-col>
            <b-col sm="9" class="my-auto">
              <b-form-input type="text" v-model="contactForm.name"></b-form-input>
            </b-col>
          </b-row>
          <span>{{ errors[0] }}</span>
        </validation-provider>
        <validation-provider rules="email" v-slot="{ errors }" name="メールアドレス">
          <b-row class="my-1">
            <b-col sm="3" class="my-auto">
              <label class="my-auto">メールアドレス</label>
            </b-col>
            <b-col sm="9" class="my-auto">
              <b-form-input type="email" v-model="contactForm.email" label="メールアドレス"></b-form-input>
            </b-col>
          </b-row>
          <span>{{ errors[0] }}</span>
        </validation-provider>
        <b-row class="my-1">
          <b-col sm="3" class="my-auto">
            <label class="my-auto">Twitter ID</label>
          </b-col>
          <b-col sm="9" class="my-auto">
            <b-form-input type="text" v-model="contactForm.twitterId"></b-form-input>
          </b-col>
        </b-row>
        <validation-provider rules="required" v-slot="{ errors }" name="お問合せ内容">
          <b-row class="my-1">
            <b-col sm="3" class="my-auto">
              <label class="my-auto">お問合せ内容<span class="text-danger font-weight-bold">*</span></label>
            </b-col>
            <b-col sm="9" class="my-auto">
              <b-textarea v-model="contactForm.contents" label="内容" placeholder="使い方に関する質問、追加してほしい機能、改善してほしい機能、アプリの感想…etc" rows="4"></b-textarea>
            </b-col>
          </b-row>
          <span>{{ errors[0] }}</span>
        </validation-provider><br>
        <b-btn :loading="contactForm.loading" variant="success" class="mx-2 my-1" @click="sendMail()" :disabled="invalid">送信</b-btn>
        <p v-show="invalid" class="help is-danger">
          ※必須項目を入力すると、送信ボタンが有効になります。
        </p>
      </validation-observer>
    </b-container>
  </div>
</template>
<style>
span.description {
  display: inline-block;
}
</style>
<script>
import { functions } from "@/main.js"
import { ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
// import router from '../router'

extend("required", {
  ...required,
  message: "{_field_}を入力してください。"
});
extend("email", {
  ...email,
  message: "{_field_}形式で入力してください。"
});
export default {
  name: 'contact-form',
  data: () => ({
    contactForm: {
      name: '',
      contents: '',
      email: '',
      twitterId: '',
      loading: false
    },
    value:'',
  }),
  components:{
    ValidationProvider,
  },
  methods: {
    sendMail: function () {
      this.contactForm.loading = true
      const mailer = functions.httpsCallable('sendMail')

      mailer(this.contactForm)
      .then(() => {
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        alert('お問合せいただき、ありがとうございました。');
        // this.$router.go({path: this.$router.currentRoute.path, force: true})
        this.contactForm = {
          name: '',
          contents: '',
          email: '',
          twitterId: '',
          loading: false
        },
        this.$refs.validationObserver.reset();
        // this.contactForm.loading = false
      })
    },
  }
}
</script>
