import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
// import PrivacyPolicy from './views/PrivacyPolicy.vue'
import Success from './views/Success.vue'
import About from './views/About.vue'
import Statistics from './views/Statistics.vue'
import Read from './views/Read.vue'
import Goal from './views/Goal.vue'
import Contact from './views/Contact.vue'
import DetailRecord from './views/DetailRecord.vue'
import DetailRecordPublic from './views/DetailRecordPublic.vue'
import DetailRecordStatistics from './views/DetailRecordStatistics.vue'
// import Settings from './views/Settings.vue'
import firebase from 'firebase/app'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/read',
      name: 'read',
      component: Read,
      meta: { title: '読み札アプリ | 歌留多録' }
    },
    // {
    //   path: '/privacypolicy',
    //   name: 'PrivacyPolicy',
    //   component: PrivacyPolicy
    // },
    {
      path: '/success',
      name: 'success',
      component: Success,
      meta: { title: '記録 | 歌留多録' }
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: Statistics,
      meta: { title: '統計 | 歌留多録' }
    },
    {
      path: '/goal',
      name: 'goal',
      component: Goal,
      meta: { title: '目標 | 歌留多録' }
    },
    // {
    //   path: '/settings',
    //   name: 'settings',
    //   component: Settings
    // },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: { title: 'ご説明 | 歌留多録' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: { title: 'お問い合わせ | 歌留多録' }
    },
    {
      path: '/detailRecord',
      name: 'detailRecord',
      component: DetailRecord,
      meta: { title: '1枚ごとの記録 | 歌留多録' }
    },
    {
      path: '/detailRecordPublic',
      name: 'detailRecordPublic',
      component: DetailRecordPublic,
      meta: { title: '1枚ごとの記録（公開版） | 歌留多録' }
    },
    {
      path: '/detailRecordStatistics',
      name: 'detailRecordStatistics',
      component: DetailRecordStatistics,
      meta: { title: '1枚ごとの記録を分析 | 歌留多録' }
    },
    {
      path: '*',
      name: 'others',
      component: Login
    }
  ],
  scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    const position = {}
    if(to.hash){
      position.selector = to.hash
      return position
    } else {
      return { x: 0, y: 0 }
    }
  }
}
})

// 未認証の場合はログイン画面へ
router.beforeResolve((to, from, next) => {
  if (to.path == '/') {
    next()
  } else {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log('認証中')
        if (to.meta && to.meta.title) {
          document.title = to.meta.title
        }
        next()
      } else {
        //ログインしなくても使えるページ
        switch(to.path){
          case '/read':
            next();
            break;
          case '/detailRecordPublic':
            next();
            break;
          default:
            console.log('未認証')
            next({path: '/'})
        }
      }
    })
  }
})

export default router
