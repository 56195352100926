<template>
  <div class="container">
    <div class="border">
      <Header />
      <h5>ご説明</h5>
      <div style="text-align:center">
        小倉百人一首競技かるたの対戦記録・統計アプリです。<br>
        作者：おくさん（<a href="https://twitter.com/0kdynnkw" target="_blank">@0kdynnkw</a>）<br>
      </div>
      <hr>
      <h5>機能</h5>
      <dl class="row">
        <dt class="col-12"><router-link to="/success">記録</router-link></dt>
        <dd class="col-12">対戦結果を記録できます。</dd>
        <dt class="col-12"><router-link to="/statistics">統計</router-link></dt>
        <dd class="col-12"><span class="description">記録を集計し、</span><span class="description">集計結果を表やグラフで確認できます。</span></dd>
        <dt class="col-12"><router-link to="/goal">目標</router-link></dt>
        <dd class="col-12"><span class="description">結果面、行動面に分けて</span><span class="description">現在の目標を登録できます。</span><span class="description">以前の目標を5段階で振り返り、</span><span class="description">過去の目標として登録できます。</span></dd>
      </dl>
      <hr>
      <h5>使い方</h5>
      <h6><b>準備</b></h6>
      <router-link to="/success">記録ページ</router-link>をお気に入り登録する。<br>
      または<br>
      歌留多録をホーム画面に追加する。<br>
      やりかた：<a href="https://www.ipodwave.com/iphone/howto/website_home.html" target="_blank">iPhone</a>、<a href="https://getsaitan.blog.fc2.com/blog-entry-276.html" target="_blank">Android</a><br>
      <br>
      <h6><b>記録</b></h6>
      記録ページを開いて、記録する。<br>
      必須入力は日付のみです。<br>
      <small><small>暗記時間中の記録はあまりオススメしません……</small></small><br>
      <br>
      <h6><b>ふりかえり</b></h6>
      統計ページを開いて、ふりかえる。<br>
      <hr>
      <h5>端末について</h5>
      <h6><b>本人しか操作できない端末</b></h6>
      ログアウトは不要です。<br>
      次回記録ページを直接開くと、<br>
      ログイン操作をスキップできます。<br>
      <br>
      <h6><b>それ以外の端末</b></h6>
      <div class="text-danger font-weight-bold">使用後に必ずログアウトしてください。</div><br>
  </div>
</div>
</template>
<style>
span.description {
   display: inline-block;
}
</style>
<script>
import Header from '@/components/Header.vue';

export default {
  name: 'about',
  components:{
    Header,
  }
}
</script>
