<template>
  <div class="container">
    <div class="border">
      <Header />
      <h5>現在の目標（結果面）</h5>
      <div class="form-group border-success" id="goal">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <label for="datepicker" class="col-sm-12 col-md-3 my-auto">開始日</label>
              <div class="col-sm-12 col-md-9 my-auto" id="date_picker">
                <Datepicker
                :language="ja"
                :format="DatePickerFormat"
                input-class="form-control inline"
                v-model="recentResultGoal.startDate"
                :typeable="false"
                name="datepicker"></Datepicker>
                <b-button variant="link" @click="clear('recentResultGoal_startDate')" class="searchclear">x</b-button>
              </div>
            </div>
            <div class="row">
              <label for="datepicker" class="col-sm-12 col-md-3 my-auto">期日</label>
              <div class="col-sm-12 col-md-9 my-auto" id="date_picker">
                <Datepicker
                :language="ja"
                :format="DatePickerFormat"
                input-class="form-control"
                v-model="recentResultGoal.endDate"
                :typeable="false"
                name="datepicker"></Datepicker>
                <b-button variant="link" @click="clear('recentResultGoal_endDate')" class="searchclear">x</b-button>
              </div>
            </div>
            <div class="row">
              <label for="content" class="col-sm-12 col-md-3 my-auto">内容</label>
              <div class="col-sm-12 col-md-9 my-auto">
                <b-form-textarea class="form-control" placeholder="達成したい結果" v-model="recentResultGoal.content" rows="3"></b-form-textarea>
              </div>
            </div>
            <b-button variant="outline-success" size="sm" class="my-2 mx-2" @click="setOldGoalParams('result');$bvModal.show('submitOldGoal')">過去の目標として登録</b-button>
            <b-button variant="success" size="sm" class="my-2 mx-2" @click="$bvModal.show('updateRecentResultGoal')">現在の目標を更新</b-button>
            <b-modal v-bind:id="'updateRecentResultGoal'" hide-footer hide-header>
              <template v-slot:modal-title>
              </template>
              <div class="d-block text-center">
                <h5><b>更新前</b></h5>
                <p>開始日：{{conversion(loadedRecentResultGoal.startDate)}}<br>期日：{{conversion(loadedRecentResultGoal.endDate)}}</p>
                <div v-for="(line,index) in loadedRecentResultGoal.content.split('\n')" :key="`first-${index}`">{{line}}<br></div>
                <hr>
                <h5><b>更新後</b></h5>
                <p>開始日：{{conversion(recentResultGoal.startDate)}}<br>期日：{{conversion(recentResultGoal.endDate)}}</p>
                <div v-for="(line,index) in recentResultGoal.content.split('\n')" :key="`second-${index}`">{{line}}<br></div>
                <hr>
                <b-button class="mx-1 my-1" size="sm" variant="secondary" @click="$bvModal.hide('updateRecentResultGoal')">やめる</b-button>
                <b-button class="mx-1 my-1" size="sm" variant="success" @click="$bvModal.hide('updateRecentResultGoal');updateRecentResultGoal()">更新</b-button>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
      <h5>現在の目標（行動面）</h5>
      <div class="form-group border-success" id="goal">
        <div class="row">
          <div class="col-12">
                <div class="row">
                  <label for="datepicker" class="col-sm-12 col-md-3 my-auto">開始日</label>
                  <div class="col-sm-12 col-md-9 my-auto" id="date_picker">
                    <Datepicker
                    :language="ja"
                    :format="DatePickerFormat"
                    input-class="form-control"
                    v-model="recentActionGoal.startDate"
                    :typeable="false"
                    name="datepicker"></Datepicker>
                    <b-button variant="link" @click="clear('recentActionGoal_startDate')" class="searchclear">x</b-button>
                  </div>
                </div>
                <div class="row">
                  <label for="datepicker" class="col-sm-12 col-md-3 my-auto">期日</label>
                  <div class="col-sm-12 col-md-9 my-auto" id="date_picker">
                    <Datepicker
                    :language="ja"
                    :format="DatePickerFormat"
                    input-class="form-control"
                    v-model="recentActionGoal.endDate"
                    :typeable="false"
                    name="datepicker"></Datepicker>
                    <b-button variant="link" @click="clear('recentActionGoal_endDate')" class="searchclear">x</b-button>
                  </div>
                </div>
                <div class="row">
                  <label for="content" class="col-sm-12 col-md-3 my-auto">内容</label>
                  <div class="col-sm-12 col-md-9 my-auto">
                    <b-form-textarea class="form-control" placeholder="実現するための行動" v-model="recentActionGoal.content" rows="3"></b-form-textarea>
                  </div>
                </div>
                <b-button variant="outline-success" size="sm" class="mx-2 my-1" @click="setOldGoalParams('action');$bvModal.show('submitOldGoal')">過去の目標として登録</b-button>
                <b-button variant="success" size="sm" class="mx-2 my-1" @click="$bvModal.show('updateRecentActionGoal')">現在の目標を更新</b-button>
                <b-modal v-bind:id="'updateRecentActionGoal'" hide-footer hide-header>
                  <div class="d-block text-center">
                    <h5><b>更新前</b></h5>
                    <p>開始日：{{conversion(loadedRecentActionGoal.startDate)}}<br>期日：{{conversion(loadedRecentActionGoal.endDate)}}</p>
                    <div v-for="(line,index) in loadedRecentActionGoal.content.split('\n')" :key="`first-${index}`">{{line}}<br></div>
                    <hr>
                    <h5><b>更新後</b></h5>
                    <p>開始日：{{conversion(recentActionGoal.startDate)}}<br>期日：{{conversion(recentActionGoal.endDate)}}</p>
                    <div v-for="(line,index) in recentActionGoal.content.split('\n')" :key="`second-${index}`">{{line}}<br></div>
                    <hr>
                    <b-button class="mx-1 my-1" size="sm" variant="secondary" @click="$bvModal.hide('updateRecentActionGoal')">やめる</b-button>
                    <b-button class="mx-1 my-1" size="sm" variant="success" @click="$bvModal.hide('updateRecentActionGoal');updateRecentActionGoal()">更新</b-button>
                  </div>
                </b-modal>

          </div>
        </div>
      </div>
      <hr>
      <h5>過去の目標</h5>
      <b-button variant="outline-success" size="sm" class="my-2 mx-2" @click="clearOldGoalPrams();$bvModal.show('submitOldGoal')">登録</b-button>
      <b-modal v-bind:id="'submitOldGoal'" hide-footer hide-header>
        <div class="form-group border-success d-block text-center" id="goal">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <label for="datepicker" class="col-md-4 my-auto">目標の種類</label>
                    <div class="col-md-8">
                      <select class="form-control" v-model="oldGoal.goalType">
                        <option>結果</option>
                        <option>行動</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label for="datepicker" class="col-md-4 my-auto">開始日<span class="text-danger font-weight-bold">*</span></label>
                    <div class="col-md-8" id="date_picker">
                      <Datepicker
                      :language="ja"
                      :format="DatePickerFormat"
                      input-class="form-control inline"
                      v-model="oldGoal.startDate"
                      :typeable="false"
                      name="datepicker"></Datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <label for="datepicker" class="col-md-4 my-auto">終了日<span class="text-danger font-weight-bold">*</span></label>
                    <div class="col-md-8" id="date_picker">
                      <Datepicker
                      :language="ja"
                      :format="DatePickerFormat"
                      input-class="form-control inline"
                      v-model="oldGoal.endDate"
                      :typeable="false"
                      name="datepicker"></Datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <label for="evaluation" class="col-md-4 my-auto">内容</label>
                    <div class="col-md-8">
                      <b-form-textarea class="form-control" placeholder="" v-model="oldGoal.content" rows="3"></b-form-textarea>
                    </div>
                  </div>
                  <div class="row">
                    <label for="datepicker" class="col-md-4 my-auto">達成状況</label>
                    <div class="col-md-8">
                      <select class="form-control" v-model="oldGoal.evaluation">
                        <option></option>
                        <option>★★★★★</option>
                        <option>★★★★</option>
                        <option>★★★</option>
                        <option>★★</option>
                        <option>★</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label for="evaluation" class="col-md-4 my-auto">ふりかえり</label>
                    <div class="col-md-8">
                      <b-form-textarea class="form-control" placeholder="" v-model="oldGoal.feedback" rows="1"></b-form-textarea>
                    </div>
                  </div>
                  <b-button variant="outline-secondary" size="sm" class="my-2 mx-2" @click="clearOldGoalPrams();$bvModal.hide('submitOldGoal')">閉じる</b-button>
                  <b-button variant="outline-success" size="sm" class="my-2 mx-2" @click="onsubmit();">登録</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <div class="pc">
        <b-pagination
        v-model="currentPage"
        :total-rows="this.oldGoals.length"
        :per-page=25
        first-number
        last-number
        aria-controls="record-table"
        ></b-pagination>
        <b-table id="oldGoals" bordered hover responsive :items="this.oldGoals" :fields="fields" :per-page=25 :current-page="currentPage">
          <template v-slot:[`cell(value.goalType)`]="data">
            <div>{{data.item.value.goalType}}</div>
            <b-button class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingOldGoal(data.item.value);$bvModal.show('editOldGoal')">更新</b-button>
          </template>
          <template v-slot:[`cell(value.content)`]="data">
            <div v-for="(line,index) in data.item.value.content.split('\n')" :key="`${index}`">{{line}}<br></div>
          </template>
          <template v-slot:[`cell(value.feedback)`]="data">
            <div v-for="(line,index) in data.item.value.feedback.split('\n')" :key="`${index}`">{{line}}<br></div>
          </template>
        </b-table>
        <b-pagination
        v-model="currentPage"
        :total-rows="this.oldGoals.length"
        :per-page=25
        first-number
        last-number
        aria-controls="record-table"
        ></b-pagination>

      </div>
      <div class="mb">
        <b-pagination
        v-model="currentPage"
        :total-rows="this.oldGoals.length"
        :per-page=25
        first-number
        last-number
        aria-controls="record-table"
        ></b-pagination>
        <b-table id="oldGoals" bordered hover responsive :items="this.oldGoals" :fields="mobileFields" :per-page=25 :current-page="currentPage">
          <template v-slot:cell(content)="data">
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-4">種類</div>
              <div class="col-8 text-left">{{data.item.value.goalType}}</div>
            </div>
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-4">開始日</div>
              <div class="col-8 text-left">{{data.item.value.startDate}}</div>
            </div>
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-4">終了日</div>
              <div class="col-8 text-left">{{data.item.value.endDate}}</div>
            </div>
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-4 my-auto">内容</div>
              <div class="col-8 text-left"><div v-for="(line,index) in data.item.value.content.split('\n')" :key="`${index}`">{{line}}<br></div></div>
            </div>
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-4">評価</div>
              <div class="col-8 text-left">{{data.item.value.evaluation}}</div>
            </div>
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-4 my-auto">ふりかえり</div>
              <div class="col-8 text-left"><div v-for="(line,index) in data.item.value.feedback.split('\n')" :key="`${index}`">{{line}}<br></div></div>
            </div>
            <div class="row" style="margin-right:0px;margin-left:0px">
              <div class="col-12"><b-button size="sm" class="mx-1" variant="success" @click="setFocusKey(data.item.key);loadEditingOldGoal(data.item.value);$bvModal.show('editOldGoal')">更新</b-button></div>
            </div>
          </template>
        </b-table>
        <b-pagination
        v-model="currentPage"
        :total-rows="this.oldGoals.length"
        :per-page=25
        first-number
        last-number
        aria-controls="record-table"
        ></b-pagination>
      </div>
      <b-modal v-bind:id="'editOldGoal'" hide-footer hide-header>


        <div class="form-group border-success d-block text-center" id="goal">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                    <b-button class="m-1 float-right" size="sm" variant="danger"  @click="$bvModal.show('removeOldGoal')"><font-awesome-icon :icon="['far','trash-alt']"/></b-button>
                  </div>
                  </div>
                  <div class="row">
                    <label for="datepicker" class="col-md-4 my-auto">目標の種類</label>
                    <div class="col-md-8">
                      <select class="form-control" v-model="editingOldGoal.goalType">
                        <option>結果</option>
                        <option>行動</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">

                    <label for="datepicker" class="col-md-4 my-auto">開始日</label>
                    <div class="col-md-8" id="date_picker">
                      <Datepicker
                      :language="ja"
                      :format="DatePickerFormat"
                      input-class="form-control inline"
                      v-model="editingOldGoal.startDate"
                      :typeable="false"
                      name="datepicker"></Datepicker>
                    </div>
                  </div>
                  <div class="row">

                    <label for="datepicker" class="col-md-4 my-auto">終了日</label>
                    <div class="col-md-8" id="date_picker">
                      <Datepicker :language="ja" :format="DatePickerFormat" input-class="form-control inline" v-model="editingOldGoal.endDate" :typeable="false" name="endDate"></Datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <label for="evaluation" class="col-md-4 my-auto">内容</label>
                    <div class="col-md-8">
                      <b-form-textarea class="form-control" placeholder="" v-model="editingOldGoal.content" rows="3"></b-form-textarea>
                    </div>
                  </div>
                  <div class="row">
                    <label for="datepicker" class="col-md-4 my-auto">達成状況</label>
                    <div class="col-md-8">
                      <select class="form-control" v-model="editingOldGoal.evaluation">
                        <option></option>
                        <option>★★★★★</option>
                        <option>★★★★</option>
                        <option>★★★</option>
                        <option>★★</option>
                        <option>★</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label for="evaluation" class="col-md-4 my-auto">ふりかえり</label>
                    <div class="col-md-8">
                      <b-form-textarea class="form-control" placeholder="" v-model="editingOldGoal.feedback" rows="1"></b-form-textarea>
                    </div>
                  </div>
                  <b-button variant="secondary" size="sm" class="my-2 mx-2" @click="$bvModal.hide('editOldGoal')">閉じる</b-button>
                  <b-button variant="success" size="sm" class="my-2 mx-2" @click="editOldGoal();$bvModal.hide('editOldGoal')">更新</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-bind:id="'removeOldGoal'" hide-footer hide-header>
        <template v-slot:modal-title>
        </template>
        <div class="d-block text-center">
          <p>本当に過去の目標を削除しますか？</p>
        </div>
        <div class="text-center">
          <b-button class="mx-1" size="sm" variant="danger" @click="removeOldGoal();$bvModal.hide('removeOldGoal');$bvModal.hide('editOldGoal')">削除</b-button>
          <b-button class="mx-1" size="sm" variant="secondary" @click="$bvModal.hide('removeOldGoal')">やめる</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<style>
/*PCの場合*/
.pc	{ display:inline!important; }
.mb	{ display:none!important; }
@media screen and (max-width: 768px) {
  /*タブレット、スマホの場合*/
  .pc	{ display:none!important; }
  .mb { display:inline!important; }
  .container {
    margin-bottom: 64px;
  }
}
.vdp-datepicker__calendar {
  width: auto !important;
}

</style>
<script>
import Header from '@/components/Header.vue'
import firebase from 'firebase/app'
import'firebase/auth'
import'firebase/database'
import Datepicker from 'vuejs-datepicker';
import {ja} from 'vuejs-datepicker/dist/locale';
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

export default {
  name: 'Goal',
  created: function(){
    this.database = firebase.database();
    this.uid = firebase.auth().currentUser.uid;

    //現在の目標の読み込み
    this.database.ref('goals/'+this.uid+'/recentResultGoal').on('value', snapshot => {
      snapshot.forEach((data) => {
        this.recentResultGoal[data.key] = data.val();
        this.loadedRecentResultGoal[data.key] = data.val();
      })
    })
    this.database.ref('goals/'+this.uid+'/recentActionGoal').on('value', snapshot => {
      snapshot.forEach((data) => {
        this.recentActionGoal[data.key] = data.val();
        this.loadedRecentActionGoal[data.key] = data.val();
      })
    })
    //過去の目標の読み込み
    this.list = this.database.ref('goals/'+this.uid+'/oldGoal').orderByChild("index");
    var _this = this;
    this.list.on('value',function(snapshot){
      let array = {};
      snapshot.forEach(child => {
        array[child.key]=child.val();
      });
      _this.oldGoals = Object.entries(array).map(([key, value]) => ({key, value}))
    });

  },
  components:{
    Header,
    Datepicker,
  },
  methods:{
    conversion(date){
      if(date==''){
        return ''
      } else {
        var date2 = new Date(date)

        return dayjs(date2).format("YYYY/MM/DD")
      }
    },
    clear(dateType){
      switch(dateType){
        case 'recentResultGoal_startDate':
          this.recentResultGoal.startDate = '';
          break;
        case 'recentResultGoal_endDate':
          this.recentResultGoal.endDate = '';
          break;
        case 'recentActionGoal_startDate':
          this.recentActionGoal.startDate = '';
          break;
        case 'recentActionGoal_endDate':
          this.recentActionGoal.endDate = '';
          break;
      }
    },
    updateRecentResultGoal(){
      var formattedStartDate = ''
      var formattedEndDate = ''
      if(this.recentResultGoal.startDate!=""){
        formattedStartDate = dayjs(this.recentResultGoal.startDate).format("YYYY/MM/DD")
      }
      if(this.recentActionGoal.endDate!=""){
        formattedEndDate = dayjs(this.recentResultGoal.endDate).format("YYYY/MM/DD")
      }
      firebase.database().ref('goals/'+this.uid+'/recentResultGoal').update({
        content:this.recentResultGoal.content,
        startDate:formattedStartDate,
        endDate:formattedEndDate
      })
    },
    updateRecentActionGoal(){
      var formattedStartDate = ''
      var formattedEndDate = ''
      if(this.recentActionGoal.startDate!=""){
        var startDate = new Date(this.recentActionGoal.startDate)
        formattedStartDate = dayjs(startDate).format("YYYY/MM/DD")
      }
      if(this.recentActionGoal.endDate!=""){
        var endDate = new Date(this.recentActionGoal.endDate)
        formattedEndDate = dayjs(endDate).format("YYYY/MM/DD")
      }
      firebase.database().ref('goals/'+this.uid+'/recentActionGoal').update({
        content:this.recentActionGoal.content,
        startDate:formattedStartDate,
        endDate:formattedEndDate
      })
    },
    onsubmit(){
      if(this.oldGoal.startDate==''||this.oldGoal.endDate==''){
        this.errorMessage=[]
        if(this.oldGoal.startDate==''){
          // this.errorMessage.push("開始日を入力してください。")
        }
        if(this.oldGoal.endDate==''){
          // this.errorMessage.push("終了日を入力してください。")
        }
      } else {
        var startDate = new Date(this.oldGoal.startDate)
        var formattedStartDate = dayjs(startDate).format("YYYY/MM/DD")
        var endDate = new Date(this.oldGoal.endDate)
        var formattedEndDate = dayjs(endDate).format("YYYY/MM/DD")
        var index = -dayjs(formattedEndDate).unix()
        firebase.database().ref('goals/'+this.uid+'/oldGoal').push({
          index:index,
          goalType:this.oldGoal.goalType,
          content:this.oldGoal.content,
          startDate:formattedStartDate,
          endDate:formattedEndDate,
          evaluation:this.oldGoal.evaluation,
          feedback:this.oldGoal.feedback,
        })
        this.oldGoal.goalType = ""
        this.oldGoal.startDate = ""
        this.oldGoal.endDate = ""
        this.oldGoal.content = ""
        this.oldGoal.evaluation = ""
        this.oldGoal.feedback = ""
        this.$bvModal.hide('submitOldGoal')
      }
    },
    setOldGoalParams(goalType){
      var type
      var recentGoal
      if(goalType=="result"){
        type = "結果"
        recentGoal = this.recentResultGoal
      } else {
        type = "行動"
        recentGoal = this.recentActionGoal
      }
      this.oldGoal.goalType = type
      this.oldGoal.startDate = recentGoal.startDate
      this.oldGoal.endDate = recentGoal.endDate
      this.oldGoal.content = recentGoal.content
    },
    clearOldGoalPrams(){
      this.oldGoal.goalType = ""
      this.oldGoal.startDate = ""
      this.oldGoal.endDate = ""
      this.oldGoal.content = ""
      this.oldGoal.evaluation = ""
      this.oldGoal.feedback = ""
    },
    setFocusKey(key){
      this.focusKey = key;
    },
    loadEditingOldGoal(item){
      this.editingOldGoal.goalType=item.goalType
      this.editingOldGoal.content=item.content
      this.editingOldGoal.startDate=item.startDate
      this.editingOldGoal.endDate=item.endDate
      this.editingOldGoal.evaluation=item.evaluation
      this.editingOldGoal.feedback=item.feedback
    },
    editOldGoal(){
      var formattedStartDate = dayjs(this.editingOldGoal.startDate).format("YYYY/MM/DD")
      var formattedEndDate = dayjs(this.editingOldGoal.endDate).format("YYYY/MM/DD")
      firebase.database().ref('goals/'+this.uid+'/oldGoal').child(this.focusKey).update({
        goalType:this.editingOldGoal.goalType,
        content:this.editingOldGoal.content,
        startDate:formattedStartDate,
        endDate:formattedEndDate,
        evaluation:this.editingOldGoal.evaluation,
        feedback:this.editingOldGoal.feedback,
      })
    },
    removeOldGoal(){
      firebase.database().ref('goals/'+this.uid+'/oldGoal').child(this.focusKey).remove();
    },
  },
  data () {
    return {
      user: firebase.auth().currentUser,
      uid: firebase.auth().currentUser.uid,
      ja:ja,
      DatePickerFormat: 'yyyy/MM/dd',
      recentResultGoal:{
        content:"",
        startDate:"",
        endDate:"",
      },
      recentActionGoal:{
        content:"",
        startDate:"",
        endDate:"",
      },
      loadedRecentResultGoal:{
        content:"",
        startDate:"",
        endDate:"",
      },
      loadedRecentActionGoal:{
        content:"",
        startDate:"",
        endDate:"",
      },
      oldGoal:{
        goalType:"",
        content:"",
        startDate:"",
        endDate:"",
        evaluation:"",
        feedback:"",
      },
      oldGoals:[],
      focusKey:"",
      editingOldGoal:{
        goalType:"",
        content:"",
        startDate:"",
        endDate:"",
        evaluation:"",
        feedback:"",
      },
      currentPage:1,
      fields: [
        {
          key: 'value.goalType',
          label: '種類',
        },
        {
          key: 'value.startDate',
          label: '開始日',
        },
        {
          key: 'value.endDate',
          label: '期日',
        },
        {
          key: 'value.content',
          label: '内容',
        },
        {
          key: 'value.evaluation',
          label: '達成状況',
        },
        {
          key: 'value.feedback',
          label: 'ふりかえり',
        },
      ],
      mobileFields:[
        {
          key: 'content',
          label: '内容',
        }
      ],
    }
  },
}
</script>
