<template>
  <div class="container">
    <div class="border">
      <Header />
      <ContactForm />
  </div>
</div>
</template>
<style>
span.description {
   display: inline-block;
}
</style>
<script>
import Header from '@/components/Header.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'contact',
  components:{
    Header,
    ContactForm,
  }
}
</script>
