var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"margin-bottom":"0px"}},[_c('div',{staticClass:"border"},[_vm._m(0),_c('div',{attrs:{"id":"firebaseui-auth-container"}}),_c('span',{staticClass:"description"},[_vm._v("認証が完了すると、")]),_c('span',{staticClass:"description"},[_vm._v("一度ログイン画面に戻ります。")]),_c('br'),_c('span',{staticClass:"description"},[_vm._v("記録画面が表示されるまで、")]),_c('span',{staticClass:"description"},[_vm._v("そのままお待ちください。")]),_c('hr'),_c('h5',[_vm._v("歌留多録（かるたろく）とは？")]),_c('span',{staticClass:"description"},[_vm._v("小倉百人一首競技かるたの")]),_c('span',{staticClass:"description"},[_vm._v("対戦記録・統計アプリです。")]),_c('br'),_vm._m(1),_c('hr'),_c('h5',[_vm._v("このアプリでできること")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_c('b',[_vm._v("その他")]),_c('br'),_c('router-link',{attrs:{"to":"/read"}},[_vm._v("読み札を表示できます。")]),_c('br'),_vm._v("（ログイン不要） ")],1),_c('hr'),_c('h5',[_vm._v("おすすめかるたアプリ")]),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("歌留多録")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("作者：おくさん（"),_c('a',{attrs:{"href":"https://twitter.com/0kdynnkw","target":"_blank"}},[_vm._v("@0kdynnkw")]),_vm._v("）"),_c('br'),_c('a',{attrs:{"href":"https://ko1ynnky.github.io/public/about.html","target":"_blank"}},[_vm._v("詳しい自己紹介と開発経緯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('span',{staticClass:"description"},[_vm._v("「記録」を通じて、")]),_c('span',{staticClass:"description"},[_vm._v("競技かるたをさらに楽しめます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("①")]),_c('br'),_vm._v("日々の練習結果を簡単に記録できます。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("②")]),_c('br'),_c('span',{staticClass:"description"},[_vm._v("記録を集計し、級ごとの勝率や、")]),_c('span',{staticClass:"description"},[_vm._v("平均枚数・お手つき回数を確認できます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("③")]),_c('br'),_c('span',{staticClass:"description"},[_vm._v("結果面・行動面に分けて")]),_c('span',{staticClass:"description"},[_vm._v("目標を設定できます。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5 offset-sm-1"},[_c('a',{attrs:{"href":"https://www.karutar.com/","target":"_blank"}},[_vm._v("競技かるた記録")]),_c('br'),_vm._v("（練習会全体の記録）"),_c('br'),_vm._v(" ゆうすけ様（"),_c('a',{attrs:{"href":"https://twitter.com/SVangurad","target":"_blank"}},[_vm._v("@SVanguard")]),_vm._v("）"),_c('br'),_c('br')]),_c('div',{staticClass:"col-sm-5"},[_c('a',{attrs:{"href":"https://matchrecord.herokuapp.com/#/","target":"_blank"}},[_vm._v("Karuta Record")]),_c('br'),_vm._v("（1枚ごとの経過の記録）"),_c('br'),_vm._v(" せーとく様（"),_c('a',{attrs:{"href":"https://twitter.com/toku_kazeso","target":"_blank"}},[_vm._v("@toku_kazeso")]),_vm._v("）"),_c('br'),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5 offset-sm-1"},[_c('a',{attrs:{"href":"https://carutalog.amebaownd.com/","target":"_blank"}},[_vm._v("かるたーのためのカルタログ")]),_c('br'),_c('a',{attrs:{"href":"https://apps.apple.com/us/app/%E3%82%AB%E3%83%AB%E3%82%BF%E3%83%BC%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%82%AB%E3%83%AB%E3%82%BF%E3%83%AD%E3%82%B0/id1500253098"}},[_vm._v("iOS")]),_vm._v(" / "),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.log.caruta"}},[_vm._v("Android")]),_c('br'),_vm._v(" （試合ごとの記録）"),_c('br'),_vm._v(" 川瀬将義様（"),_c('a',{attrs:{"href":"https://twitter.com/MasayoshiKawase","target":"_blank"}},[_vm._v("@MasayoshiKawase")]),_vm._v("）"),_c('br'),_c('br')]),_c('div',{staticClass:"col-sm-5"},[_c('a',{attrs:{"href":"https://karuta-haichi-memo.web.app/","target":"_blank"}},[_vm._v("競技かるた配置めも")]),_c('br'),_vm._v("（札配置の記録）"),_c('br'),_vm._v(" 宮様（"),_c('a',{attrs:{"href":"https://twitter.com/mt_karuta","target":"_blank"}},[_vm._v("@mt_karuta")]),_vm._v("）"),_c('br'),_c('br')])])
}]

export { render, staticRenderFns }